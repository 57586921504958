import styles from './styles.module.scss'
import { Button, Col, Divider, Form, Row, Select, Upload, Spin, Tooltip, Input, Tag } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { BiPencil, BiX } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { useEffect, useState } from "react";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { useTranslation } from "react-i18next";
import { IDormitoryCategory, IDormitoryDocument, IDormitoryPetition } from "models";
import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import useDeleteOne from "hooks/useDeleteOne";
import { FILE_URL } from "config/utils";
import FormLabel from 'components/FormLabel';
import { AxiosRequestConfig } from 'axios';
import isHasAuthRoute from 'utils/isHasRoute';
import { Card } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

function Dormitory() {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [documents, setDocuments] = useState<Record<number, IDormitoryDocument[]>>({});
    const [reFetch, setReFetch] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    const _delete = useDeleteOne();
    const petition = useGetAllData({ url: '/hostel-apps?expand=hostelDoc', isCall: 'auto', perPage: 0, refetch: [reFetch, _delete.refetch] }) as TypeReturnUseGetAllData<IDormitoryPetition>;
    const categories = useGetAllData({ url: '/hostel-categories?expand=types', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IDormitoryCategory>;




    React.useEffect(() => {

        if (!petition.loading && petition.items.length) {
            setText(petition.items[0].description ?? '')
            let newObj: Record<number, IDormitoryDocument[]> = {};
            const element = petition.items[0];
            setDocuments({})
            if (element && element.hostelDoc.length) {
                element.hostelDoc.forEach((item) => {
                    if (newObj.hasOwnProperty(item.hostel_category_id)) {
                        newObj[item.hostel_category_id].push(item);
                    } else {
                        newObj[item.hostel_category_id] = [item]
                    }
                })

                setDocuments(newObj)
            }

        }

    }, [petition.loading])


    useEffect(() => {
        if (petition.items.length) {
            categories.fetch();
        }
    }, [petition.items.length])

    const onSubmit = async () => {


        const formdata = new FormData();
        formdata.append('description', text);

        try {
            const options: AxiosRequestConfig = petition.items.length ? { url: `/hostel-apps/${petition.items[0]?.id}`, method: 'PUT', data: formdata } : { url: '/hostel-apps', method: 'POST', data: formdata }
            const response = await instance(options);
            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message);
                setReFetch(!reFetch)
            } else {
                asyncN("error", 'update', response.data?.message)
            }
        } catch (error) {

        }
    }

    const submitDocument = async (category_id: number) => {

        const values = form.getFieldsValue()

        const formdata = new FormData();

        const hosteAppId = petition.items[0]?.id

        const file = form.getFieldValue(`hostel_file_${category_id}`)?.file?.originFileObj;
        const hostelCategorytypeId = form.getFieldValue(`hostel_category_type_id_${category_id}`)

        const findCategory = categories.items.find(e => e.id === category_id);

        const isReady = findCategory?.types.length ? file && hostelCategorytypeId : file;

        if (isReady) {

            formdata.append('hostel_app_id', String(hosteAppId))
            formdata.append('hostel_category_id', String(category_id))
            formdata.append('hostel_file', file)
            if (hostelCategorytypeId) {
                formdata.append('hostel_category_type_id', hostelCategorytypeId)
            }

            const response = await instance({ url: '/hostel-docs', method: 'POST', data: formdata });

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message).then(() => {
                    form.resetFields();
                    setReFetch(!reFetch);
                })
            } else {
                asyncN("error", 'update', response.data?.message)
            }
        } else {
            if (!file && !hostelCategorytypeId) {
                form.setFields([{ name: `hostel_file_${category_id}`, errors: ["Turini tanlang!"] }]);
                form.setFields([{ name: `hostel_category_type_id_${category_id}`, errors: ["Turini tanlang!"] }]);
            }
            else if (!file) {
                form.setFields([{ name: `hostel_file_${category_id}`, errors: ["Turini tanlang!"] }]);
            } else {
                form.setFields([{ name: `hostel_category_type_id_${category_id}`, errors: ["Turini tanlang!"] }]);
            }
        }
    }


    const isDisabled = () => petition.items.length && petition.items[0].status && petition.items[0].status !== 0;

    // 2,10,14,16,20

    return (
        <Card bordered={false}>
                <Spin spinning={petition.loading && categories.loading} >
                    <div className={styles.notice_box} >
                        <p><FaInfoCircle /> Talabalar turar joyiga talabalarni joylashtirish tizimi endilikda ushbu tizimda ariza topshirish orqali amalga oshiriladi. Ariza bergandan so'ng keltirilgan hujjatlar sizda mavjud bo'lsa ularni kiritishingiz talab etiladi.</p>
                    </div>
                    {
                        petition.items.length && petition.items[0].status && petition.items[0].status !== 0 ?
                            <>
                                <Divider orientation='left' ><span className={styles.title_petition}>Sizning arizangiz</span></Divider>
                                <div className={styles.petition_box}>
                                    <p>{petition.items[0].description}</p>
                                </div>
                                <Divider orientation='left' ><span className={styles.title_petition}>Ariza bo'yicha xulosa</span>&nbsp;&nbsp;{checkStatusHostelAppAnswer(petition.items[0].status)}</Divider>
                                <div className={`${styles.notice_box} mt-2`}>
                                    <p>{petition.items[0].conclution}</p>
                                </div>
                            </>
                            :
                            <div className='mt-4' >
                                <Divider orientationMargin="left" orientation='left'><FormLabel> Arizangizni kiriting <BiPencil /> </FormLabel></Divider>
                                <TextArea placeholder='Ariza qoldiring ...' value={text} onChange={(e) => { setText(e.target.value) }} />
                                <Row className='mt-2' gutter={[12, 12]}>
                                    <Col xl={8} lg={8} md={8} xs={24}>{petition.items.length && petition.items[0]?.status !== null ? checkStatusHostelApp(petition.items[0]?.status) : null}</Col>
                                    <Col xl={16} sm={24} xs={24} className="text-end" >
                                        {petition.items.length && isHasAuthRoute('hostel-app_delete') ? <Button type="primary" onClick={() => { _delete.fetch(`/hostel-apps/${petition.items[0]?.id}`); setText('') }} className="me-2" danger>Arizani bekor qilish</Button> : null}
                                        {petition.items.length && isHasAuthRoute('hostel-app_update') ? <Button type="primary" onClick={onSubmit} >Arizani tahrirlash</Button> : null}
                                        {!petition.items.length && isHasAuthRoute('hostel-app_create') ? <Button type="primary" onClick={onSubmit}>Ariza yuborish</Button> : null}
                                    </Col>
                                </Row>
                            </div>
                    }
                    {!Boolean(isDisabled()) ?
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            {
                                isHasAuthRoute('hostel-doc_create') && petition.items.length && categories.items.length ? categories.items.map((element) => {
                                    return (
                                        <div key={element.id} className={styles.list_item_box}>
                                            <span><strong>{element.id}</strong>) {element.name} </span>
                                            <Divider className="my-2" ></Divider>
                                            <Row gutter={[12, 12]}>
                                                <Col xl={9} lg={9} md={12} sm={12} xs={24} className="w-100" >
                                                    <Form.Item
                                                        name={`hostel_file_${element.id}`}
                                                        className="w-100"
                                                    >
                                                        <Upload className="w-100" onChange={() => form.setFields([{ name: `hostel_file_${element.id}`, errors: [] }])} accept=".pdf" >
                                                            <Button type="primary" ghost style={{ minWidth: '300px', maxWidth: '600px', width: '100%' }} icon={<UploadOutlined />}>Fayl yuklash</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                                {
                                                    element.types.length ?
                                                        <Col xl={9} lg={9} md={12} sm={12} xs={24}  >
                                                            <Form.Item
                                                                name={`hostel_category_type_id_${element.id}`}
                                                            >
                                                                <Select
                                                                    className="w-100"
                                                                    disabled={!element.types.length}
                                                                    allowClear
                                                                    placeholder="Select type"
                                                                    onChange={() => form.setFields([{ name: `hostel_category_type_id_${element.id}`, errors: [] }])}
                                                                >
                                                                    {
                                                                        element.types.length ?
                                                                            element.types.map((childElement) => <Option key={childElement.id} value={childElement.id} >{childElement?.name}</Option>) : null
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        : null
                                                }
                                                <Col xl={6} lg={6} md={12} sm={12} xs={24} >
                                                    <Button type="primary" ghost className="w-100" htmlType="submit" onClick={() => submitDocument(element.id)} >{t('Saqlash')}</Button>
                                                </Col>
                                                <Col xl={24} >
                                                    {
                                                        documents[element.id] ?
                                                            <>
                                                                <Divider className="my-2" />
                                                                <Table
                                                                    id="tech-companies-1"
                                                                    className="kpi_table mt-3"
                                                                >
                                                                    <Thead style={{ border: "0px solid #EBF4F7" }}>
                                                                        <Tr style={{ backgroundColor: "#F7FAFE", border: "0px solid white !important" }}>
                                                                            <Th className="max_w_100 th_text">{t('File')}</Th>
                                                                            {element.types.length ? <Th className="max_w_100 th_text">{t('Type')}</Th> : null}
                                                                            <Th className="max_w_100 text-center th_text">{t('Actions')}</Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody style={{ border: "0px solid #EBF4F7" }} >
                                                                        {
                                                                            documents[element.id].map((item) => {
                                                                                return (
                                                                                    <Tr key={item.id}>
                                                                                        <Td style={{ width: '30%' }}><a href={FILE_URL + item.file} target="_blank" >hujjat fayli</a></Td>
                                                                                        {element.types.length ? <Td style={{ width: '70%' }}> {element.types.find(e => e.id === item.hostel_category_type_id)?.name}</Td> : null}
                                                                                        <Td className="d-flex justify-content-center max_w_100" >
                                                                                            {
                                                                                                <Tooltip title={t('Delete')}>
                                                                                                    <Button danger size="small" className="ms-2" onClick={() => { _delete.fetch(`/hostel-docs/${item?.id}`) }} disabled={!isHasAuthRoute('hostel-doc_delete')} ><BiX size={15} /></Button>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        </Td>
                                                                                    </Tr>

                                                                                )
                                                                            })
                                                                        }
                                                                    </Tbody>
                                                                </Table>
                                                            </> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }) : null
                            }
                        </Form> : null}
                </Spin>
        </Card>
    )

}




export default Dormitory;


const checkStatusHostelApp = (status: number) => {
    switch (status) {
        case 1:
            return <Tag color="#E8F0FE" className={styles.status_tag} style={{ color: '#1A73E8' }} >Siz TTJ  ga qabul qilindingiz</Tag>
        case 2:
            return <Tag color="#FCE8E6" className={styles.status_tag} style={{ color: '#C5221F' }} >Sizning arizangiz rad qilindi</Tag>
        case 3:
            return <Tag color="#E6F4EA" className={styles.status_tag} style={{ color: '#137333' }} >Arizangiz qayta ko'rib chiqilmoqda</Tag>
        default:
            return <Tag color="#C26C03" style={{ color: 'white', minWidth: '120px', textAlign: 'center' }} >Ko'rib chiqilmoqda</Tag>
    }
}
const checkStatusHostelAppAnswer = (status: number) => {
    switch (status) {
        case 1:
            return <Tag color="#E8F0FE" className={styles.status_tag_answer} style={{ color: '#1A73E8' }} >Siz TTJ  ga qabul qilindingiz</Tag>
        case 2:
            return <Tag color="#FCE8E6" className={styles.status_tag_answer} style={{ color: '#C5221F' }} >Sizning arizangiz rad qilindi</Tag>
        case 3:
            return <Tag color="#E6F4EA" className={styles.status_tag_answer} style={{ color: '#137333' }} >Arizangiz qayta ko'rib chiqilmoqda</Tag>
        default:
            return <Tag color="#C26C03" style={{ color: 'white', minWidth: '120px', textAlign: 'center' }} >Ko'rib chiqilmoqda</Tag>
    }
}

/**


hostel-app_create
hostel-app_delete
hostel-app_index
hostel-app_update
hostel-app_view
hostel-category-type_create
hostel-category-type_delete
hostel-category-type_index
hostel-category-type_update
hostel-category-type_view
hostel-category_create
hostel-category_delete
hostel-category_index
hostel-category_update
hostel-category_view
hostel-doc_check
hostel-doc_create
hostel-doc_delete
hostel-doc_index
hostel-doc_not
hostel-doc_update
hostel-doc_view


 */