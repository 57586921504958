import styled from "styled-components";




export const DocumentCardUi = styled.div`

    border: 1px solid #e7edf5;
    background-color: white;
    border-radius: 2px;
    padding: 6px 6px;
    min-height: 70px;
    display: flex;
    justify-content: space-between;

    &:hover {
        background-color: #fafafa;
        cursor: pointer;
    }

    & > div {
        color: #5A8DEE;
    }
`


export const DocumentItemUi = styled.div<{ theme: any }>`

    width:100%;
    font-size: 13px;
    border-bottom: 1px solid ${props => props.theme.text};
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${props => props.theme.text};
    position: relative;

    &>div:nth-child(1){

        display: flex;
        width: 100%;
        align-items: center;

        & > span {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
             -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-size: 14px;
            margin-left: 10px;
        }
    }
    &>div:nth-child(2){
        visibility: hidden;
        position: absolute;
        right: 10px;

        & > svg {
            color: ${p => p.theme.text};
            font-size: 18px;

            &:hover {
                color: ${p => p.theme.header_text};
            }
        }
    }

    &:hover {
        -webkit-box-shadow: 0px 0px 8px -2px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 8px -2px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 8px -2px rgba(34, 60, 80, 0.2);
        cursor: pointer;
        color: #5A8DEE;
        color: ${p => p.theme.blue};
        & > div:nth-child(2){
        visibility: visible;
    }
    &>div:nth-child(1){

        & > span {
            width: calc(100% - 50px);
        }
    }
    }

    & > svg {
        margin-right: 10px;
        color: #CCCFD3;
    }

`


export const PrintBtnUi = styled.button<{ theme: any }>`

    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: ${p => p.theme.element};

    &:hover {
        color: ${p => p.theme.header_text};
        background-color: ${p => p.theme.active_element};
    }

`