import { Button, Card, Col, Form, Input, message, Modal, Radio, Row } from "antd";
import { Title } from "pages/styles/style";
import CustomBreadcrumb from "components/Breadcrumb";
import instance from "config/_axios";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { getOneExam } from "services";
import { asyncN } from "utils/notifications";
import { examType } from "../types";
import { encodeKey } from "./keyEncoder";
import Questionnaire from "./Questionnaire";
import { AxiosResponse } from "axios";

const StudentExamView: FC<{ exam: examType }> = (): JSX.Element => {

    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();
    const url: string = useLocation()?.pathname;
    const pathParts: Array<string> = url?.split("/");
    const [secretKeyModal, setSecretKeyModal] = useState<boolean>(false);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [exam, setExam] = useState<examType>()
    const [isView, setIsView] = useState<boolean>(true);
    const [examStudent, setExamStudent] = useState<any>([])
    const [refresh, setRefresh] = useState<boolean>(false)

    const countQuestions = (values: string): number => {
        let a: number = 0;
        Object.entries(JSON.parse(values)).forEach(([key, value]: any) => {
            a += Number(value?.count)
        })
        return a;
    }
    const _breadcrump1 = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "/std_exams",
        },
        {
            name: "Subject exams",
            path: url?.substring(0, url.indexOf("/view/")),
        },
        {
            name: "Exam view",
            path: url,
        }
    ];

    useEffect(() => {
        if (exam) {
            let finishExam = new Date(exam.finish);
            let now = new Date();
            if (finishExam.getTime() > now.getTime()) {
                setIsExpired(false)
            } else {
                setIsExpired(true)
            }
        }
    }, [exam])

    useEffect(() => {
        (
            async () => {
                const resp = await getOneExam(Number(pathParts[pathParts.length - 1]), `expand=examType,examStudent,surveyStatus,surveyAnswer,eduSemestrSubject.subject,examStudent.examStudentAnswers.examStudentAnswerSubQuestion,examStudent.examStudentAnswers.question`)
                setExam(resp)
                setExamStudent(resp?.examStudent[0]?.examStudentAnswers)
                // setIsView(resp?.surveyStatus === 1)
            }
        )()
    }, [refresh])

    const onFinish = async (values: any) => {
        const { secret_key } = values
        let a: string = encodeKey(secret_key);
        if (a !== "no encode") {
            getQuestions(a, secret_key)
            form.setFields([{
                name: ["secret_key"],
                errors: [""],
                validating: true
            }])
        } else {
            form.setFields([{
                name: ["secret_key"],
                errors: ["Mahfiy parol faqat raqamlardan iborat bo'lishi kerak!"],
                validating: false
            }])
        }
    }

    const getQuestions = async (keyEncode?: string, key?: string) => {
            const id = Number(pathParts[pathParts.length - 1])
            let data = new FormData();
            data.append("exam_id", String(id));
            if (key) {
                data?.append("password", key);
            }
            const response = await instance({
                method: "post",
                url: "/exam-student-answers/get-question?expand=type,exam.examStudent",
                data: data,
            });
            let finish = new Date(response.data?.data?.times?.finish);
            let now = new Date(response.data?.data?.times?.now);

            if (response?.data?.status === 1 && finish.getTime() > now.getTime()) {                
                history.push({pathname: `/std_exam/${exam?.id}${keyEncode ? "/" + keyEncode : '/-1'}`, state: {
                    response: response.data, exam
                }})
            } else {
                if (finish.getTime() <= now.getTime()) {
                    asyncN("error", "read", "Exam is expired!");
                } else {
                    asyncN("error", "read", response?.data?.message);
                }
            }
    };

    return (
        <Card bordered={false}>
            <Title color="">
                Imtihon ko'rish
            </Title>
            <CustomBreadcrumb arr={_breadcrump1} />
            {isView ? <Row gutter={24} >
                <Col span={24} className="d-flex justify-content-center">
                    {exam && <Card style={{ width: "450px" }} className="shadow mt-5">
                        <Row gutter={24} className="p-3 bordered">
                            <Col span={24} className="d-flex justify-content-between">
                                <strong>{t("Exam type")}: </strong><span className="ms-1">{exam?.examType?.name}</span>
                            </Col>
                            <Col span={24} className="d-flex justify-content-between">
                                <strong>{t("Start time")}: </strong><span className="ms-1">{exam.start}</span>
                            </Col>
                            <Col span={24} className="d-flex justify-content-between">
                                <strong>{t("Dead line")}: </strong><span className="ms-1">{exam.finish}</span>
                            </Col>
                            <Col span={24} className="d-flex justify-content-between">
                                <strong>{t("Duration")}: </strong><span className="ms-1">{exam?.duration ? moment.utc(Number(exam?.duration) * 1000).format("HH:mm") : null}</span>
                            </Col>
                            <Col span={24} className="d-flex justify-content-between">
                                <strong>{t("Max ball")}: </strong><span className="ms-1">{exam?.max_ball}</span>
                            </Col>
                            <Col span={24} className="d-flex justify-content-between">
                                <strong>{t("Min ball")}: </strong><span className="ms-1">{exam?.min_ball}</span>
                            </Col>
                            {exam?.question_count_by_type_with_ball && <Col span={24} className="d-flex justify-content-between">
                                <strong>{t("Questions count")}: </strong><span className="ms-1">{countQuestions(exam?.question_count_by_type_with_ball)}</span>
                            </Col>}
                            {isExpired ?
                                <Col span={24} className="d-flex justify-content-center mt-3">
                                    <span className="text-warning">{t("Exam time is finished!")}</span>
                                </Col> :
                                <Col span={24} className="d-flex justify-content-center mt-3">
                                    {exam?.question_count_by_type_with_ball ? exam?.is_protected === 0 ? <Button type="primary" onClick={() => getQuestions()}>{t("Start")}</Button> :
                                    <Button onClick={() => setSecretKeyModal(true)} type="primary" ><span className="text-light">{t("Enter secret key")}</span></Button> : <span className="text-warning">{t("There are no questions!")}</span>}
                                </Col>}

                            {
                                exam?.is_protected === 1 && !isExpired && <Col span={24} className="d-flex justify-content-center">
                                    <span className="ms-1 text-warning">{t("Imtihon maxfiy parol bilan himoyalangan!")}</span>
                                </Col>
                            }
                            {
                                examStudent?.length ? <Col className="d-flex justify-content-center mt-3" span={24}>
                                    <Button onClick={() => {
                                        history.push(`/student-exams/${exam?.eduSemestrSubject?.id}/exam-result/${exam?.id}`)
                                    }}>
                                        {t("View result")}
                                    </Button>
                                </Col> : ''
                            }
                        </Row>
                    </Card>}
                </Col>
            </Row> : exam && <Questionnaire refresh={refresh} setRefresh={setRefresh} answers={exam?.surveyAnswer} subject_id={exam?.eduSemestrSubject.subject_id} exam_id={exam?.id} isView={isView} setIsView={setIsView} />}
            <Modal width={300} footer={false} title={t("Input your secret key!")} onCancel={() => setSecretKeyModal(false)} open={secretKeyModal}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        label={t("Your secret key")}
                        name={"secret_key"}
                    >
                        <Input.Password type="number" placeholder={t("Input secret key!")} />
                    </Form.Item>
                    <div className="d-flex justify-content-center">
                        <Button htmlType="submit" type="primary">{t("Enter to the exam")}</Button>
                    </div>
                </Form>
            </Modal>
        </Card>
    )
}

export default StudentExamView