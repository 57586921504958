/** @format */

import styled from "styled-components";

export const SelectingButton = styled.button<{
  theme: any;
  is?: "card" | "list";
}>`
  border: 1px solid
    ${(props) => (props.theme.name === "white" ? "white" : "#0E1621")};
  border-radius: 12px;
  min-width: 100px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 0px;
  background-color: ${(props) =>
    props.theme.name === "white" ? "white" : "#17212B"};
  height: 24px;
  /* color: #1890ff; */
  color: ${(props) => props.theme.blue};
  &:active {
    background-color: ${(props) =>
      props.theme.name === "white" ? "#f8f7fa" : "#1F2936"};
  }
  & > span {
    height: 24px;
    width: 24px;
    background-color: ${(props) =>
      props.theme.name === "white" ? "#f8f7fa" : "#1F2936"};
    color: ${(props) => props.theme.blue};
    border-radius: 50%;
    display: block;
    position: relative;
    margin-left: 10px;
    margin-right: 0px;
    & > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const ListSubjects = styled.div<{ theme: any }>`
  background-color: ${(props) => props.theme.element};
  &:hover {
    background-color: ${(props) =>
      props.theme.name === "white" ? "#F1F0F3" : "#1B2736"};
  }
`;

export const SelectSemesterBtn = styled.button<{ theme: any, active?: boolean }>`
  border: none;
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.element};
  margin: 0 2px;
  background: ${props => props.active ? "#1890FF" : ""};
  color: ${props => props.active ? "#fff" : ""};
  &:hover {
    background-color: ${(props) => !props.active && props.theme.active_element};
  }
  &:active {
    background-color: ${(props) => props.theme.active_element};
  }
`;
export const CartsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  margin: 2rem 0;
`;
export const CartWrapper = styled.div<{ theme: any }>`
  background: transparent;
  width: 100% !important;
  height: 100%;
  /* margin: 1rem; */
  border-radius: 0.5rem;
  box-shadow: ${(props) =>
    props.theme.name === "balck"
      ? "rgba(0, 0, 0, 0.6) 0px 2px 10px, rgba(0, 0, 0, 0.5) 0px 1px 4px"
      : ""};

  hr {
    margin: 0;
  }
  .div {
    padding: 0.5rem 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p, span {
      margin: 0;
      color: #1890FF;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.08);
    }
  }
  .div {
    text-decoration: none;
    color: ${(p) => p.theme.text};
  }
  div:first-child {
    display: block;
    border-radius: 0.5rem 0.5rem 0 0;
    min-height: 5rem;
    background: ${(props) => props.theme.active_element};
    /* background: #0096c7; */
    /* background: #9381ff; */
    text-align: center;
    padding: 0.75rem 0.5rem 0.5rem;
    /* color: #f1f1f1; */
    h5 {
      /* color: #f1f1f1; */
      font-size: 1.1rem;
    }
    p {
      font-size: 13px;
      /* margin-top: 0.75rem; */
      margin-bottom: 0;
    }
  }

  .teacher{
      text-transform: capitalize;
  }
`;

export const SubjectList = styled.div<{ theme: any }>`
  * {
    border-color: transparent !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    font-size: 14px;
  }

  .table_div {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    padding: 0.5rem !important;
    background-color: ${(props) => props.theme.element};
    color: ${(props) => props.theme.text};
    width: 100%;
    min-height: 3.6rem;
    max-height: 4.8rem !important;
    button {
      background-color: ${(props) => props.theme.active_element};
      padding: 0.25rem 1rem !important;
      color: ${(props) => props.theme.blue};
      border-radius: 0.5rem;
      border: 1px solid transparent !important;
      height: auto;
      z-index: 1111;
      &:hover {
        background-color: ${(props) => props.theme.active_element};
        border: 1px solid ${(props) => props.theme.blue} !important;
      }
    }
    .attend_btn{
      color: ${(props) => props.theme.text} !important;
      color: #7a7f88 !important;
      border: 1px solid #ccd0db !important;
    }
  }
  .head {
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.element};
    color: ${(props) => props.theme.text};
    height: 2.5rem;
    padding: 0.5rem !important;
  }
  .start {
    border-radius: 0.4rem 0 0 0.4rem;
    padding-left: 1rem !important;
  }
  .end {
    border-radius: 0 0.4rem 0.4rem 0;
  }
  .start-top {
    border-radius: 0.75rem 0 0 0.4rem;
    padding-left: 1rem !important;
  }
  .end-top {
    border-radius: 0 0.75rem 0.4rem 0;
  }
  .start-dip {
    border-radius: 0.4rem 0 0 0.75rem;
  }
  .end-dip {
    border-radius: 0 0.4rem 0.75rem 0;
  }
  p {
    margin: 0;
  }
`;

export const ExercisseWrapper = styled.div<{ theme: any; isMobile: boolean }>`
  .ball {
    width: 100%;
    height: 6rem;
    border-radius: 0.5rem;
    border: 2px solid ${(p) => p.theme.element};
    margin: 1rem 0;
    div {
      display: inline-block;
      width: calc(100% / 3);
      height: calc(6rem - 2px);
      padding: 0.5rem;
      font-size: 13px;
      p {
        margin: 0;
      }
      span {
        display: inline-block;
        width: 100%;
        font-size: 2rem;
        text-align: center;
      }
    }
    .left {
      border-right: 2px solid ${(props) => props.theme.element};
    }
    .right {
      border-left: 2px solid ${(props) => props.theme.element};
    }
  }
  .teacher {
    padding: 0 0.5rem;
    height: max-content !important;
    background: ${(p) => p.theme.element};
    border-radius: 0.5rem;
    border: 2px solid ${(p) => p.theme.element};
    margin: 1rem 0;
    p {
      margin: 0.25rem 0;
    }
  }
  .table {
    color: ${(props) => props.theme.text};
    th,
    td,
    tr,
    thead,
    tbody {
      border: none;
      .topic {
        p {
          margin: 0;
          a {
            font-size: 13px;
          }
        }
      }
    }
    thead tr {
      border-bottom: 2px solid ${(props) => props.theme.active_element};
    }
    tr {
      border-bottom: 1px solid ${(props) => props.theme.element};
      height: ${(props) => (props.isMobile ? "auto" : "4rem")};
      padding-bottom: 0.5rem;
    }
    tbody tr:hover {
      background: ${(props) => props.theme.element};
      border-radius: 0.5rem !important;
      td {
        &:first-child {
          border-radius: 0.5rem 0 0 0.5rem;
        }
        &:last-child {
          border-radius: 0 0.5rem 0.5rem 0;
        }
        .button {
          background: ${(props) => props.theme.element};
        }
      }
    }
    .button {
      display: block;
      color: ${(props) => props.theme.text};
      background: ${(props) => props.theme.card};
      border: 1px solid ${(props) => props.theme.text};
      &:hover {
        color: ${(props) => props.theme.blue};
        border: 1px solid ${(props) => props.theme.blue};
      }
    }
    .upload {
      color: ${(props) => props.theme.blue};
      text-decoration: none;
    }
  }
  .ant-tabs-tab-btn {
    color: ${(props) => props.theme.text};
  }
  .ant-tabs-nav::before {
    content: "";
    border-bottom-color: ${(props) => props.theme.text};
  }
`;

export const WrapperContent = styled.div<{ theme: any; size: number }>`
  .content {
    &::-webkit-scrollbar {
      width: 0.75rem;
    }
    &::-webkit-scrollbar-track {
      background: ${(p) => p.theme.element};
      border-radius: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.text};
      border-radius: 0.25rem;
    }
    .cards {
      font-size: ${(p) => p.size}px;
      background: ${(p) => p.theme.element};
      color: ${(p) => p.theme.text};
      border: 1px solid ${(p) => p.theme.active_element};
      border-radius: 0.5rem;
      span,
      p,
      b,
      i,
      li {
        color: ${(p) => p.theme.text};
      }
      a {
        color: ${(p) => p.theme.blue};
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: ${(p) => p.size + 4}px;
        color: ${(p) => p.theme.header_text};
      }
      .ant-card-head {
        color: ${(p) => p.theme.header_text};
        border-bottom: 1px solid ${(p) => p.theme.active_element};
      }
    }
    .ifream_style {
      justify-content: "center";
      width: "100%";
      height: "70vh";
      border-radius: ".5rem";
    }
  }
  .title_filter {
    display: flex;
    justify-content: space-between;

    .btn-group {
      margin-right: 2.5rem;
      display: flex;
      justify-content: end;

      .btn-filter {
        border: 1px solid ${(p) => p.theme.active_element};
        background-color: ${(p) => p.theme.element};
        color: ${(p) => p.theme.blue};
        margin-left: 0.5rem;
        border-radius: 0.25rem;

        &:hover {
          border-color: ${(p) => p.theme.blue};
        }
        &:first-child {
          margin-left: 0;
        }
      }
      .text-size {
        margin-left: 1.5rem;
        border: none;
        font-size: 13px;
        background-color: transparent;
        text-align: center;

        .btn-filter:first-child {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .title_filter {
      display: block;

      .btn-group {
        margin-top: 0.5rem;
        justify-content: center;
      }
    }
  }
  @media (max-width: 400px) {
    .title_filter {
      .btn-group {
        .btn-filter {
          margin-left: 0.25rem;
        }
        .text-size {
          margin-left: 0.75rem;
        }
      }
    }
  }
`;

export const WrapperContent_1 = styled.div<{ theme: any; size: number }>`
  .content {
    &::-webkit-scrollbar {
      width: 0.75rem;
    }
    &::-webkit-scrollbar-track {
      background: ${(p) => p.theme.element};
      border-radius: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.text};
      border-radius: 0.25rem;
    }
    .cards {
      font-size: ${(p) => p.size}px;
      background: ${(p) => p.theme.element};
      color: ${(p) => p.theme.text};
      border: 1px solid ${(p) => p.theme.active_element};
      border-radius: 0.5rem;
      span,
      p,
      b,
      i,
      li {
        color: ${(p) => p.theme.text};
      }
      a {
        color: ${(p) => p.theme.blue};
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: ${(p) => p.size + 4}px;
        color: ${(p) => p.theme.header_text};
      }
      .ant-card-head {
        color: ${(p) => p.theme.header_text};
        border-bottom: 1px solid ${(p) => p.theme.active_element};
      }
    }
    .ifream_style {
      justify-content: center;
      width: 90%;
      height: 70vh;
      border-radius: .5rem;
    }
    .image,
    .video {
      max-width: 100%;
      min-width: 60%;
      margin: 0 auto;
      border-radius: 0.5rem;
    }
  }
  .title_filter {
    .btn-group {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      margin-right: 2.5rem;

      .btn-filter {
        height: 30px;
        width: 120px;
        border: 1px solid ${(p) => p.theme.active_element};
        background-color: ${(p) => p.theme.element};
        color: ${(p) => p.theme.blue};
        margin-left: 0.5rem;
        border-radius: 0.25rem;

        &:hover {
          border-color: ${(p) => p.theme.blue};
        }
        &:first-child {
          margin-left: 0;
        }
      }
      .text-size {
        margin-left: 1.5rem;
        border: none;
        font-size: 13px;
        background-color: transparent;
        text-align: center;

        button {
          width: 60px;
        }

        .btn-filter:first-child {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .title_filter {
      display: block;

      .btn-group {
        margin-top: 0.5rem;
        justify-content: center;
        .btn-filter {
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .content {
      &::-webkit-scrollbar {
        width: 0.5rem;
      }
      &::-webkit-scrollbar-track {
        border-radius: 0.2rem;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0.2rem;
      }
      .video,
      .image {
        width: 100%;
      }
      .ifream_style {
        width: 100%;
      }
    }

  }
  @media (max-width: 768px) {
    .title_filter {
      .btn-group {
        .btn-filter {
          height: auto;
          width: auto;
          margin-left: 0.25rem;
        }
        .text-size {
          margin-left: 0.75rem;
          button {
            width: auto;
          }
        }
        .btn-filter-text {
          display: none;
        }
      }
    }
  }
`;
