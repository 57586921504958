import { SelectStudentTheme } from 'config/theme/colors';
import { Title } from 'pages/styles/style';
import { FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink, useHistory } from 'react-router-dom';
import { CartWrapper } from './styled';
import { message } from 'antd';
import { StudentAttendType, subjectType } from './type';


const SubjectCart: FC<{ id: any, value: subjectType[], data: subjectType[], attend: StudentAttendType[] }> = ({ id, value, data = [], attend }): JSX.Element => {
  const history = useHistory();

  const theme = SelectStudentTheme();

  return (
    <CartWrapper theme={theme} className='subject_cart element_shadow'>
      <div onClick={() => history.push(`/subjects/${id}?edu_semestr_id=${data[0]?.timeTable?.edu_semester_id}&semestr_id=${data[0]?.timeTable?.semester_id}&language_id=${data[0]?.timeTable?.language_id}`)}>
        <Title color={theme.header_text}>{value[0]?.subject?.name}</Title>
        {/* <p >{ value[0]?.timeTable?.subjectType === 5 ? "Majburiy" : value[0]?.timeTable?.subjectType === 6 ? "Tanlov" : ""} | {value[0]?.timeTable?.eduSemestr?.eduSemestrSubjects?.find((e: any) => e.subject_id)?.all_ball_yuklama} soat | {value[0]?.timeTable?.eduSemestr?.eduSemestrSubjects?.find((e: any) => e.subject_id)?.credit} kredit</p> */}
        <div className="d-f flex-column  justify-content-around" >{value?.map((e, i) => i < 3 && (
          <p className='teacher' key={i} >{e?.subjectCategory?.name}: - {e?.teacher?.last_name.toLowerCase()} {e?.teacher?.first_name.toLowerCase()} {e?.teacher?.middle_name.toLowerCase()}</p>
        ))}</div>
      </div>
      <hr />
      <div>
        {/* <NavLink className="div" to={`/subjects/${id}?edu_semestr_id=${data[0]?.timeTable?.edu_semester_id}&semestr_id=${data[0]?.timeTable?.semester_id}&language_id=${data[0]?.timeTable?.language_id}`} >
          <p>Resurslar </p>
          <span><IoIosArrowForward /></span>
        </NavLink> */}
        <NavLink className="div" to={`/std_attendances/${id}`} >
          <p>Davomat </p>
          <span> {attend.length}</span>
        </NavLink>
      </div>
      <hr />
      <div>
        <a className="div" onClick={() => value.filter((e: any) => e.subjectCategory?.id !== 1).length ? history.push(`/std_subject/subject_exercisses/${value.filter((e: any) => e.subjectCategory.id !== 1)[0].time_table_id}`) : message.warning("Seminar tanlanmagan")} >
          <p>Topshiriqlar </p>
          <span><IoIosArrowForward /></span>
        </a>
      </div>
    </CartWrapper>
  )
};

export default SubjectCart;