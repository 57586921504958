/** @format */

import styled from "styled-components";

export const TimeTableCard = styled.div<{ theme: any }>`
  border-radius: 6px;
  margin-top: 16px;
  padding: 6px 8px 10px 8px;
  background-color: ${(props) => props.theme.element};

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    color: #1890ff;
  }
`;

export const SelectingButtonCard = styled.button<{
  theme: any;
  is?: "card" | "list";
}>`
  border: none;
  background-color: transparent;
  color: #1890ff;
  &:active {
    background-color: ${(props) => props.theme.active_element};
  }
  &:hover {
    background-color: ${(props) => props.theme.active_element};
  }
`;
export const SelectedButtonCard = styled.button<{
  theme: any;
  is?: "card" | "list";
}>`
  border: none;
  background-color: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  color: #1890ff;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: ${(props) => props.theme.active_element};
  }
`;

export const TimeTableCardMain = styled.div<{ theme: any }>`
  border-radius: 4px;
  margin-top: 16px;
  padding: 6px 8px;
  border: 1px solid #f1f4f9;

  & > span {
    border-bottom: 1px solid #f1f4f9;
    display: block;
  }
  span {
    font-size: 13px;
    font-weight: 450;
    color: #000;
  }

  .time_table_card {
    padding: 2px 4px;
    color: #000;
    display: flex;
    p {
      font-size: 12px;
      margin-top: 2px;
      font-weight: 400;
      width: calc(100% - 20px);
    }
    div {
      width: 20px;
    }
  }
`;

// time table selecting page notice
export const NoticeTimeTableSelect = styled.div<{ theme: any }>`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: ${(props) => props.theme.element};
  border-radius: 6px;

  & > svg {
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.active_text};
    }
  }
`;

// tim table selecting page card
export const TimeTableCardBySubject = styled.div<{ theme: any }>`
  background-color: ${(props) => props.theme.element};
  border-radius: 6px;
  padding: 6px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    color: ${(props) => props.theme.text};
    font-size: 14px;
  }
  & > p {
    font-weight: 400;
    font-size: 13px;
    border: 1px solid ${(props) => props.theme.active_element};
    padding: 4px 0px 4px 6px;
    border-radius: 4px;
    margin: 4px 0px 0px 0px;
  }
`;


/* time table subject style */
export const TimeTableSubjectWrapper = styled.div<{ theme: any }>`
  .header-subject {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.active_element};
    color: ${(props) => props.theme.header_text};
    font-weight: 500;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: .5rem .5rem .25rem .25rem;
  }
  .subject-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: ${(props) => props.theme.element};
    color: ${(props) => props.theme.text};
    width: 100%;
    min-height: 3.2rem;
    border-radius: 0.3rem;
    cursor: pointer;
    &:hover{
        background-color: ${(props) => props.theme.active_element};
    }
  }
`;
