import { useState, useEffect } from 'react';
import { message } from 'antd';
import instance from 'config/_axios';



const useGetOneData = (props: { isCall?: 'auto' | undefined, url?: string }): { data: any, loading: boolean, fetch: (url: string) => void } => {

    const { isCall, url } = props;
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isCall === 'auto') {
            if (url) {
                fetch(url)
            } else {
                message.error("Url topilmadi !");
            }
        }
    }, [])

    const fetch = async (url: string) => {
        try {
            setLoading(true);
            const response = await instance({ url, method: 'GET' });

            if (response.data?.status === 1 && Object.getOwnPropertyNames(response.data?.data).length) {
                setData(response.data?.data);
            } else {
                message.error(new Error(response.data?.message));
            }
            setLoading(false);
        } catch (error: any) {
            message.error(error?.response?.message);
            setLoading(false);
        }
    }

    return { data, loading, fetch }

}

export default useGetOneData;