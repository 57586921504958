import { Alert, Button, Card, Col, Row } from 'antd';
import { FC } from 'react';
import { RiBook3Fill } from 'react-icons/ri';
import { FaChartPie, FaCalendarCheck, FaCalendarAlt, FaFileSignature } from 'react-icons/fa';
import { IoLibrarySharp } from 'react-icons/io5';
import { SelectStudentTheme } from 'config/theme/colors';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Wrapper } from './common/styled';
import { useTranslation } from 'react-i18next';
import { CardBody, Card as CardWrapper } from 'reactstrap';
import Statute from 'pages/statute';
import StatuteInHomePage from 'pages/statute/statute_home_page';
import { Title } from 'pages/styles/style';



const StudentProfileHomePage: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const theme = SelectStudentTheme();
    const history = useHistory();

    return (
        <Card bordered={false}>
            <Title color={theme.header_text}>{t('Asosiy')}</Title>
            <Wrapper theme={theme}>
                <Row>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                        <StatuteInHomePage />
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24} className="message" >
                        <Alert
                            style={{ borderRadius: ".25rem" }}
                            message={<span style={{ color: '#1890FF' }} >Talabalar turar joyi</span>}
                            description={
                                <span>
                                    Talabalar turar joyiga talabalarni joylashtirish tizimi endilikda ushbu tizimda ariza topshirish orqali amalga oshiriladi.
                                    <NavLink to={'/dormitory'}><Button type="link" >Ariza qoldirish</Button></NavLink>
                                </span>
                            }
                            type="info"
                            showIcon
                            banner
                        />
                    </Col>
                </Row>
            </Wrapper>
        </Card>
    )
}

export default StudentProfileHomePage;