import { SelectStudentTheme } from "config/theme/colors";
import React, { FC } from "react";
import { useRouteMatch, match } from 'react-router';
import { Col, Divider, message, Popconfirm, Row, Spin, Tag, Typography } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { ITimeTable } from "models/others";
import useDeleteOne from "hooks/useDeleteOne";
import styles from '../styles.module.scss';
import { BiListPlus, BiX } from "react-icons/bi";
import { Card, CardBody } from "reactstrap";
import TimeTableSelectItem from "../components/TimeTableSelectItem";
import instance from "config/_axios";
import "../common/style.scss";
import { NoticeTimeTableSelect } from "../common/styled";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import isHasAuthRoute from "utils/isHasRoute";


const { Text } = Typography;

const TimeTablesV1: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const match: match<any> | null = useRouteMatch("/time_tables_by_subjects-v1/:edu_semester_id/:subject_id");
    const history = useHistory();
    const [reFetch, setReFetch] = React.useState<boolean>(false);
    const _delete = useDeleteOne();
    const { items, loading } = useGetAllData({
        url: `/time-tables/parent-null?expand=selected,seminar.child,seminar.selected,seminar.studentTimeTable,seminar.selectedCount,seminar.child.subject,seminar.child.teacher,seminar.child.subjectCategory,seminar.child.eduPlan,seminar.child.para,seminar.child.week,seminar.child.room,seminar.subject,seminar.teacher,seminar.subjectCategory,seminar.eduPlan,seminar.para,seminar.week,seminar.room,studentTimeTable,selectedCount,language,teacher,building,room,para,week,subject,subjectCategory,eduPlan,timeTable,child.selected,child.room,child.week,child.para,child.eduPlan,child.subjectCategory,child.teacher,child.subject&filter=&filter=${JSON.stringify({ subject_id: match?.params?.subject_id, edu_semester_id: match?.params?.edu_semester_id })}`,
        isCall: 'auto',
        refetch: [_delete.refetch, reFetch]
    }) as TypeReturnUseGetAllData<ITimeTable>;

    const onSubmit = async (id: number) => {
        try {
            const findElement = items.find(e => e.id === id);
            if (findElement && Number(findElement.selectedCount) < Number(findElement.room?.capacity)) {
                let formdata = new FormData();
                formdata.append("time_table_id", String(id))
                const response = await instance({ url: "/student-time-tables", method: "POST", data: formdata })

                if (response.data.status === 1) {
                    setReFetch(prevState => !prevState)
                    message.success(response.data?.message)
                }
            } else {
                message.warning("bu dars jadval uchun limit to'lgan!")
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }


    const checking = React.useCallback((element: ITimeTable) => {

        if (Boolean(element.selected)) {
            if (!isHasAuthRoute("student-time-table_delete")) return null;
            return (
                <Popconfirm title="Bekor qilmoqchimisiz ?" placement="topRight" okText="Ha" cancelText="Yo'q" onConfirm={() => _delete.fetch(`/student-time-tables/${element.studentTimeTable?.id}`)} >
                    <BiX size={22} color="#D93025" />
                </Popconfirm>
            )
        }

        if (!isHasAuthRoute("student-time-table_create")) return null;

        if (Number(element.selectedCount) >= Number(element.room?.capacity)) return <span className={styles.full_status} >full</span>


        const isHasLecture = items.find(e => e.subject_category_id === 1 && !Boolean(e.parent_id));

        if (isHasLecture) {

            const isSelectedLecture = items.find(e => e.selected === 1 && e.subject_category_id === 1 && e.id !== element.id);
            const isSelectedSemenar = items.find(e => e.selected === 1 && e.subject_category_id !== 1 && e.id !== element.id);

            if (element.subject_category_id === 1) {
                if (isSelectedLecture) {
                    return null
                }
                return (
                    <Popconfirm title="Tanlamoqchimisiz ?" placement="topRight" okText="Ha" cancelText="Yo'q" onConfirm={() => onSubmit(element.id)} >
                        <BiListPlus size={25} />
                    </Popconfirm>
                )
            } else {
                if (isSelectedLecture) {
                    if (isSelectedSemenar) return null;
                    return (
                        <Popconfirm title="Tanlamoqchimisiz ?" placement="topRight" okText="Ha" cancelText="Yo'q" onConfirm={() => onSubmit(element.id)} >
                            <BiListPlus size={25} />
                        </Popconfirm>
                    )
                } else {
                    return null;
                }
            }
        } else {
            const isSelected = items.find(e => e.selected === 1 && e.subject_category_id !== 1 && e.id !== element.id);

            if (isSelected) return null;

            return (
                <Popconfirm title="Tanlamoqchimisiz ?" placement="topRight" okText="Ha" cancelText="Yo'q" onConfirm={() => onSubmit(element.id)} >
                    <BiListPlus size={25} />
                </Popconfirm>
            )

        }

    }, [items])

    return (
        <Spin spinning={loading} >
            <div className="not_user_select px-2">
                <div className="d-flex justify-content-between">
                    <h6 color={theme.header_text} >Dars jadval</h6>
                </div>
                <NoticeTimeTableSelect className="mt-2" theme={theme}>
                    <FaArrowLeft onClick={() => history.goBack()} color="#5A8DEE" size={13} /> <span className="mx-2" style={{ fontSize: 13, color: "#000" }}>Dars jadval tanlash | </span>
                    <div style={{ color: "#000", fontSize: '13px' }}>
                        <span style={{ color: "grey" }}>Fan nomi: &nbsp;</span><span>{items.length ? items[0]?.subject?.name : null}</span> &nbsp;
                    </div>
                </NoticeTimeTableSelect>
                <div style={{ backgroundColor: "#EFF2F4", padding: "12px" }} className="px-10 mt-3" >
                    <Row gutter={[12, 12]}>
                        <Col xl={24} xs={24}>
                            <Card>
                                <CardBody>
                                    <p style={{ fontSize: '14px' }}>
                                        <strong style={{ color: '#C26C03' }} >Eslatma !</strong><br /> Dars jadvalini tanlash uchun birinchi ma'ruza (  <Text type="warning">agarda bu fan uchun mavjud bo'lsa</Text>) dars jadvalini tanlashingiz talab etiladi va buni amalga oshirganingizdan so'ng bu fan uchun seminar dars jadvalini tanlashingiz mumkin.
                                        Faqatgina amaliy qismdan iborat fanlar bundan mustasno.
                                        &nbsp;<Text type="success">Tanlash <BiListPlus size={22} /> belgisini bosish orqali amalga oshiriladi. </Text>
                                        &nbsp;<Text type="danger">Bekor qilish <BiX size={20} color="#D93025" /> belgisini bosish orqali amalga oshiriladi. </Text>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        {
                            items?.sort((a, b) => b.selected - a.selected || a.subject_category_id - b.subject_category_id)?.map((parentElement) => {
                                if (!Boolean(parentElement.selected) || Boolean(parentElement.lecture_id)) return null
                                return (
                                    <>
                                        <Col key={parentElement.id} xl={6} lg={8} md={12} sm={12} xs={24}>
                                            <Card>
                                                <CardBody style={Boolean(parentElement.selected) ? { border: "1px solid #1F92FD", borderRadius: "4px", backgroundColor: '#F1F4F9' } : {}}>
                                                    <div className={styles.time_table_select_card_title}>
                                                        <span className={styles.selected_count}>{parentElement.subjectCategory?.name} : {parentElement.selectedCount} / {parentElement.room?.capacity}</span>
                                                        <div>
                                                            {Boolean(parentElement.selected) ? <span className={styles.selected_count}>Tanlandi</span> : null}
                                                            {checking(parentElement)}
                                                        </div>
                                                    </div>
                                                    <TimeTableSelectItem key={parentElement.id} element={parentElement} />
                                                    {
                                                        parentElement.child?.map((childElement) => <TimeTableSelectItem key={childElement.id} element={childElement} />)
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        {
                                            parentElement.seminar?.map((semenarItem) => {
                                                return (
                                                    <Col key={semenarItem.id} xl={6} lg={8} md={12} sm={12} xs={24}>
                                                        <Card>
                                                            <CardBody style={Boolean(semenarItem.selected) ? { border: "1px solid #1F92FD", borderRadius: "4px", backgroundColor: '#F1F4F9' } : {}}>
                                                                <div className={styles.time_table_select_card_title}>
                                                                    <span className={styles.selected_count}>{semenarItem.subjectCategory?.name} : {semenarItem.selectedCount} / {semenarItem.room?.capacity}</span>
                                                                    <div>
                                                                        {Boolean(semenarItem.selected) ? <span className={styles.selected_count}>Tanlandi</span> : null}
                                                                        {checking(semenarItem)}
                                                                    </div>
                                                                </div>
                                                                <TimeTableSelectItem key={semenarItem.id} element={semenarItem} />
                                                                {
                                                                    semenarItem.child?.map((childElement) => <TimeTableSelectItem key={childElement.id} element={childElement} />)
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
        </Spin >
    );
}


export default TimeTablesV1;



/**
student-time-table_create
student-time-table_delete
student-time-table_index
student-time-table_view
 */