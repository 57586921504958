import { FC } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { SelectStudentTheme } from 'config/theme/colors';
import { Wrapper } from './style';

export type BreadcrumbArgumentsType = {
    arr: Array<{
        name: string,
        path: string
    }>
}

const CustomBreadcrumb: FC<BreadcrumbArgumentsType> = ({ arr }): JSX.Element => {

    const theme = SelectStudentTheme();
    const { t } = useTranslation();
    const history = useHistory();
    const path: any = (element: { name: string, path: string }) => element.path.startsWith('/') ? element.path : `/${element.path}`

    return (
        <Wrapper theme={theme} >
            <div className="d-flex align-items-center" >
                <FaArrowLeft className="goBackBtn" onClick={() => history.goBack()} />
                <Breadcrumb>
                    {
                        arr.length && arr.map((element: { name: string, path: string }, index: number) => {
                            if (arr.length - 1 !== index) {
                                return (
                                    <Breadcrumb.Item key={index} >
                                        <NavLink to={path(element)} className="text-decoration-none">{t(element.name)}</NavLink>
                                    </Breadcrumb.Item>
                                )
                            } else {
                                return <Breadcrumb.Item key={index} > <span style={{ color: theme.header_text }} >{element.name}</span> </Breadcrumb.Item>
                            }
                        })
                    }
                </Breadcrumb>
            </div>
        </Wrapper>
    )
}


export default CustomBreadcrumb;