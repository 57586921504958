import { FC, useState } from "react";
import { SettingBarUi, SidebarSettingButton } from './styled';
import { BiMessageAltEdit, BiCalendarEdit, BiCustomize } from 'react-icons/bi';
import { NavLink, useLocation } from "react-router-dom";
import { SelectStudentTheme } from "config/theme/colors";
import { useAppSelector } from "store/services";
import { FaUserCog } from "react-icons/fa";
import { Button, Divider, Form, Input, Modal } from "antd";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";

const ConfigLink: FC = (): JSX.Element => {

    const [form] = Form.useForm();
    const ui = useAppSelector(state => state.ui);
    const messages = useAppSelector(state => state.data.messages);
    const auth: any = useAppSelector(state => state.auth);
    const _id = auth?.user?.user_id;
    const location = useLocation();
    const theme = SelectStudentTheme();
    const [visible, setVisible] = useState<boolean>(false);

    // const onSubmit = async (values: any) => {
    //     try{
    //         const formdata = new FormData();
    //         Object.entries(values).forEach(([key, value]: any) => {
    //             formdata.append(key, String(value));
    //         });

    //         const response = await instance({ url: `/passwords/${_id}`, method: 'PUT', data: formdata })

    //         if(response.data.status === 1){
    //             setVisible(false);
    //             form.resetFields();
    //         }
    //     } catch(error: any){
    //         asyncN("error", 'update', error?.response.data?.message)
    //     }
    // }

    return (
        <>
            {/* <Modal title="Parolni o'zgartirish" visible={visible} footer={false}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onSubmit}
                >
                    <Form.Item
                        label="Avvalgi parol"
                        name='old_password'
                        rules={[{ required: true, message: "Maydonni to'ldirish shart!" }]}
                    >
                        <Input.Password placeholder="Avvalgi parolingizni kiriting ..." />
                    </Form.Item>
                    <Form.Item
                        label="Yangi parol"
                        name='new_password'
                        rules={[{ required: true, message: "Maydonni to'ldirish shart!" }]}
                    >
                        <Input.Password placeholder="Yangi parolni kiriting ..." />
                    </Form.Item>
                    <Form.Item
                        label="Parolni takrorlang"
                        name='re_password'
                        rules={[{ required: true, message: "Maydonni to'ldirish shart!" }]}
                    >
                        <Input.Password placeholder="Parolni qayta kiriting ..." />
                    </Form.Item>
                    <Divider className="mt-5" />
                    <div className="text-end" >
                        <Button type="primary" danger onClick={() => {setVisible(false); form.resetFields()}}>Bekor qilish</Button>
                        <Button className="ms-2" htmlType="submit" type="primary">Saqlash</Button>
                    </div>
                </Form>
            </Modal> */}


            <SettingBarUi className="asdassad" theme={theme} ui={ui?.sidebar} active={['/chat', '/note', '/message', '/student_profile'].includes(location.pathname)}>
                <button>
                    <BiCustomize />
                </button>
                {/* <NavLink to={"/chat"}>
                <SidebarSettingButton theme={theme} ui={ui?.sidebar} active={location.pathname === "/chat"}>
                    <span>100</span>
                    <BiMessageRoundedDots />
                </SidebarSettingButton>
            </NavLink> */}
                {/* <NavLink to={"/notification"}> */}
                <SidebarSettingButton theme={theme} ui={ui?.sidebar} active={location.pathname === "/message"}>
                    <span>{messages.count}</span>
                    <BiMessageAltEdit />
                </SidebarSettingButton>
                {/* </NavLink> */}
                {/* <NavLink to={"/note"}> */}
                <SidebarSettingButton theme={theme} ui={ui?.sidebar} active={location.pathname === "/note"}>
                    <BiCalendarEdit />
                </SidebarSettingButton>
                {/* </NavLink> */}
                <NavLink to={"/profile"}>
                    <SidebarSettingButton theme={theme} ui={ui?.sidebar} active={location.pathname === "/profile"}>
                        <FaUserCog />
                    </SidebarSettingButton>
                </NavLink>
            </SettingBarUi>
        </>
    )
}

export default ConfigLink;