import * as React from 'react';



const NonLayout: React.FC = ({ children }): JSX.Element => {



    return (
        <>
            <div>
                {children}
            </div>
        </>
    )
}

export default NonLayout;