import { FC, useState, useEffect } from "react";
import { ArrayChecker } from "utils/ArrayChecker";
import moment from 'moment';
import instance from "config/_axios";
import { message, Badge, Card } from "antd";


const Notification: FC = (): JSX.Element => {

    const [messages, setMessages] = useState<Array<any>>([])

    useEffect(() => {
        (
            async () => {
                try {

                    const response = await instance({ url: `/notifications?sort=-id&expand=statusName,roles,createdBy&all=1`, method: 'GET' })

                    if (response.data?.status === 1) {
                        setMessages(response.data?.data?.items);
                    }
                } catch (error: any) {
                    message.error(error?.response?.message)
                }
            }
        )()
    }, [])


    return (
        <div>
            {
                ArrayChecker(messages)._ && messages.map((element) => {
                    return (
                        <Badge.Ribbon key={element.id} text={moment.unix(element?.created_at).format("DD-MM-YYYY")} >
                            <Card title="Dekan" className="mt-2">
                                {element?.notification}
                            </Card>
                        </Badge.Ribbon>
                    )
                })
            }
        </div>
    )
}



export default Notification;