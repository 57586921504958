import { sidebar_width_lg, sidebar_width_sm, sidebar_width_xs } from "config/utils/variable";
import styled, { css } from "styled-components";



export const BtnStyleHeader = styled.button<{ theme: any }>`
    border:none;
    border-radius:4px;
    /* border: 2px solid #EDF1FF; */
    width: 30px;
    height: 30px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size: 16px;
    margin:0  4px;
    background-color: ${p => p.theme.card};
    transition: all 0.1s;
    &:hover {
        background-color: ${p => p.theme.element};
        svg {
            color:#5A8DEE;
        }
    }

    svg {
        color:#5D48EC;
    }

    @media (min-width: 786px) and (max-width: 1320px) {
        border-radius:4px;
        width: 32px;
        height: 32px;
        font-size: 14px;
        margin: 0 6px;
    }


    @media (min-width: 576px) and (max-width: 786px) {
        border-radius:4px;
        width: 28px;
        height: 28px;
        font-size: 14px;
        margin: 0 3px;
    }
`



export const SearchBarUi = styled.form<{ screen: string | undefined, theme: any }>`

    ${props => props.screen && props.screen === "mobile" ?
        css`
        border-radius: 4px;
        height: 28px;
        width: 80%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-left: 20px;
        padding: 0 0px 0 10px;
        border: 1px solid ${props.theme.element};
        & > input {
            border:none;
            background-color:transparent;
            color:#717171;
            font-size: 13px;
            width: 230px;

            &:focus {
                outline:none;
            }

            &::placeholder {
                color:${props.theme.text};
            }
            &:-ms-input-placeholder {
                color:${props.theme.text};
            }
            &::-ms-input-placeholder {
                color:${props.theme.text};
            }
        }
        & > button {
            border:none;
            background-color:${props.theme.element};
            color:${props.theme.text};
            font-size:14px;
            height: 100%;
            width: max-content;
            cursor: pointer !important;
            padding: 2px 12px;
            border-top-right-radius:4px;
            border-bottom-right-radius:4px;

            & > span {
                display:none;
            }
        }
        `:
        css`
            border-radius: 4px;
            height: 30px;
            width: 320px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            margin-left: 20px;
            padding: 0 0px 0 10px;
            border:1px solid ${props.theme.element};
            & > input {
                border:none;
                background-color:transparent;
                color:${props.theme.text};
                font-size: 14px;
                width: 230px;

                &:focus {
                    outline:none;
                }

                &::placeholder {
                    color:${props.theme.text};
                }
                &:-ms-input-placeholder {
                    color:${props.theme.text};
                }
                &::-ms-input-placeholder {
                    color:${props.theme.text};
                }
            }
            & > button {
                border:none;
                background-color:${props.theme.element};
                color:${props.theme.text};
                font-size:14px;
                height: 100%;
                width: max-content;
                cursor: pointer !important;
                padding: 2px 12px;
                border-top-right-radius:4px;
                border-bottom-right-radius:4px;
                & > svg {
                    display:none;
                }
            }


            @media (min-width: 786px) and (max-width: 1320px) {
                border-radius: 4px;
                width: 270px;
                height: 32px;
                margin-left: 20px;
                padding: 0 0px 0 8px;
                & > input {
                    font-size: 13px;
                    width: 230px;

                    &:focus {
                        outline:none;
                    }
                }
                & > button {
                    font-size:13px;
                    padding: 2px 8px;
                    border-top-right-radius:4px;
                    border-bottom-right-radius:4px;
                    &:active {
                        background-color:#EDEEF0;
                    }
                }
            }

            @media (min-width: 576px) and (max-width: 786px) {
                border-radius: 4px;
                width: 200px;
                height: 28px;
                margin-left: 10px;
                padding: 0 0px 0 8px;
                & > input {
                    font-size: 12px;
                    width: 175px;

                    &:focus {
                        outline:none;
                    }
                }
                & > button {
                    font-size:12px;
                    padding: 2px 8px;
                    border-top-right-radius:4px;
                    border-bottom-right-radius:4px;
                    &:active {
                        background-color:#EDEEF0;
                    }

                    & > span {
                        display:none;
                    }
                    & > svg {
                        display:block;
                    }
                }
            }
        `
    }

`



export const Std_header_logo = styled.div<{ theme: any }>`


// background-color:${props => props.theme.element};

width: ${sidebar_width_lg};
height: 30px;
padding: 0px 10px;
border-radius: 6px;
display:flex;
align-items:center;
justify-content:space-between;

& > button {
    background-color: transparent;
    padding: 4px 4px;
    border-radius:4px;
    border:none;
    color: ${props => props.theme.blue};
    // color:${props => props.theme.icon};

    &:hover {
        color: ${props => props.theme.blue};
    }
}
& > span {
    padding: 4px 6px;
    border-radius:4px;
    text-align:center;
    color: ${props => props.theme.blue};
    font-weight:bold;

    & > span {
        border-left: 1px solid ${props => props.theme.text};
        font-weight:400;
        padding-left: 2px;
        margin-left:2px;
    }
}

@media (max-width:1500px){
    width: ${sidebar_width_sm};
}

@media (min-width: 786px) and (max-width: 1320px) {
    width: 200px;
    height: 32px;
    padding: 0px 6px;
    border-radius: 4px;

    & > button {
        padding: 4px 4px;
        border-radius:4px;
        font-size: 15px;
    }
    & > span {
        font-size: 15px;

        & > span {
            margin-left:2px;
        }
    }
}
@media (min-width: 576px) and (max-width: 786px) {
    width: ${sidebar_width_xs};
    height: 28px;
    padding: 0px 4px;
    border-radius: 4px;

    & > button {
        padding: 4px 4px;
        border-radius:4px;
        font-size: 13px;
    }
    & > span {
        font-size: 13px;

        & > span {
            display:none;
        }
    }
}


`