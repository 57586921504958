/** @format */

import styled, { css, keyframes } from "styled-components";

export const CustomModalBox = styled.div<{ isOpen: boolean; theme: any }>`
  border-radius: 4px;

  ${(props) =>
    props.isOpen
      ? css`
          width: 220px;
          background-color: ${(props) => props.theme.element};
          animation: 0.1s ${modal_opens} ease-out;
          padding: 4px 10px;
        color: ${props => props.theme.text};
          & > form {
            display: block;
            visibility: visible;

            .custom_modal_header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 12px;
              border-bottom: 1px solid ${props => props.theme.text};
              padding: 2px 0;

              & > svg:hover {
                color: #0d6efd;
                cursor: pointer;
              }
            }

            textarea {
              background-color: transparent;
              width: 100%;
              margin-top: 6px;
              border: 1px solid ${props => props.theme.text};
              font-size: 13px;

              &:focus {
                outline: none;
              }
            }

            button {
              width: 100%;
              /* color: #a3a8ae; */
              color: ${props => props.theme.text};
              font-size: 14px;
              text-align: center;
              /* border: 1px solid #2c3e50; */
              border: 1px solid ${props => props.theme.text};
              border-radius: 4px;
              background-color: #2c3e50;
              background-color: ${props => props.theme.active_element};
            }
          }
        `
      : css`
          width: 0px;
          height: 0px;
          animation-delay: 0.1s;

          & > form {
            display: none;
            visibility: hidden;
            transition: all 0.2s ease-in;
          }
        `}
`;

const modal_opens = keyframes`
    0% {
        display: block;
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;
