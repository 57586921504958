import { SelectStudentTheme } from "config/theme/colors";
import React, { FC } from "react";
import { useRouteMatch, match } from 'react-router';
import { NavLink, useHistory } from "react-router-dom";
import { Button, Col, Divider, message, Popconfirm, Row, Spin, Typography } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { ITimeOption } from "models/others";
import useDeleteOne from "hooks/useDeleteOne";
import styles from '../styles.module.scss';
import "../common/style.scss";
import { BiBookOpen, BiTime, BiUserVoice } from "react-icons/bi";
import { Card } from "antd";
import { NoticeTimeTableSelect } from "../common/styled";
import { FaArrowLeft } from "react-icons/fa";
import instance from "config/_axios";
import { Title } from "pages/styles/style";

const { Text } = Typography;

const TimeTableVariants: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const match: match<any> | null = useRouteMatch("/time_table_variants/:edu_semester_id");
    const _delete = useDeleteOne();
    const history = useHistory();
    const [reFetch, setReFetch] = React.useState<boolean>(false);
    const timeOptions = useGetAllData({
        url: `/time-options?expand=selected,selectedCount,faculty,eduSemester.semestr,timeTables.week,timeTables.para,timeTables.subjectCategory,timeTables.teacher,timeTables.subject&filter=${JSON.stringify({ edu_semestr_id: match?.params?.edu_semester_id })}`,
        perPage: 0,
        isCall: 'auto',
        refetch: [reFetch],
    }) as TypeReturnUseGetAllData<ITimeOption>;
    /*{
        "items": [
            {
                "id": 151,
                "name": "A(uz)",
                "capacity": 73,
                "key": "A",
                "faculty_id": 2,
                "edu_plan_id": 56,
                "edu_year_id": 16,
                "edu_semester_id": 331,
                "language_id": 1,
                "type": null,
                "description": null,
                "status": 1,
                "created_at": 1663163186,
                "updated_at": 1663339657,
                "created_by": 1,
                "updated_by": 11,
                "faculty": {
                    "id": 2,
                    "name": "Ommaviy huquq",
                    "user_id": 8988,
                    "order": 1,
                    "status": 1,
                    "created_at": 1,
                    "updated_at": 1674460994,
                    "created_by": 0,
                    "updated_by": 7401
                },
                "eduSemester": {
                    "id": 331,
                    "name": "2022-2023-4-7",
                    "edu_plan_id": 56,
                    "course_id": 4,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 7,
                    "credit": 0,
                    "edu_year_id": 16,
                    "start_date": "2022-09-04 00:00:00",
                    "end_date": "2023-01-31 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 1,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 7,
                        "name": "7",
                        "order": 1,
                        "status": 1,
                        "type": 1,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                "timeTables": [
                    {
                        "id": 671,
                        "teacher_access_id": 1859,
                        "room_id": 69,
                        "para_id": 3,
                        "week_id": 3,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 151,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 286,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662547004,
                        "updated_at": 1663326382,
                        "created_by": 591,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 3,
                            "name": "3",
                            "start_time": "10:30",
                            "end_time": "11:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997350,
                            "updated_at": 1662457753,
                            "created_by": 1,
                            "updated_by": 11
                        },
                        "week": {
                            "id": 3,
                            "name": "Chorshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852021,
                            "updated_at": 1644852021,
                            "created_by": 112,
                            "updated_by": 0
                        },
                        "subject": {
                            "id": 286,
                            "name": "Mahalliy hokimiyat huquqi",
                            "kafedra_id": 15,
                            "semestr_id": 7,
                            "parent_id": 286,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1660303924,
                            "updated_at": 1660303939,
                            "created_by": 591,
                            "updated_by": 591
                        },
                        "teacher": {
                            "user_id": 7590,
                            "first_name": "ATABEK",
                            "last_name": "BABADJANOV",
                            "middle_name": "DAVRONBEKOVICH"
                        }
                    },
                    {
                        "id": 744,
                        "teacher_access_id": 2549,
                        "room_id": 59,
                        "para_id": 1,
                        "week_id": 3,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 151,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 300,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662549394,
                        "updated_at": 1663325636,
                        "created_by": 596,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 3,
                            "name": "Chorshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852021,
                            "updated_at": 1644852021,
                            "created_by": 112,
                            "updated_by": 0
                        },
                        "subject": {
                            "id": 300,
                            "name": "Yuridik texnika",
                            "kafedra_id": 1,
                            "semestr_id": 1,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1661259600,
                            "updated_at": 1661259600,
                            "created_by": 596,
                            "updated_by": 0
                        },
                        "teacher": {
                            "user_id": 7462,
                            "first_name": "AKMAL",
                            "last_name": "SODIKOV",
                            "middle_name": "SHAVKAT O‘G‘LI"
                        }
                    },
                    {
                        "id": 795,
                        "teacher_access_id": 2402,
                        "room_id": 69,
                        "para_id": 1,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 151,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 309,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662550422,
                        "updated_at": 1663326281,
                        "created_by": 591,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 4,
                            "name": "Payshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852031,
                            "updated_at": 1659070110,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 309,
                            "name": "Nodavlat notijorat tashkilotlar huquqi",
                            "kafedra_id": 15,
                            "semestr_id": 7,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1661523979,
                            "updated_at": 1661670599,
                            "created_by": 591,
                            "updated_by": 591
                        },
                        "teacher": {
                            "user_id": 591,
                            "first_name": "Bekzod",
                            "last_name": "Narimanov",
                            "middle_name": "Abduvaliyevich"
                        }
                    },
                    {
                        "id": 3135,
                        "teacher_access_id": 3125,
                        "room_id": 69,
                        "para_id": 1,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 151,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 357,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662871792,
                        "updated_at": 1663326909,
                        "created_by": 10,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 5,
                            "name": "Juma",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852242,
                            "updated_at": 1659070142,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 357,
                            "name": "Yuridik xizmat",
                            "kafedra_id": 6,
                            "semestr_id": 7,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1662698284,
                            "updated_at": 1662871153,
                            "created_by": 7401,
                            "updated_by": 599
                        },
                        "teacher": {
                            "user_id": 4565,
                            "first_name": "ELMUROD",
                            "last_name": "AXUNDJANOV",
                            "middle_name": "PAYZULLAYEVICH"
                        }
                    }
                ],
                "selected": 0,
                "selectedCount": 72
            },
            {
                "id": 152,
                "name": "B(uz)",
                "capacity": 77,
                "key": "B",
                "faculty_id": 2,
                "edu_plan_id": 56,
                "edu_year_id": 16,
                "edu_semester_id": 331,
                "language_id": 1,
                "type": null,
                "description": null,
                "status": 1,
                "created_at": 1663163186,
                "updated_at": 1663855674,
                "created_by": 1,
                "updated_by": 11,
                "faculty": {
                    "id": 2,
                    "name": "Ommaviy huquq",
                    "user_id": 8988,
                    "order": 1,
                    "status": 1,
                    "created_at": 1,
                    "updated_at": 1674460994,
                    "created_by": 0,
                    "updated_by": 7401
                },
                "eduSemester": {
                    "id": 331,
                    "name": "2022-2023-4-7",
                    "edu_plan_id": 56,
                    "course_id": 4,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 7,
                    "credit": 0,
                    "edu_year_id": 16,
                    "start_date": "2022-09-04 00:00:00",
                    "end_date": "2023-01-31 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 1,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 7,
                        "name": "7",
                        "order": 1,
                        "status": 1,
                        "type": 1,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                "timeTables": [
                    {
                        "id": 759,
                        "teacher_access_id": 3518,
                        "room_id": 67,
                        "para_id": 1,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 152,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 300,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662549716,
                        "updated_at": 1668667605,
                        "created_by": 596,
                        "updated_by": 7401,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 4,
                            "name": "Payshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852031,
                            "updated_at": 1659070110,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 300,
                            "name": "Yuridik texnika",
                            "kafedra_id": 1,
                            "semestr_id": 1,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1661259600,
                            "updated_at": 1661259600,
                            "created_by": 596,
                            "updated_by": 0
                        },
                        "teacher": {
                            "user_id": 8991,
                            "first_name": "SHERZAD",
                            "last_name": "RABIYEV",
                            "middle_name": "MIRJALILOVICH"
                        }
                    },
                    {
                        "id": 856,
                        "teacher_access_id": 3046,
                        "room_id": 67,
                        "para_id": 1,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 152,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 309,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662551605,
                        "updated_at": 1663326271,
                        "created_by": 591,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 5,
                            "name": "Juma",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852242,
                            "updated_at": 1659070142,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 309,
                            "name": "Nodavlat notijorat tashkilotlar huquqi",
                            "kafedra_id": 15,
                            "semestr_id": 7,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1661523979,
                            "updated_at": 1661670599,
                            "created_by": 591,
                            "updated_by": 591
                        },
                        "teacher": {
                            "user_id": 7697,
                            "first_name": "Axrorxon",
                            "last_name": "Xashimxonov",
                            "middle_name": "Muminovich"
                        }
                    },
                    {
                        "id": 869,
                        "teacher_access_id": 2047,
                        "room_id": 67,
                        "para_id": 3,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 152,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 286,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662551906,
                        "updated_at": 1663326377,
                        "created_by": 591,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 3,
                            "name": "3",
                            "start_time": "10:30",
                            "end_time": "11:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997350,
                            "updated_at": 1662457753,
                            "created_by": 1,
                            "updated_by": 11
                        },
                        "week": {
                            "id": 4,
                            "name": "Payshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852031,
                            "updated_at": 1659070110,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 286,
                            "name": "Mahalliy hokimiyat huquqi",
                            "kafedra_id": 15,
                            "semestr_id": 7,
                            "parent_id": 286,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1660303924,
                            "updated_at": 1660303939,
                            "created_by": 591,
                            "updated_by": 591
                        },
                        "teacher": {
                            "user_id": 4556,
                            "first_name": "ESHMUXAMAD",
                            "last_name": "KADIROV",
                            "middle_name": "OMANTURDIYEVICH"
                        }
                    },
                    {
                        "id": 3143,
                        "teacher_access_id": 3121,
                        "room_id": 67,
                        "para_id": 1,
                        "week_id": 3,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 152,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 357,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662872020,
                        "updated_at": 1663326527,
                        "created_by": 10,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 3,
                            "name": "Chorshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852021,
                            "updated_at": 1644852021,
                            "created_by": 112,
                            "updated_by": 0
                        },
                        "subject": {
                            "id": 357,
                            "name": "Yuridik xizmat",
                            "kafedra_id": 6,
                            "semestr_id": 7,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1662698284,
                            "updated_at": 1662871153,
                            "created_by": 7401,
                            "updated_by": 599
                        },
                        "teacher": {
                            "user_id": 4542,
                            "first_name": "ADXAM",
                            "last_name": "RAJABOV",
                            "middle_name": "AMRULLOYEVICH"
                        }
                    }
                ],
                "selected": 0,
                "selectedCount": 73
            },
            {
                "id": 153,
                "name": "C(uz)",
                "capacity": 55,
                "key": "C",
                "faculty_id": 2,
                "edu_plan_id": 56,
                "edu_year_id": 16,
                "edu_semester_id": 331,
                "language_id": 1,
                "type": null,
                "description": null,
                "status": 1,
                "created_at": 1663163186,
                "updated_at": 1663340839,
                "created_by": 1,
                "updated_by": 11,
                "faculty": {
                    "id": 2,
                    "name": "Ommaviy huquq",
                    "user_id": 8988,
                    "order": 1,
                    "status": 1,
                    "created_at": 1,
                    "updated_at": 1674460994,
                    "created_by": 0,
                    "updated_by": 7401
                },
                "eduSemester": {
                    "id": 331,
                    "name": "2022-2023-4-7",
                    "edu_plan_id": 56,
                    "course_id": 4,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 7,
                    "credit": 0,
                    "edu_year_id": 16,
                    "start_date": "2022-09-04 00:00:00",
                    "end_date": "2023-01-31 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 1,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 7,
                        "name": "7",
                        "order": 1,
                        "status": 1,
                        "type": 1,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                "timeTables": [
                    {
                        "id": 776,
                        "teacher_access_id": 2549,
                        "room_id": 59,
                        "para_id": 1,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 300,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662550020,
                        "updated_at": 1663325597,
                        "created_by": 596,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 5,
                            "name": "Juma",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852242,
                            "updated_at": 1659070142,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 300,
                            "name": "Yuridik texnika",
                            "kafedra_id": 1,
                            "semestr_id": 1,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1661259600,
                            "updated_at": 1661259600,
                            "created_by": 596,
                            "updated_by": 0
                        },
                        "teacher": {
                            "user_id": 7462,
                            "first_name": "AKMAL",
                            "last_name": "SODIKOV",
                            "middle_name": "SHAVKAT O‘G‘LI"
                        }
                    },
                    {
                        "id": 1093,
                        "teacher_access_id": 2842,
                        "room_id": 59,
                        "para_id": 3,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 309,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662556656,
                        "updated_at": 1669186033,
                        "created_by": 591,
                        "updated_by": 7401,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 3,
                            "name": "3",
                            "start_time": "10:30",
                            "end_time": "11:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997350,
                            "updated_at": 1662457753,
                            "created_by": 1,
                            "updated_by": 11
                        },
                        "week": {
                            "id": 4,
                            "name": "Payshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852031,
                            "updated_at": 1659070110,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 309,
                            "name": "Nodavlat notijorat tashkilotlar huquqi",
                            "kafedra_id": 15,
                            "semestr_id": 7,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1661523979,
                            "updated_at": 1661670599,
                            "created_by": 591,
                            "updated_by": 591
                        },
                        "teacher": {
                            "user_id": 4552,
                            "first_name": "JAVLONBEK",
                            "last_name": "BAXODIROV",
                            "middle_name": "BAXODIROVICH"
                        }
                    },
                    {
                        "id": 1148,
                        "teacher_access_id": 2047,
                        "room_id": 59,
                        "para_id": 3,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 286,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662558400,
                        "updated_at": 1663326365,
                        "created_by": 591,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 3,
                            "name": "3",
                            "start_time": "10:30",
                            "end_time": "11:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997350,
                            "updated_at": 1662457753,
                            "created_by": 1,
                            "updated_by": 11
                        },
                        "week": {
                            "id": 5,
                            "name": "Juma",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852242,
                            "updated_at": 1659070142,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 286,
                            "name": "Mahalliy hokimiyat huquqi",
                            "kafedra_id": 15,
                            "semestr_id": 7,
                            "parent_id": 286,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1660303924,
                            "updated_at": 1660303939,
                            "created_by": 591,
                            "updated_by": 591
                        },
                        "teacher": {
                            "user_id": 4556,
                            "first_name": "ESHMUXAMAD",
                            "last_name": "KADIROV",
                            "middle_name": "OMANTURDIYEVICH"
                        }
                    },
                    {
                        "id": 3313,
                        "teacher_access_id": 1744,
                        "room_id": 59,
                        "para_id": 1,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 209,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662991000,
                        "updated_at": 1663327087,
                        "created_by": 601,
                        "updated_by": 308,
                        "subjectCategory": {
                            "id": 1,
                            "name": "Ma'ruza",
                            "order": 1,
                            "type": 1,
                            "status": 1,
                            "created_at": 1643784509,
                            "updated_at": 1643784509,
                            "created_by": 2,
                            "updated_by": 0
                        },
                        "para": {
                            "id": 1,
                            "name": "1",
                            "start_time": "08:30",
                            "end_time": "09:30",
                            "order": 1,
                            "status": 1,
                            "created_at": 1642997338,
                            "updated_at": 1651036105,
                            "created_by": 1,
                            "updated_by": 4
                        },
                        "week": {
                            "id": 4,
                            "name": "Payshanba",
                            "order": 1,
                            "status": 1,
                            "created_at": 1644852031,
                            "updated_at": 1659070110,
                            "created_by": 112,
                            "updated_by": 1
                        },
                        "subject": {
                            "id": 209,
                            "name": "Xalqaro xususiy huquq",
                            "kafedra_id": 20,
                            "semestr_id": 5,
                            "parent_id": null,
                            "is_deleted": 0,
                            "order": 1,
                            "status": 1,
                            "created_at": 1655183474,
                            "updated_at": 1655183540,
                            "created_by": 601,
                            "updated_by": 601
                        },
                        "teacher": {
                            "user_id": 601,
                            "first_name": "Odilbek",
                            "last_name": "Xazratqulov",
                            "middle_name": "Tursunovich"
                        }
                    }
                ],
                "selected": 1,
                "selectedCount": 51
            }
        ],
        "_links": {
            "self": {
                "href": "https://api-digital.tsul.uz/api/web/uz/time-options?expand=selected%2CselectedCount%2Cfaculty%2CeduSemester.semestr%2CtimeTables.week%2CtimeTables.para%2CtimeTables.subjectCategory%2CtimeTables.teacher%2CtimeTables.subject&filter=%7B%22edu_semestr_id%22%3A%22undefined%22%7D&per-page=0&page=1"
            },
            "first": {
                "href": "https://api-digital.tsul.uz/api/web/uz/time-options?expand=selected%2CselectedCount%2Cfaculty%2CeduSemester.semestr%2CtimeTables.week%2CtimeTables.para%2CtimeTables.subjectCategory%2CtimeTables.teacher%2CtimeTables.subject&filter=%7B%22edu_semestr_id%22%3A%22undefined%22%7D&per-page=0&page=1"
            },
            "last": {
                "href": "https://api-digital.tsul.uz/api/web/uz/time-options?expand=selected%2CselectedCount%2Cfaculty%2CeduSemester.semestr%2CtimeTables.week%2CtimeTables.para%2CtimeTables.subjectCategory%2CtimeTables.teacher%2CtimeTables.subject&filter=%7B%22edu_semestr_id%22%3A%22undefined%22%7D&per-page=0&page=1"
            }
        },
        "_meta": {
            "totalCount": 3,
            "pageCount": 1,
            "currentPage": 1,
            "perPage": 0
        },
        loading: false
    }*/



    const selectTimeOption = async (id: number) => {
        try {
            if (!id) {
                message.error("Ma'lumot yuborishda xatolik!")
                return;
            }

            let formdata = new FormData();
            formdata.append("time_option_id", String(id))
            const response = await instance({ url: "/student-time-options?", method: "POST", data: formdata })

            if (response.data.status === 1) {
                setReFetch(prevState => !prevState)
                message.success(response.data?.message)
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }

    const isSelectedOption = () => {
        const isSelectded = timeOptions.items.some(e => e.selected === 1)
        return isSelectded;
    }

    return (
        <Card bordered={false}>
            <Spin spinning={timeOptions.loading} >
                <div className="not_user_select px-2">
                    <div className="d-flex justify-content-between">
                        <Title color={theme.header_text} >Dars jadval</Title>
                    </div>
                    <NoticeTimeTableSelect className="mt-2" theme={theme}>
                        <FaArrowLeft onClick={() => history.goBack()} color="#5A8DEE" size={13} /> <span className="mx-2" style={{ fontSize: 13, color: "#000" }}>Dars jadval tanlash</span>
                    </NoticeTimeTableSelect>
                    <Divider className="my-2" ></Divider>
                    {/* <div style={{ backgroundColor: "#EFF2F4", padding: "12px" }} className="px-10" > */}
                        <Card className="mb-2">
                            <p style={{ fontSize: '14px' }}>
                                <strong style={{ color: '#C26C03' }} >Eslatma ! Tanlash bir marotaba.</strong><br />
                                Quyida keltirilgan variantlarda dars jadvallari mavjud bo'lib, ular bilan tanishib chiqish uchun
                                variantdagi ( <strong style={{ color: '#0D6EFD' }} >VARIANT - </strong> ) so'ziningning ustiga bosing  va undagi mavjud dars jadvallari bilan tanishib chiqing.
                                Tanishganingizdan so'ng bu variantlardan birini tanlashingiz mumkin
                            </p>
                        </Card>
                        <Row gutter={[12, 12]}>
                            {
                                timeOptions.items?.map((element) => {
                                    return (
                                        <Col key={element.id} xl={6} lg={6} md={12} sm={12} xs={24}>
                                            <NavLink to={`/time_tables_by_subject/${element.id}`} style={{textDecoration: 'none'}}>
                                                <Card style={Boolean(element.selected) ? { border: "1px solid #1F92FD", borderRadius: "4px" } : {}} title={
                                                    <h5>Variant - {element?.name}</h5>
                                                }>
                                                    <div className="d-f justify-content-between">
                                                        <span className="px-1" style={Boolean(element.selected) ? { backgroundColor: "#1F92FD" } : { backgroundColor: "#c26c03", color: 'white' }} >Tanlandi: {element?.selectedCount} / {element?.capacity}</span>
                                                        {
                                                            !isSelectedOption() ?
                                                                (Number(element.selectedCount) < Number(element.capacity) ?
                                                                    <Popconfirm placement="topRight" title={"Tanlamoqchimisiz ?"} onConfirm={() => selectTimeOption(element.id)} okText="Ha" cancelText="Yo'q">
                                                                        <Button type="primary" size="small">Tanlash</Button>
                                                                    </Popconfirm> : null) : null
                                                        }
                                                    </div>
                                                    {
                                                        element.timeTables?.map((childElement) => {
                                                            return (
                                                                <div key={childElement.id} style={{ cursor: 'pointer' }} className={styles.time_table_select_card} >
                                                                    <span><BiTime />{childElement.week?.name} / {childElement.para?.start_time}-{childElement.para?.end_time} / {childElement.subjectCategory?.name}</span>
                                                                    <span><BiBookOpen />{childElement.subject?.name}</span>
                                                                    <span><BiUserVoice />{childElement.teacher?.last_name}&nbsp;{childElement.teacher?.first_name}.&nbsp;{String(childElement.teacher?.middle_name).charAt(0).toUpperCase()}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Card>
                                            </NavLink>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    {/* </div> */}
                </div>
            </Spin>
        </Card>
    );
}


export default TimeTableVariants;



/**
student-time-table_create
student-time-table_delete
student-time-table_index
student-time-table_view
 */