import { useEffect, useState } from "react"
import "./common/style.scss";
import { SelectStudentTheme } from "config/theme/colors";
import React from "react";
import { FC } from "react";
import { Alert, Button, Col, Divider, Popconfirm, Row, Spin, Tag, Typography } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { IStudentTimeTable, ITimeOption, IWeek } from "models/others";
import { IEducationSemester, IEducationSemesterSubject, IPara } from "models/education";
import useDeleteOne from "hooks/useDeleteOne";
import styles from './styles.module.scss';
import { NavLink } from "react-router-dom";
import { SelectSemesterBtn } from "pages/subjects/common/styled";
import { BiArrowFromLeft, BiBookOpen, BiTime, BiUserVoice, BiX } from "react-icons/bi";
import isHasAuthRoute from "utils/isHasRoute";
import instance from "config/_axios";
import { Card } from "antd";
import { Title } from "pages/styles/style";


const { Text } = Typography;

const StudentLessonSchedule: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const [eduSemesters, setEduSemesters] = useState<IEducationSemester[]>([]);
    const [eduSemester, setEduSemester] = useState<IEducationSemester>();
    const [subjectIds, setSubjectIds] = useState<number[]>([]);
    const para = useGetAllData({ url: `/paras`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IPara>;
    const weeks = useGetAllData({ url: `/weeks`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IWeek>;
    const edu_semesters = useGetAllData({ url: `/edu-semestrs?expand=semestr`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemester>
    const subjects = useGetAllData({ url: `/edu-semestr-subjects?expand=subject,selection&filter=${JSON.stringify({ edu_semestr_id: eduSemester?.id })}`, perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemesterSubject>;
    const timeOptions = useGetAllData({ url: `/time-options?expand=selected,selectedCount,timeTables.subjectType,faculty,eduSemester.semestr,timeTables.week,timeTables.para,timeTables.subjectCategory,timeTables.teacher,timeTables.subject&filter=${JSON.stringify({ edu_semestr_id: eduSemester?.id, selected: 1 })}`, perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<ITimeOption>;
    const [refetch, setRefetch] = React.useState<boolean>(false)
    const _delete = useDeleteOne();

    const timeTables = useGetAllData({
        url: `/student-time-tables?sort=-id&expand=subject,teacher,subjectCategory,building,room,para,week,timeTable.language,timeTable.selected`,
        perPage: 0
    }) as TypeReturnUseGetAllData<IStudentTimeTable>;

    useEffect(() => {
        if (edu_semesters.items.length) {
            const findActiveEduSemester = edu_semesters.items.find((element) => element.status === 1)
            if(eduSemester === undefined){
                setEduSemester(findActiveEduSemester);
            }
            if (findActiveEduSemester) {
                timeTables.fetch();
                subjects.fetch()
            }
        }
    }, [edu_semesters.items, eduSemester, _delete.refetch])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const response = await instance({ url: '/edu-semestrs', method: 'GET' })

    //             if (response.data.status === 1) {

    //                 const items = response.data?.data?.items as IEducationSemester[];
    //                 setEduSemesters(items ?? []);
    //                 const findActiveSemester = items?.find(element => element.status === 1);
    //                 setEduSemester(findActiveSemester)
    //                 timeTables.fetch();
    //                 timeOptions.fetch();
    //             }
    //         }
    //     )()
    // }, [_delete.refetch])



    // const checkSubject = (subject_id: number) => {
    //     let arr: number[] = []

    //     timeTables.items.forEach((element) => {
    //         if (element?.timeTable?.subject_id === subject_id && !Boolean(element?.timeTable?.parent_id)) {
    //             if (!arr.includes(element?.timeTable?.subject_category_id)) {
    //                 arr.push(element?.timeTable?.subject_category_id)
    //             }
    //         } else if (element?.timeTable?.subject_id === subject_id && !Boolean(element?.timeTable?.parent_id) && !Boolean(element?.timeTable?.lecture_id)) {
    //             arr.push(0)
    //         }
    //     })

    //     if (arr.length === 2 || arr.includes(3)) {
    //         return false;
    //     } else if (!arr.length) {
    //         return <span></span>
    //     }

    //     return <span style={{ color: 'red' }}>&nbsp;&nbsp;({arr.includes(1) ? 'Seminar' : "Ma'ruza"} tanlang)</span>

    // }



    // const checkIsSelected = (id: number) => {

    //     if (!timeTables.items.length) return null;

    //     const findBySubject = timeTables.items.filter(e => e.subject.id === id);

    //     const isHasLecture = findBySubject?.some(e => Number(e.timeTable?.subject_category_id) === 1);


    //     if (isHasLecture) {

    //         const isSelectedLecture = findBySubject.find(e => e.timeTable?.subject_category_id === 1 && Number(e.timeTable?.selected) === 1)
    //         const isSelectedSemenar = findBySubject.find(e => e.timeTable?.subject_category_id !== 1 && Number(e.timeTable?.selected) === 1)

    //         console.log("isecture -> ", isSelectedLecture, isSelectedSemenar)

    //         if (!isSelectedLecture && !isSelectedSemenar) return null
    //         if (isSelectedLecture && isSelectedSemenar) return <Text type="success">( Tanlandi )</Text>

    //         if (!isSelectedLecture) return <Text type="danger">( Ma'ruza tanlang )</Text>

    //         if (!isSelectedSemenar) return <Text type="danger">( Seminar tanlang )</Text>

    //         return null

    //     } else {
    //         const isSelected = findBySubject.find(e => Number(e.timeTable?.selected) === 1);

    //         if (isSelected) return <Text type="success">( Tanlandi )</Text>

    //         return null;
    //     }


    // }

    const isSelectedOption = (type: "V" | "S"): number => {
        if (type === "V") {
            return timeOptions?.items?.filter((el) => el.selected == 1)?.length;
        } else {
            return timeOptions?.items?.filter((el) => el.selected == 1)[0]?.timeTables?.filter((el) => el.subjectType == 6)[0]?.subject_id ?? 0;
        }
    }



    const filterTimeTable = React.useCallback((para_id: number, week_id: number) => {
        if (timeTables.items.length) {

            const filteredTimeTables = timeTables.items.filter(element => element?.week?.id === week_id && element?.para?.id === para_id);
            return filteredTimeTables;
        }
    }, [timeTables]);

    const isSelection = () => subjects.items?.some(e => Boolean(e.selection))


    return (
        <Spin spinning={timeTables.loading} >
            <Card bordered={false}>
                <div className="d-flex justify-content-between">
                    <Title color={theme.header_text} >Dars jadval</Title>
                    <div>
                        {edu_semesters.items.length ? <>
                            Semestr:&nbsp; {edu_semesters.items.map((e, i) => {
                                return <SelectSemesterBtn key={i} theme={theme} style={eduSemester?.semestr_id === e.semestr_id ? { background: "#1890FF", color: "#fff" } : {}} onClick={() => { console.log(eduSemester);
                                 setEduSemester(e) }}>{e?.semestr?.name}</SelectSemesterBtn>
                            })}
                        </> : null}
                    </div>
                </div>
                <Divider className="my-2" ></Divider>
                {/* {!isSelection() ? <Alert message={<span>Siz tanlov fanini tanlamagansiz ! &nbsp;&nbsp;<NavLink to="/select_subject">Tanlash</NavLink></span>} type="info" showIcon /> : null} */}
                {isSelectedOption("V") > 0 ? <div className={styles.subject_containers} >
                    {
                        subjects.items.length ?
                            subjects.items.map((element) => {
                                if (Number(element.subject_type_id) === 6 && element?.subject_id !== isSelectedOption("S")) return null;

                                return <NavLink key={element.id} to={`/time_tables_by_subjects/${eduSemester?.id}/${element.subject_id}`}>{element?.subject?.name} {element?.subject_id == isSelectedOption("S") ? <Text type="warning">(Tanlov fan)</Text> : null}</NavLink>
                            }
                            ) : null
                    }

                </div> : <div className={styles.notice_time_table} >
                    Sizda tanlov fani mavjud emas. Iltimos tanlov fanini tanlang!
                    <hr />
                    <div className="text-end">
                        <NavLink to={`/time_table_variants/${eduSemester?.id}`}><Button type="primary" size="small">Tanlov fanini tanlash &nbsp;<BiArrowFromLeft size={18} /> </Button></NavLink>
                    </div>
                </div>}
                {/* <div className={styles.notice_time_table} >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta minus, aliquam, quo reiciendis dolorum molestiae quaerat delectus nisi voluptatum
                    libero veniam mollitia eligendi architecto placeat asperiores vitae. Veniam, quas fugiat!
                    <hr />
                    <div className="text-end">
                        <NavLink to={`/time_table_variants/${eduSemester?.id}`}><Button type="primary" size="small">Dars jadvalini tanlash &nbsp;<BiArrowFromLeft size={18} /> </Button></NavLink>
                    </div>
                </div> */}
                <Row gutter={[24, 12]} className="mt-2" >
                    {weeks.items.length ? weeks.items.map((weekItem, weekIndex) => {
                        if (!Boolean(weekItem?.status)) return null
                        return (
                            <Col key={weekIndex} xl={weekIndex === 4 ? 4 : 5} lg={weekIndex === 4 ? 4 : 5} md={12} sm={12} xs={24} style={{ border: "1px solid #F1F4F9" }} className="px-0" >
                                {timeTables.items.length ? <div className={styles.time_table_week_name}> {weekItem.name} </div> : null}
                                <div className="px-1">
                                    {
                                        para.items.length ? para.items.map((paraItem) => {

                                            const _options = filterTimeTable(paraItem.id, weekItem.id);

                                            if (!_options?.length) return null;

                                            return (
                                                <span style={{ width: 'calc(100% / 5)' }}>
                                                    <div className={styles.time_table_title}>
                                                        <span className={styles.time_lesson} >
                                                            {paraItem?.name}&nbsp;&nbsp;◷&nbsp;{paraItem?.start_time}-{paraItem?.end_time}
                                                        </span>
                                                    </div>
                                                    {
                                                        <span>
                                                            {
                                                                _options?.map((timeTableItem) => {
                                                                    return (
                                                                        <span
                                                                            key={timeTableItem.id}
                                                                            className={styles.created_time_table_box}
                                                                        >
                                                                            <span className={styles.info_text_subject} ><span>{timeTableItem?.building?.name}&nbsp;&nbsp;{timeTableItem?.room?.name?.replace(/\D/g, '')}-xona&nbsp;&nbsp;</span>{timeTableItem?.subject?.name}<br /><i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name} ({timeTableItem?.timeTable?.language?.name})</i></span><br />
                                                                            <span className={styles.info_text_teacher}>{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0)}.</span>
                                                                            {
                                                                                isHasAuthRoute('student-time-table_delete') && !timeTableItem.timeTable?.parent_id ?
                                                                                    <Popconfirm
                                                                                        placement="topRight"
                                                                                        title={"O'chirilsinmi ?"}
                                                                                        onConfirm={() => _delete.fetch(`/student-time-tables/${timeTableItem.id}`)}
                                                                                        okText="Ha"
                                                                                        cancelText="Yo'q"
                                                                                    >
                                                                                        <button className={styles.remove_time_table} ><BiX size={16} /></button>
                                                                                    </Popconfirm>
                                                                                    : null
                                                                            }
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </span>
                                                    }
                                                </span>
                                            )
                                        }) : null
                                    }
                                </div>
                            </Col>
                        )
                    }) : null}
                </Row>
            </Card>
        </Spin>
    );
}


export default StudentLessonSchedule;



/**
student-time-table_create
student-time-table_delete
student-time-table_index
student-time-table_view
 */