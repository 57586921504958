import { Col, Row } from "antd";
import { FC } from "react"
import { useTranslation } from 'react-i18next';
import { SelectStudentTheme } from 'config/theme/colors';
import { Collapse } from 'antd';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Wrapper } from "./style";
import { Title } from "pages/styles/style";

const { Panel } = Collapse;

const FAQ: FC = (): JSX.Element => {
  const theme = SelectStudentTheme();
  const { t } = useTranslation();

  const text = `
    You should only access the API by official doc with ref. Directly access internal props or state is not \n
    recommended which will make your code strong coupling with current version. Any refactor will break your \n
    code like refactor with Hooks version, delete or rename internal props or state,
    <address></address>just internal node constructor, etc.
  `;

  const arr: Array<string> = [
    'Will you provide Sass/Stylus(etc.) style files in addition to the Less style files currently included?',
    'How do I prevent Select Dropdown DatePicker TimePicker Popover Popconfirm scrolling with the page?',
    'How do I modify the default theme of Ant Design?',
    'Why does modifying props in mutable way not trigger a component update?'
  ]

  return (
    <Wrapper theme={theme}>
      <Row className="p-2 px-0">
        <Col span={24}>
          <Title color={theme.header_text} >{t("FAQ")}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={0} style={{ backgroundColor: theme.element, color: theme.text, marginTop: "1rem" }} expandIconPosition="right">
            {arr?.map((e, i: number) => (
              <Panel header={e} key={i} >
                <p>{text}</p>
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </Wrapper>
  )


}

export default FAQ;