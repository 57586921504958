import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'




const subjectApi = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: `https://api-digital.tsul.uz/api/web/${localStorage.getItem('i18n')}/`,
        prepareHeaders: (headers, { getState }) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('access_token')}`);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getSubjects: builder.query({
            query: (semesterId: number) => `edu-semestr-subjects?expand=subject&filter=${JSON.stringify({ semester_id: semesterId })}`
        })
    })
})


export const { useGetSubjectsQuery } = subjectApi;

export default subjectApi;