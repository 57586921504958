import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import { FC } from "react";
import { DocumentCardUi } from './styled';
import { SelectStudentTheme } from "config/theme/colors";
import doc_icon from 'assets/images/icons/doc_student.svg'
import { Card } from "antd";
import { Title } from "pages/styles/style";

const Documents: FC = (): JSX.Element => {

    const history = useHistory();
    const theme = SelectStudentTheme();

    const _to = (link: string) => history.push(link)


    return (
        <Card bordered={false}>
            <Row>
                <Col xl={18}>
                    <Title color={ theme.header_text }>Hujjatlar</Title>
                </Col>
                <Col xl={6}>

                </Col>
            </Row>
            <hr className="mt-0 mb-3" />
            <div>
                <Row gutter={[12, 12]}>
                    <Col xl={6}>
                        <DocumentCardUi onClick={() => _to('/doc_page')}>
                            <div>
                                <span>Ma'lumotnoma</span>
                            </div>
                            <img src={doc_icon} alt="" width="60px" />
                        </DocumentCardUi>
                    </Col>
                </Row>
                {/* <DocumentItemUi>
            <div onClick={() => _to('/doc_page')}><FaFileAlt color={theme.text} /> <span>O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma</span></div>
            <div><BiDownload /></div>
        </DocumentItemUi>
        <DocumentItemUi>
            <div onClick={() => _to('/doc_page')}><FaFileAlt color={theme.text} /> <span>O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma</span></div>
            <div><BiDownload /></div>
        </DocumentItemUi>
        <DocumentItemUi>
            <div onClick={() => _to('/doc_page')}><FaFileAlt color={theme.text} /> <span>O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma </span></div>
            <div><BiDownload /></div>
        </DocumentItemUi>
        <DocumentItemUi>
            <div onClick={() => _to('/doc_page')}><FaFileAlt color={theme.text} /> <span>O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma O'qish joyidan ma'lumotnoma</span></div>
            <div><BiDownload /></div>
        </DocumentItemUi> */}
            </div>
        </Card>
    )
}



export default Documents;