import { Button } from "antd";
import { SelectStudentTheme } from "config/theme/colors";
import { FC, ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "./styles.scss";

export const PageNotice: FC<{ notice: ReactNode, showIcon?: boolean, isTitle?: boolean }> = ({ notice, showIcon = false, isTitle = true }) => {


    const history = useHistory();


    return (
        <div className="page-notice">
            <span className="notice_title">
                {showIcon && <><Button type="link" onClick={() => history.goBack()} ><FaArrowLeft size={16} /></Button>&nbsp;</>}
                {isTitle && <>Eslatma ! &nbsp;</>} </span>
            {notice}
        </div>
    )
}


export const PageInfoContainer = ({ children }: { children: ReactNode }) => {
    
    return (
        <div className="page-info-container" >
            {children}
        </div>
    )
}