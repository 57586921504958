import { SelectStudentTheme } from 'config/theme/colors';
import { Title } from 'pages/styles/style';
import { FC } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { ExercisseWrapper } from '../common/styled';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Button, Popconfirm, Tag } from 'antd';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useRouteMatch, match } from "react-router";
import { Card, CardBody } from 'reactstrap';
import moment from 'moment';
import isHasAuthRoute from 'utils/isHasRoute';
import { useTranslation } from 'react-i18next';
import { SpinnerLoading } from 'components/Spinner';
import EmptyTable from 'components/EmptyTable';
import useGetOneData from 'hooks/useGetOneData';

export const NOT_TIME = (n: number | string) => <span style={{ color: "#e47d00", backgroundColor: "rgba(255, 140, 0, 0.2)", borderRadius: 3, fontSize: 13 }} className="py-1 px-2 me-2 mb-2" >{n}-topshiriq vaqti boshlanmagan</span>
export const FINISH_TIME = (n: number | string, url?: string) => <span style={{ color: "#CC3C3A", backgroundColor: "#FCE8E6", borderRadius: 3, fontSize: 13 }} className="py-1 px-2 me-2 mb-2" >{n}-topshiriq vaqti yakunlangan</span>


const Exercisse: FC = (): JSX.Element => {

  let isMobile = /iPhone|Android/i.test(navigator.userAgent);
  const history = useHistory();
  const { t } = useTranslation();
  const theme = SelectStudentTheme();
  // const subject_category_id = new URLSearchParams(window.location.search).get("subject_category_id");
  const match: match<any> | null = useRouteMatch("/std_subject/subject_exercisses/:time_table_id");

  // const examControl = useGetAllData({
  //   url: `/exam-controls?filter={"time_table_id":${match?.params?.time_table_id}}&fields=name,start,finish,description&expand=description`,
  //   isCall: "auto"
  // })

  const { data, loading, fetch } = useGetOneData({
    url: `/time-tables/${match?.params?.time_table_id}?fields=now,examControl.name,examControl.start,examControl.finish,examControl.start2,examControl.finish2,examControl.description,subject.name,examControl.examControlStudents.ball,examControl.examControlStudents.ball2,examControl.examControlStudents.main_ball&expand=examControl,examControl.examControlStudents,now,subject`,
    isCall: "auto"
  })

  // useEffect(() => {
  //   if(Number(subject_category_id) !== 1){
  //     fetch(`/time-tables/${match?.params?.time_table_id}?fields=now,examControl.name,examControl.start,examControl.finish,examControl.start2,examControl.finish2,examControl.description,subject.name,examControl.examControlStudents.ball,examControl.examControlStudents.ball2&expand=examControl,examControl.examControlStudents,now,subject`)
  //   }
  // },[])

  const FINISH_TIME1 = (n: number | string, url?: string) =>
    <span style={{ color: "#CC3C3A", backgroundColor: "#FCE8E6", borderRadius: 3, fontSize: 13 }} className="py-1 px-2 me-2 mb-2"
    >{n}-topshiriq vaqti yakunlangan |<b onClick={() => { url && history.push(url) }} className="cursor-pointer" style={{ color: "#5B8DEE" }}>&nbsp;Ko'rish</b> </span>


  const checkExamTime = (question: number) => {

    if (question === 1) {

      if (data?.now?.length && data?.now[0] < data.examControl?.start) {
        return NOT_TIME(question);
      } else if (data?.now?.length && data?.now[0] > data.examControl?.finish) {
        // return FINISH_TIME1(question, `/exam_control/${match?.params?.time_table_id}/${question}`);
        return FINISH_TIME1(question, `/exam_control_view/${match?.params?.time_table_id}/${question}`);
      } else if (isHasAuthRoute("exam-control-student_create")) {
        return <Popconfirm placement="topLeft" title={"Imtihonni boshlamoqchimisiz ?"} okText={t("Yes")} cancelText={t("No")}
          onConfirm={() => { history.push(`/exam_control/${match?.params?.time_table_id}/${question}`) }}
        >
          <Button type='primary' className='me-2 mb-2' >{question} - Topshiriq</Button>
        </Popconfirm>
      } else return null
    }
    if (question === 2) {
      if (data?.now?.length && data?.now[0] < data.examControl?.start2) {
        return NOT_TIME(question);
      } else if (data?.now?.length && data?.now[0] > data.examControl?.finish2) {
        return FINISH_TIME1(question, `/exam_control_view/${match?.params?.time_table_id}/${question}`);
      } else if (isHasAuthRoute("exam-control-student_create")) {
        return <Popconfirm placement="topLeft" title={"Imtihonni boshlamoqchimisiz ?"} okText={t("Yes")} cancelText={t("No")}
          onConfirm={() => { history.push(`/exam_control/${match?.params?.time_table_id}/${question}`) }}
        >
          <Button type='primary' className='me-2 mb-2' >{question} - Topshiriq</Button>
        </Popconfirm>
      } else return null
    }

  }

  return (
    <Card>
      <CardBody>
        <SpinnerLoading loading={loading} color="#1890ff" size={28} >
          <ExercisseWrapper theme={theme} isMobile={isMobile} >
            <div> <span style={{ cursor: "pointer" }} ><FaArrowLeft onClick={() => history.goBack()} /></span><Title className='d-inline-block' color={theme.header_text} >&nbsp;&nbsp;{data?.subject?.name} </Title> / Topshiriqlar</div>
            {/* <div className='ball'>
            <div className="left"><p>O'zlashtirish:</p><span>38</span></div>
            <div className="middle"><p>Max ball:</p><span>50</span></div>
            <div className="right"><p>Baho:</p><span>4</span></div>
          </div> */}
            <div className="exercisse_list">
              <Table className={`table border-0`}>
                {/* <Thead>
                <Tr className="head_tr" >
                  <Th style={{ maxWidth: 180 }} >Subject type</Th>
                  <Th>Topshiriq</Th>
                  <Th>Muddati</Th>
                  <Th style={{ maxWidth: 90 }} >Baho</Th>
                  <Th>Yuklash</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr className="body_tr" sytle={{ height: isMobile ? "auto" : "" }} >
                  <Td>Maruza</Td>
                  <Td>
                    <a href='file.doc' download className='upload' ><FaDownload /><span> &nbsp; 1-mustaqil ish</span></a>
                  </Td>
                  <Td>23.59 | 26.01.2022</Td>
                  <Td>3 /5</Td>
                  <Td style={{ maxWidth: 180 }}>
                    <Button className='button' icon={<FaUpload />} onClick={showModal} >&nbsp; Upload</Button>
                  </Td>
                </Tr>
              </Tbody> */}
                <Thead>
                  <Tr className="head_tr">
                    <Th>№</Th>
                    <Th>Topshiriq turi</Th>
                    <Th>Nomi</Th>
                    <Th>Tasnif</Th>
                    {data.examControl?.start || data.examControl?.finish ? <Th>1-topshiriq uchun muddat</Th> : null}
                    {data.examControl?.start2 || data.examControl?.finish2 ? <Th>2-topshiriq uchun muddat</Th> : null}
                    <Th>Oraliq bahosi</Th>
                    <Th>Imtihonni boshlash</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    data.examControl ?
                      <Tr>
                        <Td>{1}</Td>
                        <Td>{"Oraliq nazorat"}</Td>
                        <Td>{data.examControl?.name}</Td>
                        <Td>{data.examControl?.description}</Td>
                        {data.examControl?.start || data.examControl?.finish ? <Td>{data.examControl?.start ? moment.unix(Number(data.examControl?.start)).format("DD.MM.YYYY | HH:mm") : null} - {data.examControl?.finish ? moment.unix(Number(data.examControl?.finish)).format("DD.MM.YYYY | HH:mm") : null}</Td> : null}
                        {data.examControl?.start2 || data.examControl?.finish2 ? <Td>{data.examControl?.start2 ? moment.unix(Number(data.examControl?.start2)).format("DD.MM.YYYY | HH:mm") : null} - {data.examControl?.finish2 ? moment.unix(Number(data.examControl?.finish2)).format("DD.MM.YYYY | HH:mm") : null}</Td> : null}
                        {/* <Td><Tag color='blue' className='border border-0 rounded-2 py-1 px-2' style={{fontWeight: 500}} >{data.examControl?.examControlStudents?.length && (data.examControl?.examControlStudents[0]?.ball || data.examControl?.examControlStudents[0]?.ball2 || data.examControl?.examControlStudents[0]?.ball === 0 || data.examControl?.examControlStudents[0]?.ball2 === 0) ? data.examControl?.examControlStudents[0]?.main_ball : "_"} / 40</Tag></Td> */}
                        <Td><Tag color='blue' className='border border-0 rounded-2 py-1 px-2' style={{ fontWeight: 500 }} >{data.examControl?.examControlStudents?.length ? Number(data.examControl?.examControlStudents[0]?.ball) + Number(data.examControl?.examControlStudents[0]?.ball2) : "_"} / 40</Tag></Td>
                        <Td>
                          <div className="d-f flex-wrap">
                            {data.examControl?.start || data.examControl?.finish ? checkExamTime(1) : null}
                            {data.examControl?.start2 || data.examControl?.finish2 ? checkExamTime(2) : null}
                          </div>
                        </Td>
                      </Tr> : null
                  }
                </Tbody>
              </Table>
              {!data.examControl ? <EmptyTable loading={loading} data={[]} /> : null}
            </div>
          </ExercisseWrapper>
        </SpinnerLoading>
      </CardBody>
    </Card>
  )
}

export default Exercisse