import React, { FC } from "react";
import { StudentAvatarCardUi } from './styled';
import user_male from 'assets/images/user_male.svg';
import { SelectStudentTheme } from "config/theme/colors";
import { BiEditAlt } from 'react-icons/bi';
import { NavLink, useHistory } from "react-router-dom";
import { useAppSelector } from "store/services";

const StudentAvatarCard: FC<{ isMobile: boolean }> = ({ isMobile }): JSX.Element => {


  const history = useHistory();
  const theme = SelectStudentTheme();
  const user_data: any = useAppSelector(state => state.auth);


  return (
    <StudentAvatarCardUi isMobile={isMobile} theme={theme}>
      {/* <div className="avatar"> */}
        {/* {<div className="img"><img src={user_male} alt="" /></div>} */}
        <div className="image" ><img src={user_male} alt="" /></div>
        {user_data.user.last_name ? <p>{user_data.user.last_name}</p> : <div className="line"></div>}
        {user_data.user.first_name ? <p>{user_data.user.first_name}</p> : <div className="line"></div>}
        {/* <div className="close"><FaTimes size={18} color={theme.text} onClick={()=>setIsSetting(false)} /></div> */}
      {/* </div> */}
    </StudentAvatarCardUi>
  )
}


export default StudentAvatarCard;