import React, { FC, useEffect, useState } from "react";
import StudentHeader from '../dashboard/vertical/header/index';
import StudentHeaderMobile from '../dashboard/vertical/header_mobile';
import { SelectStudentTheme } from "config/theme/colors";
import Sidebar from '../dashboard/vertical/sidebar';
import "./styles.scss";
import { StdContent } from "../dashboard/vertical/common/styled";
import { useAppSelector } from "store/services";
import RightSitebar from "../dashboard/vertical/right_sidebar";
import { useDispatch } from "react-redux";
import { manageSidebarCustom } from "store/ui";
import useWindowSize from "hooks/useWindowSize";
import { Badge, BadgeProps, Button, Calendar, Layout } from "antd";
import { StudentContentWrapper } from "../dashboard/vertical/content/styled";
import UnreadMessagesPage from "pages/notification/UnreadMessagesPage";
import StudentAvatarCard from "../dashboard/vertical/sidebar/avatar";
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import type { Moment } from 'moment';
import { LinkBoxUi, LinkItemUi } from "../dashboard/vertical/sidebar/styled";
import ConfigLink from "../dashboard/vertical/sidebar/config_bar";
import { RoutesTypeSubmenu } from "routes/types";
import { NavLink, useLocation } from "react-router-dom";
import IconComponent from "components/Icon";
import { useTranslation } from "react-i18next";
import { BiLogOut } from "react-icons/bi";
import _logout from "config/_axios/logout";
import { student_routes } from "routes";

const { Header, Sider, Footer, Content } = Layout

const getListData = (value: Moment) => {
    let listData;
    switch (value.date()) {
      default:
    }
    return listData || [];
  };
  
  const getMonthData = (value: Moment) => {
    return null
  };

const Layout1: FC<{allProps: any, Component: any}> = ({allProps, Component}): JSX.Element => {    

    const {t} = useTranslation();
    const { pathname } = useLocation();
    const ui = useAppSelector(state => state.ui);
    const messages = useAppSelector(state => state.data.messages);
    let isMobile = /iPhone|Android/i.test(navigator.userAgent);
    const width = useWindowSize().width;
    const _sidebar = useAppSelector(state => state.ui.sidebar);
    const [isSetting, setIsSetting] = useState<boolean>(false);
    const theme = SelectStudentTheme();
    const dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)

    useEffect(() => {
        if(isMobile) dispatch(manageSidebarCustom({ sidebarType: 'xs' }));
    }, [])
    

    useEffect(() => {
        window.addEventListener("resize", () => {
          setWindowSize(window.innerWidth);
          setWindowHeight(window.innerHeight);
          if(isMobile) dispatch(manageSidebarCustom({ sidebarType: 'xs' }));
        });
      })

    const monthCellRender = (value: Moment) => {
    const num = getMonthData(value);
    return num ? (
        <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
        </div>
    ) : null;
    };

    const dateCellRender = (value: Moment) => {
        const listData: any = getListData(value);
        return (
            <ul className={`${listData.length ? 'hasContent' : ''} events`}>
            {listData.map((item: any) => (
                <li key={item.content}>
                <Badge status={item.type as BadgeProps['status']} text={item.content} />
                </li>
            ))}
            </ul>
        );
    };
    

    return (<>
        {/* <div className="wrapper">
            <div className="d-flex sidebar_content">
                <StdContent theme={theme} sidebar={_sidebar}>
                    <RightSitebar isSetting={isSetting} setIsSetting={setIsSetting} />
                </StdContent>
            </div>
        </div> */}
        <Layout>
            <Header
                style={{
                    height: "65px",
                    marginRight: 10,
                    marginLeft: 10,
                    marginTop: 10,
                    borderRadius: 15,
                    zIndex: 1009,
                    padding: 0,
                    backgroundColor: '#fff',
                    lineHeight: '50px',
                    position: isMobile || width < 576 ? 'fixed' : 'static',
                    width: isMobile || width < 576 ? windowSize - 20 : ''
                }}
            >
                {isMobile || width < 576 ? <StudentHeaderMobile windowSize={windowSize} /> : <StudentHeader windowSize={windowSize} /* isSetting={isSetting} setIsSetting={setIsSetting}*/ />}
            </Header>
            <Layout
                style={{
                    transition: 'all .2s',
                    marginTop: isMobile || width < 576 ? 75 : ''
                  }}
            >
                <Sider
                    width={windowSize < 1080 ? windowSize < 576 ? 200 : 170 : 300}
                    collapsedWidth={0}
                    style={{
                        backgroundColor: "white",
                        height: windowHeight - 95,
                        position: "static",
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: _sidebar !== 'xs' ? 10 : '',
                        marginRight: 0,
                        borderRadius: '15px',
                        overflow: 'hidden',
                    }}
                    trigger={null}
                    collapsible
                    collapsed={['md', 'sm', 'xs'].includes(_sidebar)}
                >
                    <Sidebar />
                </Sider>
                <Content style={{
                    zIndex: 1000,
                    backgroundColor: '#fff',
                    margin: '10px',
                    borderRadius: '15px',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    height: windowHeight - 95
                }}>
                    {/* <StudentContentWrapper isMobile={isMobile} theme={theme} ui={ui} sidebar={_sidebar}>    */}
                        {messages.messages.length ? <UnreadMessagesPage /> : <Component collapse={['md', 'sm', 'xs'].includes(_sidebar)} {...allProps}/>}
                    {/* </StudentContentWrapper> */}
                </Content>
            </Layout>
        </Layout>
    </>)
}


export default React.memo(Layout1)