import { Route } from "react-router-dom"
import Layout from 'components/structure/Layouts/Layout'
import NonLayout from "components/structure/Layouts/NonLayout"


const RenderRoutes = ({ component: Component, structure }: any) => {

    return (
        <Route
            render={(props: any) => {
                if (structure === 'student_layout') {
                    return (
                        <Layout allProps={props} Component={Component}/>
                    )
                } else {
                    return (
                        <NonLayout>
                            <Component {...props} />
                        </NonLayout>
                    )
                }
            }}
        />
    )
}

export default RenderRoutes;