import React, { FC } from "react";
import { SelectStudentTheme } from "config/theme/colors";
import { eduSemestrType } from "pages/student_exams/types";
import { Title } from "pages/styles/style";
import { SelectSemesterBtn } from "pages/subjects/common/styled";
import { useTranslation } from "react-i18next";
import "./style.scss"
import useUrlQueryParams from "hooks/useUrlQueryParams";

type TypeTitleAndSemestrs = {
  semesters: eduSemestrType[],
  title: string | HTMLElement,
}

const TitleAndSemestrs: FC<TypeTitleAndSemestrs> = ({ title, semesters}): JSX.Element => {
  const theme = SelectStudentTheme();
  const {t} = useTranslation();
  const {value, writeToUrl} = useUrlQueryParams({});

  return (
    <div className="std_subject_header">
      <Title color={theme.header_text} >{ typeof title === "string" ? t(title) : title}</Title>
      <div>
        {t("Semestrs")}: {semesters?.length ? semesters.map((e, i) => (
          <SelectSemesterBtn
            style={{ marginTop: "0.25rem" }}
            onClick={() => {writeToUrl({name: "semestr_id", value: e.semestr_id}); writeToUrl({name: "edu_semestr_id", value: e.id})}}
            key={i}
            active={e.semestr_id === value.filter?.semestr_id}
            theme={theme}
          >
            {e?.semestr?.name}
          </SelectSemesterBtn>
        )) : null}
      </div>
    </div>
  )
}

export default TitleAndSemestrs