import { createSlice } from "@reduxjs/toolkit";
import { ArrayChecker } from "utils/ArrayChecker";
import getAsyncInitialData from "services/async_requests";

export type DataInitialState = {
    countries: {
        data: Array<any>,
        _meta: any,
        status: string,
        loading: boolean
    },
    regions: {
        data: Array<any>,
        _meta: any,
        status: string,
        loading: boolean
    },
    languages: {
        data: Array<any>,
        _meta: any,
        status: string,
        loading: boolean
    },
    messages: {
        messages: any[],
        count: number,
        ids: number[]
    }
}

let initialState: DataInitialState = {
    countries: {
        data: [],
        _meta: null,
        status: 'error',
        loading: false
    },
    regions: {
        data: [],
        _meta: null,
        status: 'error',
        loading: false
    },
    languages: {
        data: [],
        _meta: null,
        status: 'error',
        loading: false
    },
    messages: {
        messages: [],
        count: 0,
        ids: []
    }
}

const dataSlice = createSlice({
    name: "data",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAsyncInitialData.pending, (state: any, action: any) => {
                switch (action.payload?.key) {
                    case 'countries':
                        state.countries.loading = true;
                        break
                    case 'regions':
                        state.regions.loading = true;
                        break
                    case 'languages':
                        state.languages.loading = true;
                }
            })
            .addCase(getAsyncInitialData.fulfilled, (state: any, action: any) => {
                switch (action.payload?.key) {
                    case 'countries':
                        state.countries.loading = false;
                        state.countries.data = action.payload.data;
                        state.countries._meta = action.payload._meta;
                        state.countries.status = 'success';
                        break
                    case 'regions':
                        state.regions.loading = false;
                        state.regions.data = action.payload.data;
                        state.regions._meta = action.payload._meta;
                        state.regions.status = 'success';
                        break
                    case 'languages':
                        state.languages.loading = false;
                        state.languages.data = action.payload.data;
                        state.languages._meta = action.payload._meta;
                        state.languages.status = 'success';
                        break
                    case 'messages':
                        state.messages.messages = action.payload.data;
                        state.messages.count = action.payload.data.length;
                        if (ArrayChecker(action.payload.data)._) {
                            state.messages.ids = action.payload.data.map((e: any) => e.id);
                        }
                        break
                }
            })
            .addCase(getAsyncInitialData.rejected, (state: any, action: any) => {
                switch (action.payload?.key) {
                    case 'countries':
                        state.countries.status = 'error';
                        break
                    case 'regions':
                        state.regions.status = 'error';
                        break
                    case 'languages':
                        state.languages.status = 'error';
                        break
                }
            })
    }
})

export default dataSlice;