import { FC, useState, useRef } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import CustomModal from "./modal";
import "./styles.scss";
// import moment from "moment";

const Note: FC = (): JSX.Element => {


    const [events, setEvents] = useState<Array<any>>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>('');
    // const [coordinate, setCoordinate] = useState<{ clientY: number, clientX: number }>({
    //     clientY: 0,
    //     clientX: 0
    // });

    let ref: any = useRef();
    const handleDateClick = (arg: any) => {
        setSelectedDate(arg.dateStr);
        setIsOpen(!isOpen)
        // console.log('arg -> ', arg)
    }
    const saveEvent = (text: string) => {
        setEvents((prevState) => ([...prevState, { title: text, allDay: true, start: selectedDate, end: undefined }]));
        setSelectedDate('');
        setIsOpen(false);
    }

    function renderEventContent(eventInfo: any) {
        eventInfo.backgroundColor = "grey";
        eventInfo.border = "none";
        eventInfo.isDraggable = false;
        eventInfo.isDragging = false;
        eventInfo.isEditable = false
        return (
            <div className='date_box'>
                <i>{eventInfo.event.title}</i>
            </div>
        )
    }

    // const injectCellContent = (args: any) => {
    //     return (
    //         <div>
    //             <button>{args.dayNumberText}</button>
    //         </div>
    //     )
    // }

    // const _eventDrop = (info: any) => {
    //     const { start, end } = info.oldEvent._instance.range;
    //     const oldDate = moment(start).format("YYYY-MM-DD");
    //     const newDate = moment(end).format("YYYY-MM-DD");
    //     console.log("event drop -> ", oldDate, newDate);
    //     const newEvents = events;
    //     for (let index = 0; index < newEvents.length; index++) {
    //         let element = newEvents[index];
    //         if (element.start === oldDate) {
    //             element.start = newDate
    //         }
    //     }
    //     setEvents(newEvents);
    // }

    // const _eventResize = (info: any) => {
    //     const { start, end } = info.oldEvent._instance.range;
    //     const oldDate = moment(start).format("YYYY-MM-DD");
    //     const newDate = moment(end).format("YYYY-MM-DD");
    //     console.log("event resize -> ", oldDate, newDate);
    //     const newEvents = events;
    //     for (let index = 0; index < newEvents.length; index++) {
    //         let element = newEvents[index];
    //         if (element.date === oldDate) {
    //             element.end = newDate
    //         }
    //     }
    //     setEvents(newEvents);
    // }


    // const _eventDropStop = (event: any) => {
    //     if (coordinate.clientX < event.jsEvent.clientX) {
    //         alert("ha")
    //     } else {

    //         return
    //     }
    // }


    return (
        <div className="note">
            <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} saveEvent={saveEvent} selectedDate={selectedDate} />

            <FullCalendar
                ref={ref}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                headerToolbar={{
                    left: "prev,next,today",
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                initialView="dayGridMonth"
                contentHeight={600}
                // editable={true}
                // selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                eventContent={renderEventContent}
                dateClick={handleDateClick}
                events={events}
            // droppable={true}
            // eventDrop={_eventDrop}
            // eventResize={_eventResize}
            // eventDragStart={(e: any) => { setCoordinate({ clientY: e.jsEvent.clientY, clientX: e.jsEvent.clientX }) }}
            // eventDragStop={e => console.log("event drop stop -> ", e)}
            // eventsSet={e => console.log("event set -> ", e)}
            // eventMouseEnter={e => console.log("event mouse enter -> ", e)}
            />
        </div>
    )
}


export default Note;