import { Affix, Button, Col, Collapse, message, Modal, Popconfirm, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import { CaretRightOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from "store/services";
import React from "react";
import moment from "moment";
import { FaExclamationTriangle } from "react-icons/fa";
import { checkIsHasStudentAnswer, GetExamQuestion, getStudentAnswerForView } from "./service.requests";
import instance from "config/_axios";
import { useHistory, useRouteMatch, match, Prompt, useLocation } from "react-router-dom";
import "./styles.scss";
import { editor_buttonList } from "./utils";
import { IExamQuestion } from "models/exam";
import ResultView from "./components/ResultView";
import _logout from "config/_axios/logout";
import { TypeInitialStateAuth } from "store/auth";
import { EXAM_ACTIONS } from "store/exam";

const { Panel } = Collapse;

const ExamView = () => {

    const { t } = useTranslation();
    const match: match<any> | null = useRouteMatch("/std_exam/:exam_id/:password");
    const dispatch: any = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const auth: TypeInitialStateAuth = useAppSelector<TypeInitialStateAuth>((state: any) => state.auth);


    const [resultVisible, setResultVisible] = React.useState<boolean>(false);
    const [resultLoading, setResultLoading] = React.useState<boolean>(false);
    const [studentAnswer, setStudentAnswer] = React.useState<IExamQuestion>();


    // exam_questions -> exam question may be multiple. This variable stored tha exam questions.
    // time -> this time includes start, finish, duration and current time of student not exam.
    // exam_question -> this exam question by default includes 0 index value of exam questions. This can be changed to value in another index of exam questions if exam questions multiple.
    // question_count -> this variable means that exam questions multiple or not. If exam questions are multiple this variable includes ids of exam questions else will be empty.
    const { isLoading, exam_questions, time, exam_question, question_count } = useAppSelector(state => state.exam.pass_exam);
    

    function generate_key(sub_question_id: number) {

        const user_id = auth.user.user_id;
        const exam_id = match?.params.exam_id;
        // data stored to localStorage by key. Key consists of user_id, exam_id, exam_question_id, sub_question_id and the composition will be in the same order like following.
        const key = `${user_id}_${exam_id}_${exam_question?.id}_${sub_question_id}`;

        const _isKey = !user_id || !exam_id || !exam_question || !sub_question_id

        return _isKey ? null : key
    }
 

    // write answer to localstorage by generated key and send answers to server getting from localstorage
    const handleChange = React.useCallback((sub_question_id: number, value: string) => {

        const key = generate_key(sub_question_id);

        if (key) {
            // setTimeout(() => onSubmit(sub_question_id), 3000)
            localStorage.setItem(key, value);
        } else {
            message.warning("Ma'lumot shakllantirishda xatolik!")
        }
    }, [exam_question])


    React.useEffect(() => {
        if (!exam_question) {
            dispatch(GetExamQuestion({ password: match?.params.password, history, exam_id: match?.params.exam_id }))
        }
    }, [])

    React.useEffect(() => {
        if (exam_question) {
            checkIsHasStudentAnswer(exam_question.id, generate_key, handleChange)
        }
    }, [exam_question])


    React.useEffect(() => {
        if (location.pathname.includes('exam/pass')) {
            // window.onpopstate = function (event) {

            //     if (window.confirm("Sahifadan chiqmoqchimisiz?")) {
            //         history.goBack();
            //     } else {
            //         history.go(1);
            //     }

            // };
        }
    }, [])


    function clickViewAnswer(isRequest: boolean) {
        if (exam_question) {
            setResultVisible(prevState => !prevState);
            if (isRequest) {
                setResultLoading(true);
                getStudentAnswerForView(exam_question.id).then((response) => {
                    setStudentAnswer(response?.data)
                }).catch(() => {

                }).finally(() => {
                    setResultLoading(false);
                })
            }
        }
    }


    const getAnswerLocalStorage = (sub_question_id: number) => {

        const key = generate_key(sub_question_id);

        if (!key) return;

        const value = localStorage.getItem(key);

        if (!value) return

        return value

    }


    const onSubmit = async (_sub_question_id?: number) => {

        try {

            const formdata = new FormData();

            let answers: Array<{ sub_question_id: number, answer: string }> = []

            if (_sub_question_id) {
                const key = generate_key(_sub_question_id)
                if (key) {
                    const value = localStorage.getItem(key);
                    if (value) {
                        answers.push({
                            sub_question_id: _sub_question_id,
                            answer: value
                        })
                    }
                }
            } else {

                exam_question?.question.subQuestion.forEach((element: any) => {

                    const key = generate_key(element.id)

                    if (key) {
                        const value = localStorage.getItem(key);

                        if (value) {
                            answers.push({
                                sub_question_id: element.id,
                                answer: value
                            })
                        }
                    }

                })

            }

            if (answers.length) {
                formdata.append("subQuestionAnswers", JSON.stringify(answers));
                const response = await instance({ url: `exam-student-answers/${exam_question?.id}`, method: 'PUT', data: formdata });

                if (response.data.status === 1) {
                    if (!_sub_question_id) {
                        message.success("Imtihon javoblari saqlandi!")
                    }
                }
            } else {
                message.warning("Javob yozilmagan!")
            }


        } catch (error) {

        }
    }



    return (
        <div translate="no">
            {/* <Prompt
                when={!location.pathname.includes('exam/pass')}
                message="Are you sure you want to leave?" /> */}
            <Spin spinning={isLoading}>
                {
                    exam_question ?

                        <Row gutter={[12, 12]}>
                            {
                                resultVisible ?
                                    <Col xl={20}>
                                        <ResultView data={studentAnswer} isLoading={resultLoading} />
                                    </Col> :
                                    <Col xl={20}>
                                        <Card>
                                            <CardBody>
                                                <div className="d-f justify-content-between">
                                                    <strong>{exam_question.question_type}</strong>
                                                    <div>
                                                        {
                                                            exam_questions && exam_questions.length ?
                                                                exam_questions.map((element: any, index: any) => <Button key={index} type={exam_question.id === element.id ? "primary" : "default"} onClick={() => {
                                                                    dispatch(EXAM_ACTIONS.selectExamQuestion({exam_question_id: element.id}))
                                                                }
                                                            } className="ms-2" size="small">{index + 1}</Button>) : null
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div dangerouslySetInnerHTML={{ __html: exam_question?.question?.question?.replaceAll("&nbsp;", " ").trim() ?? '' }} ></div>
                                            </CardBody>
                                        </Card>
                                        <Collapse
                                            bordered={false}
                                            defaultActiveKey={['1']}
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            className="site-collapse-custom-collapse px-0"
                                        >
                                            {
                                                exam_question?.question?.subQuestion.length ?
                                                    exam_question.question.subQuestion.map((subQuestion: any, index: number) => {
                                                        return (
                                                            <Panel
                                                                key={index + 1}
                                                                header={
                                                                    <div>
                                                                        <div className="exam_sub_question" dangerouslySetInnerHTML={{ __html: subQuestion.question?.replaceAll("&nbsp;", " ").trim() ?? '' }} ></div>
                                                                    </div>
                                                                }
                                                                className="site-collapse-custom-panel"
                                                            >
                                                                <SunEditor
                                                                    onDrop={(e) => { e.preventDefault() }}
                                                                    height={300 + "px"}
                                                                    placeholder={t("Enter an answer")}
                                                                    onChange={(value) => handleChange(subQuestion.id, value)}
                                                                    setContents={getAnswerLocalStorage(subQuestion.id)}
                                                                
                                                                    lang="ru"
                                                                    setOptions={{
                                                                        fontSize: [12, 14, 16, 18, 20, 24, 32],
                                                                        fontSizeUnit: "px",
                                                                        mathFontSize: [{ text: '18', value: '18', default: true }],
                                                                        // codeMirror: 'CodeMirror',
                                                                        katex: 'window.katex',
                                                                        buttonList: editor_buttonList
                                                                    }} />
                                                            </Panel>
                                                        )
                                                    }) : <span>Savollar topilmadi !</span>
                                            }
                                        </Collapse>
                                    </Col>
                            }
                            <Col xl={4}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <div className="warning_exam_pass">
                                                <strong><FaExclamationTriangle size={14} className="mb-1" />&nbsp;&nbsp;E'tibor qarating!</strong><br />
                                                <span>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                    Sapiente nostrum suscipit voluptate asperiores magni nihil
                                                    quas distinctio beatae architecto, sint vero. Iusto,
                                                    quibusdam sapiente voluptas distinctio assumenda omnis tempore porro!
                                                </span>
                                            </div>
                                            <div className="exam_pass_info">
                                                <p>Imtihon ma'lumotlari</p>
                                                <div className="date_box_exam_pass">
                                                    <span>savol turi: </span><b>{exam_question.question_type}</b><br />
                                                    <span>savollar soni: </span><b>{Object.keys(JSON.parse(exam_question.exam?.question_count_by_type_with_ball ?? "{}")).length}</b><br />
                                                    <span>maksimal ball: </span><b>{exam_question.max_ball}</b><br />
                                                    <span>boshlash: </span><b>{moment(time?.start).format("DD-MM-YYYY HH:mm:ss")}</b><br />
                                                    <span>tugatish: </span><b>{moment(time?.finish).format("DD-MM-YYYY HH:mm:ss")}</b><br />
                                                    <span>davomiyligi: </span><b>{moment(Number(time?.duration) * 1000).format("HH:mm:ss")}</b>
                                                </div>
                                            </div>
                                            <Button type="primary" className="w-100  mt-3" onClick={() => onSubmit()}>Javobni saqlash</Button>
                                            <Button
                                                type="primary"
                                                className="w-100  mt-3"
                                                onClick={() => clickViewAnswer(!resultVisible)}
                                            >
                                                {
                                                    !resultVisible ?
                                                        "Natijani ko'rish" : "Imtihonni davom ettirish"
                                                }
                                            </Button>
                                            <Popconfirm
                                                placement="top"
                                                title={<span className="w-100 d-block" >Imtihonni yakunlash</span>}
                                                onConfirm={() => _logout()}
                                                okText="YAKUNLASH"
                                                cancelText="DAVOM ETISH"
                                                className="w-100"
                                                style={{ width: "100%" }}
                                            >
                                                <Button type="primary" className="w-100  mt-5" danger>Imtihonni yakunlash</Button>
                                            </Popconfirm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> :
                        null
                }
            </Spin>
        </div >
    )
}




export default ExamView;