import { FC, useState } from "react"
import { Avatar, Card, Col, Image, Rate, Row, Tabs } from "antd";
import { Chart, Coordinate, Interval, Tooltip as ToolTipChart } from 'bizcharts';
import { useTranslation } from "react-i18next";
import { WrappaerTeacherStatistics } from "./crud/style";
import { useHistory, useLocation } from "react-router-dom";
import { SelectStudentTheme } from "config/theme/colors";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import useWindowSize from "hooks/useWindowSize";
import { Wrapper } from "pages/styles/style";
import PageContentTitleStudent from 'components/StudentTitle';
import { Card as CardWrapper, CardBody } from "reactstrap"

const { TabPane } = Tabs;
const { Meta } = Card;

const TeacherStatistic: FC = (): JSX.Element => {

  const theme = SelectStudentTheme()
  const { t } = useTranslation()
  const history = useHistory();
  const location: any = useLocation();
  const teacher = location?.state?.teacher;
  if (!teacher) {
    //  history.goBack();
  }
  const { width } = useWindowSize()
  const [evaluation, setEvaluation] = useState<boolean>(true);
  const [star, setStar] = useState<number>(3);
  const [classname, setClassname] = useState<string>("");
  const data = [
    { year: '2011 y', sales: 38 },
    { year: '2013 y', sales: 52 },
    { year: '2016 y', sales: 61 },
    { year: '2017 y', sales: 45 },
    { year: '2018 y', sales: 48 },
    { year: '2019 y', sales: 38 },
    { year: '2020 y', sales: 35 },
    { year: '2021 y', sales: 58 },
  ];

  return (
    <CardWrapper>
      <CardBody>
        <WrappaerTeacherStatistics theme={theme} >
          <div className="container-fluid" style={{ height: "100%" }}>
            <Row>
              <Col span={24} className="mb-2" >
                <PageContentTitleStudent title="O'qituvchi kabiniti" />
              </Col>
            </Row>
            <Row gutter={[24, 24]} className="p-0">
              {<Col className="p-1" xs={24} sm={24} md={8} lg={6}>
                <Row gutter={[24, 24]} className="m-2 p-3 pb-4 card rounded" >
                  <Col className="d-flex justify-content-center flex-wrap" xs={24} sm={24} md={24} lg={24}>
                    <Row gutter={24}>
                      <Col span={24} className="d-flex justify-content-center align-items-center w-100 " >
                        <div className="avatar">
                          <Avatar shape="square" size={100} src={<Image src={teacher?.profile_img ? teacher?.profile_img : "https://tsul.uz/teachers/291158.jpg"} style={{ width: 100 }} />} />
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-4 w-100 text-cente" gutter={[36, 12]}>
                      <Col span={24}>{teacher?.first_name}&nbsp;{teacher?.last_name}&nbsp;{teacher?.middle_name}</Col>
                      <Col span={12} className="text-lowercase  text-end" ><strong>Telefon raqami:</strong></Col>
                      <Col span={12} className="text-lowercase text-start" > +998(99)1334555</Col>
                      <Col span={12} className="text-lowercase text-end" ><strong>Pochta manzili:</strong></Col>
                      <Col span={12} className="text-lowercasetext-start " > tsul@tsul.uz</Col>
                      <Col span={12} className="text-lowercase text-end" ><strong>ORCID</strong></Col>
                      <Col span={12} className="text-lowercasetext-start " > 123456789</Col>
                      <Col span={12} className="text-lowercase text-end" ><strong>RESEARCHGARE</strong></Col>
                      <Col span={12} className="text-lowercasetext-start " > 123456789</Col>
                      <Col span={12} className="text-lowercase text-end" ><strong>Google scholar </strong></Col>
                      <Col span={12} className="text-lowercasetext-start " > 123456789</Col>
                      <Col span={12} className="text-lowercase text-end" ><strong>Facebook</strong></Col>
                      <Col span={12} className="text-lowercasetext-start " >facebook.com//dfghjkl</Col>
                      <Col span={12} className="text-lowercase text-end" ><strong>Telegram</strong></Col>
                      <Col span={12} className="text-lowercasetext-start " > @tsul</Col>
                      <Col span={12} className="text-lowercase text-end" ><strong>Linkedin</strong></Col>
                      <Col span={12} className="text-lowercasetext-start " > @tsul</Col>
                      <Col span={24} className="text-lowercase w-100 text-center" >
                        <div className="star" ><Rate classNametext-start={classname} allowHalf defaultValue={star} value={3.5} disabled={evaluation} onChange={(e) => { setStar(e); setEvaluation(true); setClassname("flipInX") }} />
                          {/* <Tooltip placement="top" title={t("Baholash")}><RiUserStarLine className="editStar" onClick={() => { setEvaluation(false); setClassname("") }} /></Tooltip> */}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>}
              <Col className="p-2 tabs" xs={24} sm={24} md={16} lg={18} >
                <Card style={{ height: "100%", position: "relative" }} className="shadow-sm rounded">
                  <Tabs tabPosition="top">
                    <TabPane tab="Ilmiy malumotlari" key="1">
                      <Row className="pe-2 ps-2" gutter={24}>
                        <Col xs={24} className='mb-3'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>{t("Kasbi")}</h6>} description={
                              <>
                                <p>Xalqaro huquq fani o'qituvchisi</p>
                              </>
                            } />
                          </Card>
                        </Col>
                        <Col xs={24} className='mb-3'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>Ilmiy darajasi</h6>} description={
                              <>
                                <p>Professor, Huquq fanlari doktori</p>
                              </>
                            } />
                          </Card>
                        </Col>
                        {/* <Col xs={24} className='mb-4'>
                      <Card className="rounded cards" >
                        <Meta title={t("Email")} description={"john_doe@mail.ru"} />
                      </Card>
                    </Col> */}
                        <Col span={24} className='mb-3'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>{t("O'qituvchi haqida ma'lumot")}</h6>} description={
                              <p>

                              </p>
                            } />
                          </Card>
                        </Col>
                        <Col span={24} className='mb-4'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>{t("Maqolalari")}</h6>} description={
                              <ul>
                                <li><a style={{ color: theme.blue }} href="" > Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, sunt.</a></li>
                                <li><a style={{ color: theme.blue }} href="" > Lorem ipsum dolor sit amet consectetur.</a></li>
                                <li><a style={{ color: theme.blue }} href="" > Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, sunt. Lorem, ipsum dolor.</a></li>
                              </ul>
                            } />
                          </Card>
                        </Col>
                        <Col span={24} className='mb-4'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>{t("Ilmiy ishlari")}</h6>} description={
                              <ul>
                                <li> Lorem ipsum dolor sit amet consectetur.</li>
                                <li> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Temporibus provident tempora quod.</li>
                                <li> Lorem ipsum dolor sit.</li>
                              </ul>
                            } />
                          </Card>
                        </Col>
                          {/* <Col span={24} className="mb-3" >
                          <Card className="rounded cards">
                            <Meta title={<h6>{t("Teacher statistics")}</h6>} description={
                              <Chart height={width === 640 ? 400 : 240} autoFit data={data} interactions={['active-region']} padding={[30, 30, 30, 50]} >
                                <Interval position="year*sales" />
                                <ToolTipChart shared />
                                {width < 640 && (<Coordinate transpose />)}
                              </Chart>
                            } />
                          </Card>
                        </Col> */}
                        <Col span={24} className="p-" >
                          <Wrapper theme={theme} >
                            <Table className="table m-0">
                              <Thead>
                                <Tr>
                                  <Th className="w-75" >Name</Th>
                                  <Th>Ball</Th>
                                  <Th>Yil</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {
                                  data?.map((element: any, i: number) => (
                                    <Tr key={i}>
                                      <Td>Teacher Training: The Ways of Implementing Alternative Assessment Effectively in Schools</Td>
                                      <Td>{element?.sales}.0</Td>
                                      <Td>04.08.{element?.year}</Td>
                                    </Tr>
                                  ))
                                }
                              </Tbody>
                            </Table>
                          </Wrapper>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Ish faoliyati" key="2">

                    </TabPane>
                    <TabPane tab="Malaka oshirganlik haqida" key="2">

                    </TabPane>
                    <TabPane tab="Yutuqlari" key="2">

                    </TabPane>
                    <TabPane tab="Qo'shimcha ma'lumotlar" key="2">
                      <Row className="pe-2 ps-2" gutter={24}>
                        <Col span={24} className='mb-4'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>{t("Maqolalari")}</h6>} description={
                              <ul>
                                <li><a style={{ color: theme.blue }} href="" > Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, sunt.</a></li>
                                <li><a style={{ color: theme.blue }} href="" > Lorem ipsum dolor sit amet consectetur.</a></li>
                                <li><a style={{ color: theme.blue }} href="" > Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, sunt. Lorem, ipsum dolor.</a></li>
                              </ul>
                            } />
                          </Card>
                        </Col>
                        <Col span={24} className='mb-4'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>{t("Ilmiy ishlari")}</h6>} description={
                              <ul>
                                <li> Lorem ipsum dolor sit amet consectetur.</li>
                                <li> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Temporibus provident tempora quod.</li>
                                <li> Lorem ipsum dolor sit.</li>
                              </ul>
                            } />
                          </Card>
                        </Col>
                        <Col span={24} className='mb-4'>
                          <Card className="rounded cards" >
                            <Meta title={<h6>{t("Yutuqlari")}</h6>} description={
                              <div>
                                <img className="w-50 rounded-3 border border-5" src="https://rohmatchemistry.staff.ipb.ac.id/files/2017/10/Sertfikat-juara-lomba-web-ipb-2017.jpg" alt="" />
                                <img className="w-50 rounded-3 border border-5" src="https://www.iainpare.ac.id/wp-content/uploads/2020/05/20200516_013629_0018.pdf.png" alt="" />
                                <img className="w-50 rounded-3 border border-5" src="https://vaginoplastica.ru/wp-content/uploads/2017/01/diplom-opreh.jpg" alt="" />
                                <img className="w-50 rounded-3 border border-5" src="https://www.savol-javob.com/wp-content/uploads/2021/06/%D0%91%D0%B5%D0%B7-%D0%BD%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.jpg" alt="" />
                              </div>
                            } />
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </div>
        </WrappaerTeacherStatistics>
      </CardBody>
    </CardWrapper>
  )
}

export default TeacherStatistic;