import { FC, useEffect, useMemo } from "react"
import { subjectType } from "pages/subjects/common/type"
import useGetAllData, { TypeReturnUseGetAllData } from "./useGetAllData"

const useGetSujectByTimeTable = (semestr_id: number | undefined, refetch: any[]) => {
  const { items: timeTables, loading, fetch } = useGetAllData({
    url: `/student-time-tables?expand=subject,teacher,subjectCategory,timeTable,studentAttends&semester_id=${semestr_id}`,
    perPage:0,
  }) as TypeReturnUseGetAllData<subjectType>

  useEffect(()=> {
    if(semestr_id){
      fetch();
    }
  },[semestr_id, ...refetch])

  const subjects = useMemo(() => {
    let obj: { [key: string]: subjectType[] } = {}

    if (timeTables.length) {
      const newData: subjectType[] = timeTables?.filter((e: any) => Number(e?.timeTable?.semester_id) === semestr_id);

      newData?.map((item: subjectType) => {
        if (obj[`${item?.subject?.id}`]) {
          let arr = [...obj[`${item?.subject?.id}`]];
          if (!obj[`${item?.subject?.id}`]?.find((e: subjectType) => e?.subjectCategory?.id === item?.subjectCategory?.id && e?.timeTable?.parent_id === null)) arr.push(item)
          obj = { ...obj, [`${item?.subject?.id}`]: arr }
        } else {
          obj = { ...obj, [`${item?.subject?.id}`]: [item] }
        }
      })
    }

    return obj
  }, [timeTables])

  return {subjects, timeTables, loading}
}

export default useGetSujectByTimeTable;