import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import instance from "config/_axios";
import { IExam, IExamQuestion, IExamSurvey, IGetExamQuestion } from "models/exam";
import moment from "moment";
import { RouteComponentProps } from "react-router-dom";


export const GetExamInfo = createAsyncThunk(
    "exam/info",
    async ({ exam_id }: { exam_id: number }) => {

        let exam_survey_questions: null | (IExamSurvey & { ball: number })[] = null;

        const exam = await instance({ url: `/exams/${exam_id}?expand=surveyStatus,surveyAnswer,hasAccess,studentSubjectRestrict,examType,subject,type,examStudents`, method: "GET" });

        const exam_info = await exam.data.data as IExam;

        if (!Boolean(exam_info.surveyStatus)) {

            const exam_surveys = await instance({ url: `/survey-questions?sort=id`, method: "GET" });

            exam_survey_questions = exam_surveys.data.data.items;

            if (exam_survey_questions?.length) {

                const findBall = (survey_question_id: number) => {

                    if (!exam_info.surveyAnswer.length) return 0;

                    const findElement = exam_info.surveyAnswer.find((e: any) => e.survey_question_id === survey_question_id);

                    if (!findElement) return 0;

                    return findElement.ball
                }

                exam_survey_questions = exam_survey_questions.map((e) => {

                    return { ...e, ball: findBall(e.id) }

                })
            }

        }

        return { exam_info, exam_survey_questions }

    }
);


export const GetExamQuestion = createAsyncThunk(
    'exam/question',
    async (params: { password: string, history: RouteComponentProps['history'], exam_id: number }, { rejectWithValue }) => {

        try {
            const formdata = new FormData();
            formdata.append('exam_id', String(params.exam_id));
            formdata.append('password', params.password);

            const response = await instance({ url: `/exam-student-answers/get-question?expand=type,exam.examType,exam.studentSubjectRestrict,question`, method: 'POST', data: formdata });

            const exam_question_data = await response.data.data as IGetExamQuestion;

            if (response.data.status === 1) {
                const checkTime = moment(exam_question_data.times.now).isBefore(exam_question_data.times.finish)
                if (checkTime) {
                    params.history.push(`/std_exam/${params.exam_id}/${params.password}`);
                } else {
                    message.warning("Siz uchun imtihon vaqti tugagan!")
                }
            } else {
                // params.history.push(`/`);
            }

            return {
                exam_questions: exam_question_data.questions,
                time: exam_question_data.times,
            }
        } catch (error) {
            // if(!window.location.pathname.includes('exam/info')){
            //     params.history.goBack();
            // }
            return rejectWithValue("error")
        }
    }
)


export const checkIsHasStudentAnswer = async (exam_question_id: number, generate_key: (sub_question_id: number) => string | null, handleChange: (sub_question_id: number, value: string) => void) => {

    try {

        const response = await instance({ url: `/exam-student-answers/${exam_question_id}?expand=examStudentAnswerSubQuestion`, method: 'GET' });

        const data = await response.data.data as IExamQuestion;

        if (data && data.examStudentAnswerSubQuestion?.length) {
            let answersNotInLocalStorage = []
            data.examStudentAnswerSubQuestion.forEach((element: any) => {

                const key = generate_key(element.sub_question_id);

                if (key && element.answer) {
                    const value = localStorage.getItem(key);

                    if (!value) {
                        answersNotInLocalStorage.push(1)
                        handleChange(element.sub_question_id, element.answer);
                    }
                }

            })

            if (answersNotInLocalStorage.length) {
                window.location.reload();
            }

        }

    } catch (error) {
    }
}


export const getStudentAnswerForView = async (exam_question_id: number) => {

    try {

        const response = await instance({ url: `/exam-student-answers/${exam_question_id}?expand=question,examStudentAnswerSubQuestion.subQuestion&fields=*,question.question,question.question_file`, method: 'GET' });

        const data = await response.data.data as IExamQuestion;

        return Promise.resolve({
            data: data
        })


    } catch (error) {
    }
}

export function finishExam() {



}


export class AxiosClient {}