
import akademik_halollik_kodeksi from 'assets/files/akademik_halollik_kodeksi.pdf';
import amaliyot_otash_tartibi from 'assets/files/amaliyot_otash_tartibi.pdf';
import bmi_tartibi from 'assets/files/bmi_tartibi.pdf';
import kredit_tizim_nizomi from 'assets/files/kredit_tizim_nizomi.pdf';
import magistratura_haqida_nizom from 'assets/files/magistratura_haqida_nizom.pdf';
import odob_axloq_kodeksi from 'assets/files/odob_axloq_kodeksi.pdf';
import talabalarning_bilimini_baholash_tartibi from 'assets/files/talabalarning_bilimini_baholash_tartibi.pdf';
import yakuniy_davlat_attestatsiyasi from 'assets/files/yakuniy_davlat_attestatsiyasi.pdf';

export const statuteDocuments: Array<{ id: number, name: string, file: any }> = [
    {
        id: 1,
        name: "Akademik halollik va tadqiqot etikasi kodeksi",
        file: akademik_halollik_kodeksi,
    },
    {
        id: 2,
        name: "Amaliyot o'tash tartibi",
        file: amaliyot_otash_tartibi,
    },
    {
        id: 3,
        name: "Bitiruv malakaviy ishni tayyorlash va baholash tartibi to'g'risidagi nizom",
        file: bmi_tartibi,
    },
    {
        id: 4,
        name: "Ta'limning kredit-modul tizimida o'quv jarayonini tashkil etish tartibi to'g'risida nizom",
        file: kredit_tizim_nizomi,
    },
    {
        id: 5,
        name: "Magistratura to'g'risidagi nizom ( Magistrlar uchun )",
        file: magistratura_haqida_nizom,
    },
    {
        id: 6,
        name: "Odob-axloq kodeksi",
        file: odob_axloq_kodeksi,
    },
    {
        id: 7,
        name: "O'qitishning modul tizimi sharoitlarida talabalar bilimini nazorat qilish tartibi va baholash me'zonlari to'g'risida",
        file: talabalarning_bilimini_baholash_tartibi,
    },
    {
        id: 8,
        name: "O'zbekiston Respublikasi oliy ta'lim muassasalari bitiruvchilarining yakuniy davlat attestatsiyasi to'g'risida nizom",
        file: yakuniy_davlat_attestatsiyasi,
    },
]
export const statuteDocumentsHomepage: Array<{ id: number, name: string, file: any }> = [
    {
        id: 1,
        name: "Akademik halollik va tadqiqot etikasi kodeksi",
        file: akademik_halollik_kodeksi,
    },
    {
        id: 2,
        name: "Amaliyot o'tash tartibi",
        file: amaliyot_otash_tartibi,
    },
    {
        id: 3,
        name: "Bitiruv malakaviy ishni tayyorlash va baholash tartibi to'g'risidagi nizom",
        file: bmi_tartibi,
    },
    {
        id: 4,
        name: "Ta'limning kredit-modul tizimida o'quv jarayonini tashkil etish tartibi to'g'risida nizom",
        file: kredit_tizim_nizomi,
    },
    {
        id: 5,
        name: "Magistratura to'g'risidagi nizom ( Magistrlar uchun )",
        file: magistratura_haqida_nizom,
    },
    {
        id: 6,
        name: "Odob-axloq kodeksi",
        file: odob_axloq_kodeksi,
    },
    {
        id: 7,
        name: "O'qitishning modul tizimi sharoitlarida talabalar bilimini nazorat qilish tartibi va baholash me'zonlari to'g'risida",
        file: talabalarning_bilimini_baholash_tartibi,
    },
    {
        id: 8,
        name: "O'zbekiston Respublikasi oliy ta'lim muassasalari bitiruvchilarining yakuniy davlat attestatsiyasi to'g'risida nizom",
        file: yakuniy_davlat_attestatsiyasi,
    },
]