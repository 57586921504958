import { FC } from "react";
import { SidebarBoxUi, LinkBoxUi, LinkItemUi } from "./styled";
import ConfigLink from './config_bar';
import SidebarFooter from './footer';
import _logout from "config/_axios/logout";
import { Badge, BadgeProps, Button, Calendar, Layout } from "antd";
import { SelectStudentTheme } from "config/theme/colors";
import "./styles.scss";
import { student_routes } from "routes";
import { RoutesTypeSubmenu } from "routes/types";
import IconComponent from "components/Icon";
import { NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "store/services";
import { useSelector } from "react-redux";
import { RootState } from "store";
import user_male from 'assets/images/user_male.svg';
import StudentAvatarCard from "./avatar";
import { useTranslation } from "react-i18next";
import { BiLogOut } from "react-icons/bi";

const Sidebar: FC = (): JSX.Element => {

    const {t} = useTranslation();
    const _sidebar = useAppSelector(state => state.ui.sidebar);
    const { pathname } = useLocation();
    const ui = useSelector<RootState>(state => state.ui);
    const theme = SelectStudentTheme();
    let isMobile = /iPhone|Android/i.test(navigator.userAgent);

    return (
        <div className="h-100 d-f flex-column justify-content-beetwen">
            <StudentAvatarCard isMobile={isMobile} />
            <LinkBoxUi>
                <div className="mb-3" ><ConfigLink /></div>
                {
                    student_routes.map((element: RoutesTypeSubmenu, i: number) => {
                        if (element.config.isShowLink) {
                            return (
                                <NavLink to={element.path} className="text-decoration-none" key={i} >
                                    <LinkItemUi sidebar={_sidebar} theme={theme} active={element.path === pathname}>
                                        {IconComponent(element.config.icon)}<span>{t(element.name)}</span>
                                    </LinkItemUi>
                                </NavLink>
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </LinkBoxUi>
            <div className="mx-2 mt-auto my-1">
                <Button onClick={_logout} icon={
                <BiLogOut className="mx-2" color={theme.blue} style={{ transform: "rotate(-180deg)" }} size={20} />} className="d-flex justify-content-center align-items-center w-100" style={{height: 30, borderRadius: '0.25rem'}} >
                    {t("Exit")}
                </Button>
            </div>
        </div>
    )
}
export default Sidebar;