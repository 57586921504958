import { Button, Empty, Spin } from "antd";
import { IExamQuestion } from "models/exam";
import { BiX } from "react-icons/bi";
import { Card } from "antd";






const ResultView = ({ data, isLoading }: { data: IExamQuestion | undefined, isLoading: boolean }) => {

    return (
        <Spin spinning={isLoading}>
            <Card>
                {
                    data ?
                        <div className="student_answer_view_page">
                            <div className="body_question">
                                <strong>Savol</strong>
                                <span dangerouslySetInnerHTML={{ __html: data?.question.question?.replaceAll("&nbsp;", " ").trim() ?? '' }}></span>
                            </div>
                            {
                                data.examStudentAnswerSubQuestion?.length ?
                                    data.examStudentAnswerSubQuestion.map((element: any, index: number) => {
                                        return (
                                            <div key={element.id} className="sub_question">
                                                <div className="index_question">{index + 1}</div>
                                                <span dangerouslySetInnerHTML={{ __html: element.subQuestion?.question?.replaceAll("&nbsp;", " ").trim() ?? '' }}></span>
                                                <p className="mt-3" dangerouslySetInnerHTML={{ __html: element.answer ?? '' }}></p>
                                            </div>
                                        )
                                    }) : null
                            }

                        </div> : <Empty description="Ma'lumot topilmadi" />

                }
            </Card>
        </Spin>
    )


}






export default ResultView;