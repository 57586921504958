import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

type PageTitlePropsType = {
    title: ReactElement | string,
    isShowIcon?: true | undefined
}

const PageTitle: FC<PageTitlePropsType> = ({ title, isShowIcon }): JSX.Element => {

    const { t } = useTranslation();
    const history = useHistory()

    if (typeof title === 'string') {
        return <span className={styles.page_title} >{isShowIcon || isShowIcon === undefined ? <BiArrowBack size={18} onClick={() => history.goBack()} /> : null}{t(title)}</span>
    }

    return <>{title}</>


}


export default PageTitle;