import { Alert, Button, Card, Col, Form, message, Radio, Rate, Row } from "antd";
import instance from "config/_axios";
import i18next from "i18next";
import React, { FC, useEffect, useState } from "react";
import { asyncN } from "utils/notifications";
import { surveyType } from "../types";
import data from './opros.json'

const Questionnaire: FC<{ refresh: boolean, setRefresh: any, isView: boolean, setIsView: (isView: boolean) => void, subject_id: number, exam_id: number, answers: any }> = ({ refresh, setRefresh, isView, setIsView, exam_id, subject_id, answers }): JSX.Element => {

    const [form] = Form.useForm()
    const [questions, setQuestions] = useState<Array<surveyType>>([]);
    const [localAns, setLocalAns] = useState<Array<any>>([])


    useEffect(() => {
        if (answers) {
            setLocalAns(answers)
        }
    }, [answers])

    useEffect(() => {
        (
            async () => {
                try {
                    const resp = await instance({
                        method: 'get',
                        url: '/survey-questions?sort=id&expand=surveyAnswer'
                    });
                    if (resp?.data?.status === 1) {
                        setQuestions(resp?.data?.data?.items);
                        if (answers) {
                            let answer: any = {}
                            answers?.forEach((item: any) => {
                                answer = { ...answer, [`${item?.survey_question_id}-question`]: item?.ball }
                            })
                            form?.setFieldsValue(answer)
                        }
                    } else {
                        asyncN("error", "read", resp?.data?.message);
                    }
                } catch (error: any) {
                    asyncN("error", "read", error?.response?.data?.message);
                }
            }
        )()
    }, [])


    useEffect(() => {
        if (localAns?.length > 0 && localAns?.length > answers?.length) {
            if (localAns) {
                let answer1: any = {}
                localAns?.forEach((item: any) => {
                    answer1 = { ...answer1, [`${item?.survey_question_id}-question`]: item?.ball }
                })
                form?.setFieldsValue(answer1)
            }
        }
    }, [localAns])

    const setLocalAnswer = (answer: any) => {
        // console.log(answer);

        if (localAns?.length > 0) {
            setLocalAns([...localAns?.filter((item1: any) => item1?.survey_question_id != answer?.survey_question_id), answer])
        } else {
            setLocalAns([answer])
        }
    }

    // console.log(localAns);


    const onFinishQuestion = (values: any) => {
        // console.log(values);

        let a: Array<{ id: number, correct: number }> = [];
        Object.entries(values)?.map(([key, value]: any) => {
            if (value) {
                let s: Array<string> = key?.split("-");
                a.push({
                    id: Number(s[0]),
                    correct: value
                })
            }
        })
        if (a?.length === Object?.keys(values).length) {
            setIsView(true)
        } else {
            message.info("Iltimos barcha savollarga javob berib yuborish tugmasini bosing!")
        }
    }

    const onChange = async (id: number, ball: number) => {
        try {
            let data = new FormData();
            let putOrPost: any = answers && answers?.filter((ans: any) => ans?.survey_question_id == id)?.length > 0 ? answers?.filter((ans: any) => ans?.survey_question_id == id)[0] : undefined
            data.append("survey_question_id", String(id));
            data.append("ball", String(ball));
            data.append("exam_id", String(exam_id));
            data.append("subject_id", String(subject_id));
            const resp = await instance({
                method: putOrPost ? 'put' : 'post',
                url: putOrPost ? '/survey-answers/' + putOrPost?.id : '/survey-answers',
                data: data
            })
            if (resp?.data?.status == 1) {
                setLocalAnswer(resp?.data?.data)
            } else {
                asyncN("error", "create", resp?.data?.message)
            }
        } catch (error: any) {
            asyncN("error", "create", error?.response ? error?.response?.data?.message : error?.message)
            form.setFieldsValue({
                [`${id}-question`]: 0
            })
        }
    }


    return (
        <div>
            <Row className="d-flex justify-content-center" gutter={24}>
                <Col span={16}>
                    <Card className="cards shadow">
                        <Alert
                            message={
                                <div>
                                    Hurmatli talabalar. Mazkur so'rovnoma har yili o'tkazilib, yakuniy imtihon natijasiga umuman ta'sir korsatmaydi. Barcha yakuniy ishlar avtomatik ravishda shifrlanib, kafedraga tekshirish uchun anonim shaklda yuboriladi.<br /> Уважаемые студенты. Данный опрос проводится ежегодно и никак не влияет на итоговые результаты экзаменов. Все итоговые контрольные  работы автоматически шифруются и анонимно отправляются на кафедры для проверки.
                                </div>
                            }
                            type="warning"
                            showIcon
                            className="mb-3"
                        />
                        <Form form={form} onFinish={onFinishQuestion} layout="vertical">
                            {
                                questions?.map((item: surveyType, i: number) =>
                                    <Form.Item key={i}
                                        name={item?.id + "-question"}
                                        label={
                                            <h6 style={{ color: "#717171" }}> <span className="text-danger">*</span>    {i + 1}. {item?.question}</h6>
                                        }
                                        initialValue={
                                            answers && answers?.filter((ans: any) => ans?.survey_question_id == item?.id)?.length > 0 ?
                                                answers && answers?.filter((ans: any) => ans?.survey_question_id == item?.id)[0]?.ball : 0
                                        }
                                    >
                                        <Rate onChange={(e) => {
                                            onChange(item?.id, e)
                                        }} style={{ color: "yellow" }} allowClear count={item?.max} />
                                    </Form.Item>
                                )
                            }
                            <Row gutter={24}>
                                <Col span={24} className="d-flex justify-content-center">
                                    <Button type="primary" style={{ color: "white" }} htmlType="submit"><span style={{ color: "white", zIndex: 10000 }}>Yuborish</span></Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Questionnaire