import { FC, useState } from "react";
import { SelectStudentTheme } from "config/theme/colors";
import { Tabs } from "antd";
import { ExercisseWrapper } from "../common/styled";
import { subjectType } from "../common/type";
import instance from "config/_axios";
import CustomBreadcrumb from "components/Breadcrumb";
import { Card, CardBody } from "reactstrap";
import { useRouteMatch, match } from 'react-router';
import useGetAllData from "hooks/useGetAllData";
import { SpinnerLoading } from "components/Spinner";
import Topics from "../common/topics";

const _breadcrump = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Subjects",
    path: `std_subjects`,
  },
  {
    name: "Fan mavzulari",
    path: "",
  }
];

const { TabPane } = Tabs

const SubjectDetails: FC = (): JSX.Element => {

  let isMobile = /iPhone|Android/i.test(navigator.userAgent);
  const theme = SelectStudentTheme();
  const [subjectTopic, setSubjectTopic] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);

  // const match: match<any> | null = useRouteMatch("/subjects/:subject_id/:edu_semestr_id/:semestr_id/:language_id");
  const match: match<any> | null = useRouteMatch("/subjects/:subject_id");

  const urlParams = new URLSearchParams(window.location.search);
  const semestr_id = urlParams.get('semestr_id');
  const edu_semestr_id = urlParams.get('edu_semestr_id');
  const language_id = urlParams.get('language_id');

  const studentTimeTable = useGetAllData({
    url: `/student-time-tables?expand=subject,teacher,subjectCategory,timeTable,timeTable.teacher&semester_id=${semestr_id}&filter={"subject_id":${match?.params?.subject_id}}`,
    perPage: 0,
    isCall: "auto"
  })

  const eduSemestrSubjects = useGetAllData({
    url: `/edu-semestr-subjects?filter={"subject_id":${match?.params?.subject_id},"edu_semestr_id":${edu_semestr_id}}&expand=subjectType,subject`,
    perPage: 0,
    isCall: "auto"
  })

  const getSubjectTopics = async (categoryId: number) => {
    setLoading(true);
    const res = await instance.get(`/subject-topics?filter={"subject_id":${match?.params?.subject_id},"subject_category_id":${categoryId},"lang_id":${language_id}}&perPage=0`);
    setSubjectTopic(res?.data?.data?.items);
    setLoading(false);
  }

  const filterSubjectList = () => {
    const subject = studentTimeTable.items;
    let arr: Array<subjectType> = [];

    subject.forEach(e => {
      if (!arr?.find(a => a?.subjectCategory?.id === e?.subjectCategory?.id))
        arr.push(e)
    })

    return arr;
  }


  return (
    <Card>
      <CardBody>
        <SpinnerLoading loading={studentTimeTable.loading} color="#1890ff" size={24} >
          <ExercisseWrapper theme={theme} isMobile={isMobile} >
            <CustomBreadcrumb arr={_breadcrump} />
            <hr />
            <div className="subject_header" >
              <p><strong>&nbsp;<h6 className="d-inline" style={{ color: theme.blue }} >{eduSemestrSubjects.items[0]?.subject?.name}</h6> &nbsp;|&nbsp; {"Fan turi"}: </strong>{eduSemestrSubjects.items[0]?.subjectType?.name} &nbsp;|&nbsp; <strong>{"Umumiy soat"}: </strong>{eduSemestrSubjects.items[0]?.all_ball_yuklama + " soat"} &nbsp;|&nbsp; <strong>{"Kredit"}: </strong>{eduSemestrSubjects.items[0]?.credit}</p>
            </div>
            <div style={{ background: theme.element }} className="teacher">
              <p><b>O'qituvchi(lar):</b></p>
              {
                studentTimeTable.items?.length ? filterSubjectList()?.map((e: subjectType, i: number) => (
                  <p key={i} className="text-uppercase" >&nbsp; &nbsp;<b>{e?.subjectCategory?.name}</b> : <i>{e?.teacher?.last_name} {e?.teacher?.first_name} {e?.teacher?.middle_name}</i></p>
                )) : null
              }
            </div>
            <div>
              <Tabs defaultActiveKey="1">
                {studentTimeTable.items?.length ? filterSubjectList()?.map((element: subjectType, i: number) => (
                  <TabPane tab={element?.subjectCategory?.name} key={i + 1} >
                    <Topics subject_id={match?.params?.subject_id} category_id={element?.subjectCategory?.id} lang_id={Number(language_id)} user_id={element?.teacher?.user_id} />
                  </TabPane>
                )) : null
                }
              </Tabs>
            </div>
          </ExercisseWrapper>
        </SpinnerLoading>
      </CardBody>
    </Card>
  )
}

export default SubjectDetails;