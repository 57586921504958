import styled from "styled-components"


export const SettingBarUi = styled.div<{ theme: any, ui: any, active: boolean }>`
    margin: 0px auto;
    border-radius:4px;
    /* background: rgb(23,35,53);
    background: linear-gradient(90deg, rgba(23,35,53,1) 0%, rgba(27,41,62,1) 50%, rgba(23,35,53,1) 100%); */
    // background: rgb(18,39,175);
    // background: linear-gradient(90deg, rgba(18,39,175,1) 0%, rgba(29,57,196,1) 50%, rgba(18,39,175,1) 100%);
    background: ${props => props.theme.element};
    margin-bottom: 10px;
    display:flex;
    align-items:center;
    /* trasition: all 0.8s ease-in-out; */
    width:94%;
    height: 36px;
    width:94%;
    height: 36px;
    justify-content:space-evenly;
    & > button:nth-child(1){
        display:none;
    }

`

export const SidebarSettingButton = styled.button<{ theme: any, ui: any, active: boolean }>`
    border: 1px solid ${props => props.active ? props => props.theme.blue : 'transparent'};
    background:transparent;
    height: 24px;
    width: 28px;
    border-radius:4px;
    background-color: ${props => props.theme.active_element};
    transition: all 0.2s ease-in-out;
    display: ${props => props?.ui?.type !== 'lg' ? "block" : 'none'};
    position:relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

& > span {
    min-width: 10px;
    height: 10px;
    border-radius: 4px;
    /* background-color:${props => props.active ? '#5A8DEE' : '#5A8DEE'}; */
    background-color:${props => props.theme.blue};
    position: absolute;
    top: -8px;
    right: -8px;
    display: block;
    font-size: 7px;
    color:white;
    padding: 1px 3px 0 3px;
    text-shadow: 10px 0px 10px black;
}


& > svg {
    font-size: 17px;
    /* color:${props => props?.active ? '#5A8DEE' : '#6682B8'}; */
    color:${props => props?.theme.blue};
    position:absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
}

&:hover {
    border: 1px solid ${props => props.theme.blue};
    & > svg {
        color: ${props => props.theme.blue};
    }
}

`