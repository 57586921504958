import styled, { css } from "styled-components";



export const StudentAvatarCardUi = styled.div<{ isMobile: boolean, theme: any }>`

    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 220px;
    padding: 1rem;
    padding-top: 0;
    position: relative;
    .img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: ${(p) => p.theme.element};
      margin-bottom: 0.75rem;
    }
    .line {
      width: 100%;
      height: 1rem;
      border-radius: 0.25rem;
      background-color: ${(p) => p.theme.element};
      margin: 0.5rem 0;
      &:nth-child(3) {
        width: 70%;
      }
    }
    ${props => props.isMobile ?
        css`
        width: 90%;
        margin: 10px auto 0;
        .image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin: 0 auto 0.5rem;
            border: 6px solid ${props.theme.element};
            & > img {
                width:100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        & > p {
            color:${props.theme.text};
            text-align:center;
            font-size: 15px;
            font-weight: 450;
            margin: 0.5rem 0 0;
            padding: 0;
        }
        `:
        css`
        width:100%;
        position:relative;
        padding-bottom: 0;
        .image {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin: 1rem auto;
            border: 6px solid ${props.theme.element};
            & > img {
                width:100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        & > p {
            color:${props.theme.text};
            text-align:center;
            font-size: 17px;
            font-weight: 450;
            padding: 0;
            margin: 0.5rem 0 0;
            margin-top:4px;
            &:first-child{
                margin-top: 1rem;
            }
        }
        @media (max-width:1500px){
            border-radius:0px !important;
            margin-top: 0px !important;
            height: 110px;
            padding: 8px;
            border-top:1px solid ${props.theme.element};
            & > div {
                width: 70px;
                height: 70px;
                border: 4px solid ${props.theme.element};
            }
            & > p {
                text-align:center;
                font-size: 14px;
                font-weight: 400;
                margin-top:4px;
            }
            }
        `
    }
    `