import { FC, useRef } from "react";
import { BiPrinter, BiDownload } from 'react-icons/bi';
import ReferenceFromUniversityUz from './refrence_uz';
import { PrintBtnUi } from "../styled";
import PageContentTitleStudent from 'components/StudentTitle';
import { SelectStudentTheme } from "config/theme/colors";
import { Card, CardBody } from "reactstrap";


const Docs: FC = (): JSX.Element => {

    const print_ref = useRef<any>(null);
    const theme = SelectStudentTheme();
    // const data = [
    //     {
    //         edu_type: "bakalavr",
    //         lang: "uz",
    //         name: "Ubaydullayev Doston Akbar o'g'li",
    //         edu_form: "kunduzgi",
    //         faculty: "Xususiy huquq",
    //         course: 1,
    //         pay_form: "to'lov-kontrakt"
    //     },
    //     {
    //         edu_type: "bakalavr",
    //         lang: "en",
    //         name: "Ubaydullayev Doston Akbar o'g'li",
    //         edu_form: "",
    //         faculty: "Private law",
    //         course: 1,
    //         pay_form: "a state grant"
    //     },
    //     {
    //         edu_type: "bakalavr",
    //         lang: "ru",
    //         name: "Низаева Зарина Курбан кизи",
    //         edu_form: "очной форме ",
    //         faculty: "Частного право ",
    //         course: 1,
    //         pay_form: "бюджетной основе"
    //     },
    //     {
    //         edu_type: "magistr",
    //         lang: "uz",
    //         name: "Ubaydullayev Doston Akbar o'g'li",
    //         edu_form: "kunduzgi",
    //         faculty: "5А240101–Advakatlik faoliyati",
    //         course: 1,
    //         pay_form: "to'lov-kontrakt"
    //     },
    //     {
    //         edu_type: "magistr",
    //         lang: "en",
    //         name: "Akhatova Parizoda",
    //         edu_form: "",
    //         faculty: "Intellectual Property and Information Technology Law",
    //         course: 1,
    //         pay_form: "a state grant"
    //     },
    //     {
    //         edu_type: "magistr",
    //         lang: "ru",
    //         name: "Низаева Зарина Курбан кизи",
    //         edu_form: "",
    //         faculty: "Частного право ",
    //         course: 1,
    //         pay_form: "бюджетной основе"
    //     },
    // ]

    const printPage = (): void => {
        if (print_ref.current) {
            print_ref.current._print();
        }
    }

    return (
        <Card>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                    <PageContentTitleStudent title="Ma'lumotnoma" />
                    <div>
                        <PrintBtnUi theme={theme}> <BiDownload /></PrintBtnUi>&nbsp;&nbsp;
                        <PrintBtnUi theme={theme} onClick={printPage} > <BiPrinter /></PrintBtnUi>
                    </div>
                </div>
                <hr className="mt-2 mb-3" />
                <ReferenceFromUniversityUz ref={print_ref} />
            </CardBody>
        </Card>
    )
}



export default Docs;