import { Popconfirm, Tag } from "antd";
import { SelectStudentTheme } from "config/theme/colors";
import { ITimeTable } from "models/others";
import { FC } from "react";
import { BiListPlus } from "react-icons/bi";
import { SelectingButtonCard } from "../common/styled";
import styles from "../styles.module.scss";




const checkCapacity = (element: ITimeTable | any) => {

    if (element?.subject_category_id === 1) return element?.room?.capacity;

    const _capacity = Number(element?.room?.capacity) > 35 ? 35 : element?.room?.capacity;

    return _capacity

}

const TimeTableVariantChildItem: FC<{ elements: ITimeTable[] | any }> = ({ elements }) => {


    return (
        elements?.map((childElement: any) => {
            return (
                <div key={childElement.id} className={`${styles.time_table_card} mt-2`}>
                    <div className="pr-2">
                        <span>{childElement?.week?.name}&nbsp;/&nbsp;({childElement?.para?.start_time}-{childElement?.para?.end_time})&nbsp;/&nbsp;{childElement?.subjectCategory?.name}</span>
                    </div>
                    <p>
                        {childElement?.building?.name}&nbsp;/&nbsp;{String(childElement?.room?.name)?.replace(/\D/g, '')}-xona ({checkCapacity(childElement)})<br />
                        <a href="https://tsul.uz/uz/all-teachers" target="_blank" >{childElement?.teacher?.last_name}&nbsp;{childElement?.teacher?.first_name}&nbsp;{childElement?.teacher?.middle_name}</a>
                    </p>
                </div>
            )
        })
    )
}

const IsSelectButton: FC<{ isSelected: boolean, element: ITimeTable, selectTimeTable: (id: number) => void, theme: any, isSelectedSeminar?: boolean }> = ({ isSelected, element, selectTimeTable, theme, isSelectedSeminar }): JSX.Element | null => {

    if (Boolean(element.selected) && element.subject_category_id === 1) return <div style={{ display: 'inline-block', color: '#1967D2', fontSize: '12px', fontWeight: 500, textAlign: 'center', width: "100%", textTransform: 'uppercase' }} color="#E8F0FE" >Variant tanlash orqali tanlandi</div>;

    if (!Boolean(element.parent_id) && !Boolean(element.lecture_id) && Number(element.subject_category_id) === 1) return <>null1</>;

    if (!isSelected) return <>null2</>;

    if (Number(element.selectedCount) >= checkCapacity(element) && !Boolean(element.selected)) return <>null3</>;

    if (isSelectedSeminar) {

        return Boolean(element.selected) ? <Tag style={{ color: '#1967D2', fontSize: '12px', fontWeight: 500, textAlign: 'center' }} color="#E8F0FE" >TANLANDI</Tag> : null

    }

    return (
        <Popconfirm
            placement="topRight"
            title={"Tanlash imkoniyat bir marotaba !"}
            onConfirm={() => selectTimeTable(element.id)}
            okText="Tanlash"
            cancelText="Bekor qilish"
        >
            <SelectingButtonCard theme={theme}> <BiListPlus size={24} /></SelectingButtonCard>
        </Popconfirm>
    )

}



export const TimeTableVariantItem: FC<{ isSelected: boolean, element: ITimeTable, selectTimeTable: (id: number) => void, isSelectedSeminar?: boolean }> = ({ isSelected, element, selectTimeTable, isSelectedSeminar }) => {

    const theme: any = SelectStudentTheme();

    return (<>
        <div className={styles.filtered_time_table_card} style={element.selected ? { borderColor: "#0066ff" } : {}}>
            <div className={styles.select_card}>
                {
                    // Number(element.subject_category_id) !== 1 ?
                    <span className={styles.selected_count} >Tanlandi: {element.selectedCount ?? 0} / {checkCapacity(element)}</span>
                }
                <IsSelectButton isSelected={isSelected} element={element} theme={theme} selectTimeTable={selectTimeTable} isSelectedSeminar={isSelectedSeminar} />
            </div>
            <div className={`${styles.time_table_card} mt-2`}>
                <div className="pr-2">
                    <span>{element?.week?.name}&nbsp;/&nbsp;({element?.para?.start_time}-{element?.para?.end_time})/&nbsp;{element?.subjectCategory?.name}</span>
                </div>
                <p>
                    {element?.building?.name}&nbsp;/&nbsp;{String(element?.room?.name)?.replace(/\D/g, '')}-xona({checkCapacity(element)})<br />
                    <a href="https://tsul.uz/uz/all-teachers" target="_blank" >{element?.teacher?.last_name}&nbsp;{element?.teacher?.first_name}&nbsp;{element?.teacher?.middle_name}</a>
                </p>
            </div>
            <TimeTableVariantChildItem elements={element.child} />
        </div >
    </>)
}

