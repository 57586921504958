import { FC } from "react";
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useHistory } from "react-router-dom";




const PageContentTitleStudent: FC<{ title: string }> = ({ title }): JSX.Element => {

    const history = useHistory();

    return (
        <>
            <div className="pageContentTitleStudent">
                <BiLeftArrowAlt onClick={() => history.goBack()} fontSize={16} />
                <span>{title}</span>
            </div>
        </>
    )
}

export default PageContentTitleStudent;