import { FC } from "react"
import moment from "moment";
import { useAppSelector } from "store/services";
import { ArrayChecker } from "utils/ArrayChecker";
import { confirmMessage } from "./service";
import { UnreadMessageItem } from './styled';


const UnreadMessagesPage: FC = (): JSX.Element => {

    const messages = useAppSelector(state => state.data.messages);

    return (
        <div>
            <span className="d-flex align-items-center" style={{ fontSize: '13px', fontWeight: 400 }}> <span style={{ fontSize: "14px", color: "#5A8DEE", fontWeight: 450, marginRight: "10px" }}>Notice !</span> <span className="d-block">Agarda siz bu xabarni tasdiqlamasangiz tizimdan to'liq foydalana olmaysiz !</span></span>
            <hr className="m-0 p-0" />
            {
                ArrayChecker(messages.messages)._ && messages.messages.map((element) => {
                    return (
                        <UnreadMessageItem key={element.id}>
                            <div>
                                <span>Dekan <span>({moment.unix(element?.created_at).format("DD-MM-YYYY")})</span></span>
                                <button onClick={() => confirmMessage(element.id)}>accept</button>
                            </div>
                            <p>{element?.notification}</p>
                        </UnreadMessageItem>
                    )
                })
            }
        </div>
    )

}


export default UnreadMessagesPage;