import { Button, Card, Col, Divider, message, Popconfirm, Row } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { match, useRouteMatch } from "react-router-dom";
import useGetOneData from "hooks/useGetOneData";
import { IWeek } from "models/others";
import { Card as CardWrapper, CardBody } from "reactstrap";
import { FC, useState } from "react";
import { IClubTime } from "models/club";
import "../styles.scss";
import { BiListPlus } from "react-icons/bi";
import instance from "config/_axios";
import { MdPlaylistAddCheck } from "react-icons/md";

const _breadcrumb = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Mashg'ulotlar",
    path: "/clubs",
  },
  {
    name: "Subject contents",
    path: "/clubs/:id",
  }
]

const Club: FC = (): JSX.Element => {
  const match: match<any> | null = useRouteMatch("/club/:id");
  const [refetch, setRefetch] = useState<boolean>(false)

  const { data } = useGetOneData({
    url: `/clubs/${match?.params?.id}?expand=clubTimes,description`,
    isCall: "auto",
  });
  const weeks = useGetAllData({ url: `/weeks`, isCall: "auto" }) as TypeReturnUseGetAllData<IWeek>;

  const findWeek = (id: string) => weeks.items?.length ? weeks.items?.find(e => e?.id === Number(id))?.name : id;

  const subscribeClub = async ( club_id: number, club_time_id: number) => {
    try {
        const formdata = new FormData();
        formdata.append("club_time_id", String(club_time_id));
        const response = await instance({ url: "/student-clubs", method: "POST", data: formdata });
        if (response.data.status === 1) {
            message.success(response.data?.message?.selected_ids);
            addSelectedClub( club_id, club_time_id );
        }
    } catch (error) {
    }
}

const addSelectedClub = ( club_id: number, club_time_id: number) => {
    let data: Array<{ club_id: number, club_time_id: number}> = JSON.parse(localStorage.getItem("selectedClubs") || '[]')

    data.push({club_id: club_id, club_time_id: club_time_id})
    localStorage.setItem("selectedClubs", JSON.stringify(data))
    setRefetch(p => !p)
}

const selectedClub = ( club_id: number, club_time_id: number) => {
    const data: Array<{ club_id: number, club_time_id: number}> = JSON.parse(localStorage.getItem("selectedClubs") || '[]')
    return { club_id: data?.find(e=>e.club_id === club_id), club_time_id: data?.find(e=>e.club_time_id === club_time_id) }
}

  return (
    <CardWrapper>
      <CardBody>
        <CustomBreadcrumb arr={_breadcrumb} />
        <hr />
        <div className="bg-light">
          <Card key={data.id} className="mt-3 py-3 club" >
            <Row gutter={[12, 12]} >
              <Col span={24}>
                <div className="club_header">
                  <div className="club_image" >
                    <img src={FILE_URL + data?.image} alt="Rasm topilmadi" />
                  </div>
                  <div className="club_info">
                    <p>{data?.name}</p>
                  </div>
                </div>
              </Col>
              <Col span={24} >
                <Divider orientation="left" plain > <span className="divider_style">Mashg'ulot haqida ma'lumot</span></Divider>
                <p><span>{data?.description}</span> </p>
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain > <span className="divider_style">Mashg'ulot vaqtlari</span></Divider>
                <Row gutter={[12, 12]}>
                  {
                    data.clubTimes?.length ?
                      data.clubTimes.map((clubTimeItem: IClubTime) => {
                        const checkedclubs = selectedClub(data?.id, clubTimeItem?.id);
                        return (
                          <Col key={clubTimeItem.id} xl={6} lg={8} md={12} sm={24} xs={24}>
                            <div className="club_time_card" style={{ borderColor: checkedclubs.club_time_id ? "#3FA85C" : "" }} >
                              {
                                weeks.items.length ?
                                  weeks.items.map((weekItem) => {
                                    if (!clubTimeItem?.times || !clubTimeItem?.times[weekItem.id]) return null;
                                    return (
                                      <div key={weekItem.id} className="club_time">
                                        {weekItem.name}:&nbsp;{clubTimeItem?.times[weekItem.id]}
                                      </div>
                                    )
                                  }) : null
                              }
                              <div className="subscribe_club" >
                                {checkedclubs.club_id && checkedclubs.club_time_id ? <Button type="link" size="small" style={{ color: "#3FA85C" }} ><MdPlaylistAddCheck size={24} /></Button>
                                  : checkedclubs.club_id ? null
                                    : <Popconfirm placement="topRight" title={"A'zo bo'lmoqchimisiz !"} onConfirm={() => subscribeClub(data?.id, clubTimeItem?.id)} okText="Ha" cancelText="Yo'q">
                                      <Button type="link" size="small"><BiListPlus size={24} /></Button>
                                    </Popconfirm>}
                              </div>
                            </div>
                          </Col>
                        )
                      }) : null
                  }
                </Row>
              </Col>
              {/* <Col span={24}>
                <Divider orientation="left" plain><span className="divider_style">Mashg'ulot sardori</span></Divider>
                <div className="club_leader">
                  <p> <strong>F.I.O.</strong>&nbsp; Familiya Ism Otasining ismi </p>
                  <span> <strong>Description:</strong>&nbsp; Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Quasi, nisi? Laborum ut quo perspiciatis ea! Lorem ipsum dolor,
                    sit amet consectetur adipisicing elit. Nihil quia laudantium eius illo maxime corrupti
                    praesentium, amet aperiam laboriosam deserunt iusto rem, possimus explicabo eligendi. </span>
                    <iframe src=""/>
                </div>
              </Col> */}
            </Row>
          </Card>
        </div>
      </CardBody>
    </CardWrapper>
  )
}

export default Club;