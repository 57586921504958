import styled from "styled-components";

export const Wrapper = styled.div<{ theme: any }>`
.ant-breadcrumb span{
    color: ${props=>props.theme.text} !important;
}
.row{
    justify-content: space-around;
    margin-top: 1rem;
    & > div{
        margin-bottom: 16px
    }
    div > div{
        background-color: ${props => props.theme.element};
        height: 260px;
        min-width: 160px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        padding: 3rem .5rem;
        font-size: 18px;
        cursor: pointer;
        &:hover{
            background-color: ${props => props.theme.active_element};
        }
        h1{
            color: #5A8DEE;
            padding-bottom: 4px;
        }
    }
}
@media (max-width: 720px){
    .row{
        grid-template-columns: repeat(3, 1fr);
        padding: 8px;
        padding-top: 0;
        div > div{
            min-width: 136px;
        }
    }
}
@media (max-width: 576px){
    .row{
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
        div > div{
            min-width: 110px;
            height: 200px;
            font-size: 16px;
            padding: 1.5rem .25rem;
        }
    }
}
`