import { Col, Row } from "antd";
import { FC } from "react"
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomBreadcrumb from "components/Breadcrumb";
import { SelectStudentTheme } from "config/theme/colors";
import { Wrapper } from "./style";
import { Card, CardBody } from "reactstrap";

const _breadcrump = [
	{
		name: "Home",
		path: "/",
	},
	{
		name: "Kutubxona",
		path: "std_library",
	},
	{
		name: "Kitoblar",
		path: "std_library_books",
	},
];

const StudentLibraryBooks: FC = (): JSX.Element => {

	const theme = SelectStudentTheme();
	const { t } = useTranslation();

	return (
		<Card>
			<CardBody>
				<Wrapper theme={theme} >
					<Row className="p-2 px-0">
						<Col span={24}>
							<CustomBreadcrumb arr={_breadcrump.map(item => {
								item = { ...item, name: t(item?.name) };
								return item
							})} />
						</Col>
					</Row>
					<div className="component">
						<ul className="align">
							<li>
								<figure className='book'>
									<div className="book-pages">
										<ul className='hardcover_front'>
											<li>
												<img src="https://s.cdpn.io/13060/book1.jpg" alt="" width="100%" height="100%" />
												{/* <div className="img-page">
											<h1>hello</h1>
											<p>Lorem ipsum dolor sit amet.</p>
										</div> */}
											</li>
											<li></li>
										</ul>
										<ul className='page'>
											<li></li>
											<li>
												<a className="btn" href="#" download={"book"} >Download</a>
											</li>
											<li></li>
											<li></li>
											<li></li>
										</ul>
										<ul className='hardcover_back'>
											<li></li>
											<li></li>
										</ul>
									</div>
									<figcaption>
										<h1>Responsive Web Design</h1>
										<span>By Ethan Marcotte</span>
										<p>From mobile browsers to netbooks and tablets, users are visiting your sites from an increasing array of devices and browsers. Are your designs ready?...</p>
									</figcaption>
								</figure>
							</li>
							<li>
								<figure className='book'>
									<div className="book-pages">
										<ul className='hardcover_front'>
											<li>
												<img src="https://s.cdpn.io/13060/book2.jpg" alt="" width="100%" height="100%" />
											</li>
											<li></li>
										</ul>
										<ul className='page'>
											<li></li>
											<li>
												<a className="btn" href="#" download={"book"} >Download</a>
											</li>
											<li></li>
											<li></li>
											<li></li>
										</ul>
										<ul className='hardcover_back'>
											<li></li>
											<li></li>
										</ul>
										<ul className='book_spine'>
											<li></li>
											<li></li>
										</ul>
									</div>
									<figcaption>
										<h1>Mobile First</h1>
										<span>By Luke Wroblewski</span>
										<p>Our industrys long wait for the complete, strategic guide to mobile web design is finally over. Former Yahoo! design architect and co-creator of Bagcheck Luke Wroblewski knows more about mobile experience than the rest of us...</p>
									</figcaption>
								</figure>
							</li>
							<li>
								<figure className='book'>
									<div className="book-pages">
										<ul className='hardcover_front'>
											<li>
												<img src="https://s.cdpn.io/13060/book3.jpg" alt="" width="100%" height="100%" />
											</li>
											<li></li>
										</ul>
										<ul className='page'>
											<li></li>
											<li>
												<a className="btn" href="#" download={"book"} >Download</a>
											</li>
											<li></li>
											<li></li>
											<li></li>
										</ul>
										<ul className='hardcover_back'>
											<li></li>
											<li></li>
										</ul>
										<ul className='book_spine'>
											<li></li>
											<li></li>
										</ul>
									</div>
									<figcaption>
										<h1>Adaptive Web Desgn</h1>
										<span>By Aaron Gustafson</span>
										<p>The web is an ever-changing medium whose scope, application, audience and platform continue to grow on a daily basis. If youve worked on the web for any amount of time, youve likely heard or even used the term “progressive enhancement.”...</p>
									</figcaption>
								</figure>
							</li>
							<li>
								<figure className='book'>
									<div className="book-pages">
										<ul className='hardcover_front'>
											<li>
												<img src="https://s.cdpn.io/13060/book2.jpg" alt="" width="100%" height="100%" />
											</li>
											<li></li>
										</ul>
										<ul className='page'>
											<li></li>
											<li>
												<a className="btn" href="#" download={"book"} >Download</a>
											</li>
											<li></li>
											<li></li>
											<li></li>
										</ul>
										<ul className='hardcover_back'>
											<li></li>
											<li></li>
										</ul>
										<ul className='book_spine'>
											<li></li>
											<li></li>
										</ul>
									</div>
									<figcaption>
										<h1>Mobile First</h1>
										<span>By Luke Wroblewski</span>
										<p>Our industrys long wait for the complete, strategic guide to mobile web design is finally over. Former Yahoo! design architect and co-creator of Bagcheck Luke Wroblewski knows more about mobile experience than the rest of us...</p>
									</figcaption>
								</figure>
							</li>
							<li>
								<figure className='book'>
									<div className="book-pages">
										<ul className='hardcover_front'>
											<li>
												<img src="https://s.cdpn.io/13060/book3.jpg" alt="" width="100%" height="100%" />
											</li>
											<li></li>
										</ul>
										<ul className='page'>
											<li></li>
											<li>
												<a className="btn" href="#" download={"book"} >Download</a>
											</li>
											<li></li>
											<li></li>
											<li></li>
										</ul>
										<ul className='hardcover_back'>
											<li></li>
											<li></li>
										</ul>
										<ul className='book_spine'>
											<li></li>
											<li></li>
										</ul>
									</div>
									<figcaption>
										<h1>Adaptive Web Desgn</h1>
										<span>By Aaron Gustafson</span>
										<p>The web is an ever-changing medium whose scope, application, audience and platform continue to grow on a daily basis. If youve worked on the web for any amount of time, youve likely heard or even used the term “progressive enhancement.”...</p>
									</figcaption>
								</figure>
							</li>
						</ul>
					</div>
				</Wrapper>
			</CardBody>
		</Card>
	)


}


export default withRouter(StudentLibraryBooks);

