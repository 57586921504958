import { message } from "antd";
import instance from "config/_axios";
import { getNotifications } from "services";


export const confirmMessage = async (id: number) => {
    try {
        if (!id) {
            message.warning("Ma'lumotni tasdiqlash uchun id topilmadi !");
            return;
        }
        const formdata = new FormData();
        formdata.append("status", '2');
        const response = await instance({ url: `/notifications/approved/${id}`, method: "PUT", data: formdata });
        if (response.data?.status === 1) {
            message.success(response.data?.message);
            getNotifications();
        }
    } catch (error: any) {
        message.error(error?.message);
    }
}