import { Alert, Button, Card, Col, Collapse, Divider, Form, Image, Input, message, Modal, Popconfirm, Popover, Row, Tabs, Tag } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import instance from "config/_axios";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { FaDownload, FaEye } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import { CaretRightOutlined } from '@ant-design/icons';
import { Title } from "pages/styles/style";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const Result: FC<{}> = (): JSX.Element => {
    const {t} = useTranslation()
    const url = useLocation().pathname
    const [form] = Form.useForm()
    let a: Array<string> = url?.split("/")
    const [visible, setVisible] = useState<{ open: boolean, text: string, title: string, file: string | null }>({ open: false, text: "", title: "", file: null })
    const [apilatsiya, setApilatsiya] = useState<boolean>(false);
    const [studentExams, setStudentExams] = useState<any>([]);
    const [active, setActive] = useState<string>("0");
    const [refresh, setRefresh] = useState<boolean>(false)
    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "/std_exams"
        },
        {
            name: "Subject exams",
            path: `/student-exams/${a[2]}`
        },
        {
            name: "Exam result",
            path: url
        }
    ];

    useEffect(() => {
        (
            async () => {
                const resp = await instance({
                    method: 'get',
                    url: `/exam-students?expand=examStudentAnswers.examStudentAnswerSubQuestion,appeal,allBall,isChecked,isCheckedFull,examStudentAnswers.question,exam.subject&filter={"exam_id":${a[a.length - 1]}}`
                })
                setStudentExams(resp?.data?.data?.items);
                if (Array.isArray(resp?.data?.data?.items) && resp?.data?.data?.items.length && resp?.data?.data?.items[0]?.appeal) {
                    form.setFieldsValue({ appeal_text: resp?.data?.data?.items[0]?.appeal?.appeal_text });
                }
            }
        )()
    }, [refresh])

    const getText = (data: any): string => {
        let div = document.createElement('div');
        div.innerHTML = data;
        return div.innerText;
    }




    const appealTypes1 = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Asosli</Tag>;
    const appealTypes2 = <Tag color="#FCE8E6" style={{ color: '#C5221F', minWidth: '120px', textAlign: 'center' }} >Asossiz</Tag>;
    const appealTypes3 = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Texnik</Tag>;
    const appealTypes4 = <Tag color="#E8F0FE" style={{ color: '#1A73E8', minWidth: '120px', textAlign: 'center' }} >Asosli va Texnik</Tag>;
    const appealTypesDef = <Tag color="#E8F0FE" style={{ color: '#1A73E8', minWidth: '120px', textAlign: 'center' }} >Jarayonda</Tag>;


    const appealTypes = (status: number) => {

        switch (status) {
            case 1:
                return appealTypes1;
            case 2:
                return appealTypes2;
            case 3:
                return appealTypes3;
            case 4:
                return appealTypes4;
            default:
                return appealTypesDef;
        }
    }


    const _delete = async () => {
        const id = studentExams[0]?.appeal?.id;
        if (id) {
            const response = await instance({ url: `/exam-appeals/${id}`, method: 'DELETE' });
            if (response.data?.status) {
                asyncN('success', 'delete', response.data?.message).then(() => { setRefresh(!refresh); form.resetFields() });
            } else {
                asyncN('error', 'delete', response.data?.message);
            }
        } else {
            message.warning("Id ma'lumoti topilmadi.");
        }
    }

    const sendAppeal = async (values: { appeal_text: string }) => {
        try {
            let data = new FormData();
            data.append("appeal_text", values?.appeal_text);
            data?.append("exam_student_id", studentExams[0]?.id);
            const id = studentExams[0]?.appeal?.id;
            const _options: any = id ? { url: `/exam-appeals/${id}`, method: "PUT", data } : { url: '/exam-appeals', method: "POST", data }
            const response = await instance(_options);
            if (response?.data?.status === 1) {
                setRefresh(!refresh)
                asyncN("success", "create", response?.data?.message).then(() => { setApilatsiya(false); setRefresh(prevState => !prevState) })
            } else {
                asyncN("error", "create", response?.data?.message)
            }
        } catch (error: any) {
            if (error?.response?.status == 422) {
                asyncN("error", "create", error?.response?.data?.errors[0]);
            } else {
                asyncN("error", "create", error?.response ? error?.response?.data?.message : error?.message);
            }
        }
    }

    const appealtime = (): boolean => {
        let start_appeal = studentExams[0]?.exam?.appeal_start ? new Date(studentExams[0]?.exam?.appeal_start) : null
        let finish_appeal = studentExams[0]?.exam?.appeal_finish ? new Date(studentExams[0]?.exam?.appeal_finish) : null
        if (start_appeal && finish_appeal) {
            return start_appeal?.getTime() < new Date()?.getTime() && finish_appeal?.getTime() < new Date()?.getTime();
        }
        else return true;
    }


    return (
        <Card bordered={false}>
            <Title color="">Imtihon natijasi</Title>
                <CustomBreadcrumb arr={_breadcrump} />
                {studentExams[0]?.act === 1 ? 
                <Alert showIcon type="warning" message="Siz imtihondan chetlashtirilgansiz!" /> 
                : <div className="site-card-wrapper mt-3 p-3" style={{ backgroundColor: '#F2F4F6' }}>
                    <Row gutter={[12, 12]}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Card title="Fan nomi" bordered={false}>
                                <span className="fw-bold">{studentExams[0]?.exam?.subject?.name || 'Fan nomi mavjud emas'}</span>
                            </Card>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Card title="Oraliq nazorat 1" bordered={false}>
                                Ball: <span className="fw-bold">{studentExams[0]?.on1 || 'Ball mavjud emas'}</span>
                            </Card>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Card title="Oraliq nazorat 2" bordered={false}>
                                Ball: <span className="fw-bold">{studentExams[0]?.on2 || 'Ball mavjud emas'}</span>
                            </Card>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Card title="Yakuniy nazorat" bordered={false}>
                                Ball: <span className="fw-bold">{studentExams[0]?.allBall || 'Ball mavjud emas'}</span>
                            </Card>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Card title="Umumiy ball" bordered={false}>
                                Ball: <span className="fw-bold">{Number(studentExams[0]?.on1) + Number(studentExams[0]?.on2) + Number(studentExams[0]?.allBall) || 'Ball mavjud emas'}</span>
                            </Card>
                        </Col>
                    </Row>
                </div>}
                <Tabs activeKey={active} centered onChange={(e) => setActive(String(e))}>
                    {studentExams[0]?.examStudentAnswers?.map((item: any, key: number) => {
                        return (
                            studentExams[0]?.act === 1 ? '' : <Tabs.TabPane tab={`${(key + 1) + '-savol'}`} key={`${key}`}>
                                <Row className="pt-3 pb-5" gutter={24}>
                                    <Col span={24}>
                                        {studentExams[0]?.isChecked == 0 ? <Alert type="warning" showIcon message="Natijalar tekshirilmagan!" />
                                            : studentExams[0]?.isCheckedFull == 0 && !studentExams[0]?.appeal ? <Alert type="warning" showIcon message="Natijalar to'la tekshirilmagan!" /> : ''
                                        }
                                    </Col>
                                    {isHasAuthRoute("exam-appeal_view") && studentExams[0]?.appeal ? studentExams[0]?.appeal?.type != null && [1, 2, 3, 4]?.includes(studentExams[0]?.appeal?.type) ? <Col span={24}>
                                        <Alert description={
                                            <div>
                                                <span>
                                                    <span style={{ fontWeight: "450" }}>Apelaysiya: </span>{appealTypes(studentExams[0]?.appeal?.type)}
                                                </span>
                                                <br />
                                                <span>
                                                    {studentExams[0]?.appeal?.teacher_conclusion ?? ''}
                                                </span>
                                            </div>
                                        } />
                                    </Col> : <Col span={24}><Alert className="w-100" description="Apelatsiya tekshirilmoqda" /></Col> : ''}
                                    <Col span={24}>
                                        <Col className="d-flex justify-content-start align-items-center" span={24}>
                                            <h6>1-Kazus <Button onClick={() => {
                                                setVisible({
                                                    open: true,
                                                    title: "Kazus text",
                                                    text: getText(item?.question?.question),
                                                    file: item?.question?.question_file ? FILE_URL + item?.question?.question_file : null
                                                })
                                            }} type="link"><FaEye /></Button></h6>
                                        </Col>
                                        <Col span={24}>
                                            <Collapse
                                                defaultActiveKey={["0", "1", "2"]}
                                                bordered={false}
                                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                className="site-collapse-custom-collapse"
                                            >
                                                {
                                                    item?.question?.subQuestion?.map((subQ: any, i: number) => {
                                                        return (
                                                            <Panel
                                                                key={i}
                                                                header={(i + 1) + ". " + getText(subQ?.question)}
                                                                className="site-collapse-custom-panel"
                                                            >
                                                                <Row key={i} gutter={24}>
                                                                    <Col span={24}>
                                                                        <Divider orientation="left" orientationMargin={0} style={{ color: '#2DB7F5', fontSize: '14px' }} >Talaba javobi</Divider>
                                                                        <div className="result-exam-answer" dangerouslySetInnerHTML={{ __html: item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.answer || "Talaba javobi mavjud emas" }} />
                                                                        {
                                                                            item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.teacher_conclusion && <div>
                                                                                <Divider orientation="left" orientationMargin={0} style={{ color: '#2DB7F5', fontSize: '14px' }} >O'qituvchi izohi</Divider>
                                                                                <div>
                                                                                    {item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.teacher_conclusion}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {isHasAuthRoute("exam-appeal_view") && item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.appeal_teacher_conclusion && <div>
                                                                            <Divider orientation="left" orientationMargin={0} style={{ color: '#2DB7F5', fontSize: '14px' }} >Apelatsiya izohi</Divider>
                                                                            <div>
                                                                                {item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.appeal_teacher_conclusion}
                                                                            </div>
                                                                        </div>}
                                                                        {item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.ball != null ?
                                                                        <Popover content={<span>{t("Avvalgi ball")}/{t("Hozirgi ball")}/{t("Max ball")}</span>}>
                                                                            <Tag color="#2db7f5" className="mt-3 text-center" style={{ minWidth: "150px", fontSize: '14px' }}> Ball: &nbsp;&nbsp;
                                                                                {isHasAuthRoute("exam-appeal_view") && studentExams[0]?.appeal ? item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.old_ball ?
                                                                                    item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.ball + " / " +
                                                                                    item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.old_ball :
                                                                                    "- / " + item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.ball
                                                                                    : item?.examStudentAnswerSubQuestion?.filter((item: any) => Number(item?.sub_question_id) === Number(subQ?.id))[0]?.ball
                                                                                } / {
                                                                                    item?.max_ball * subQ?.percent / 100
                                                                                }
                                                                            </Tag>
                                                                        </Popover> : null}
                                                                    </Col>
                                                                </Row>
                                                            </Panel>
                                                        )
                                                    })
                                                }
                                            </Collapse>
                                            <Col span={24} className="d-flex justify-content-between mt-3 flex-wrap">
                                                {
                                                    studentExams[0]?.examStudentAnswers[active]?.teacher_conclusion ? <Alert className="mb-2 w-100" message={
                                                        <div >
                                                            <strong>Kazus uchun xulosa matni: </strong>{studentExams[0]?.examStudentAnswers[active]?.teacher_conclusion}
                                                            {
                                                                studentExams[0]?.examStudentAnswers[active]?.appeal_teacher_conclusion && studentExams[0]?.examStudentAnswers[active]?.appeal_teacher_conclusion !== 'null' ?
                                                                    <p>
                                                                        <strong>Kazus uchun xulosa matni: </strong>{studentExams[0]?.examStudentAnswers[active]?.appeal_teacher_conclusion}
                                                                    </p> : null
                                                            }
                                                        </div>
                                                    } /> : null
                                                }
                                                {studentExams[0]?.allBall || studentExams[0]?.allBall == 0 ?
                                                    <div className="d-flex justify-content-start align-items-center mt-2 pb-2"><span style={{ fontWeight: "420" }}>Jami ball</span> : <Tag color="#2db7f5" className="ps-1 ms-1 text-center" style={{ minWidth: "100px", fontSize: '14px' }}>{studentExams[0]?.allBall} / {studentExams[0]?.examStudentAnswers[0]?.max_ball}</Tag></div> : ''}
                                                {appealtime() ? studentExams[0]?.exam?.status === 4 && isHasAuthRoute("exam-appeal_view") ?
                                                    <div className="d-flex justify-content-end align-items-center w-100">
                                                        {studentExams[0]?.appeal && isHasAuthRoute("exam-appeal_delete") ? <Popconfirm placement="top" title={"O'chirilsinmi ?"} onConfirm={_delete} okText="Yes" cancelText="No">
                                                            <Button type="primary" danger className="me-2" >Apelatsiyani o'chirish</Button>
                                                        </Popconfirm> : null}
                                                        {[1, 2]?.includes(studentExams[0]?.appeal?.type) ?
                                                            <Button onClick={() => {
                                                                setApilatsiya(true);
                                                                form?.setFieldsValue({
                                                                    appeal_text: studentExams[0]?.appeal?.appeal_text
                                                                })
                                                            }} type="primary">{"Apelatsiyani ko'rish"}</Button> : ''}
                                                        {isHasAuthRoute("exam-appeal_update") || isHasAuthRoute("exam-appeal_create") ?
                                                            <Button className="mx-2" onClick={() => {
                                                                setApilatsiya(true);
                                                                form?.setFieldsValue({
                                                                    appeal_text: studentExams[0]?.appeal?.appeal_text
                                                                })
                                                            }} type="primary">{
                                                                    studentExams[0]?.appeal && isHasAuthRoute("exam-appeal_update")
                                                                        ? 'Apelatsiyani tahrirlash' : (!studentExams[0]?.appeal && isHasAuthRoute("exam-appeal_create")) ? 'Apelatsiya berish'
                                                                            : "Apelatsiyani ko'rish"}</Button> : ''}
                                                        <span></span>
                                                    </div> : ''
                                                    :
                                                    <div className="text-warning">Imtihon uchun apelatsiya vaqti tuagagan!</div>
                                                }
                                            </Col>
                                        </Col>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        )
                    })}
                </Tabs>
                <Modal footer={false} title={visible?.title} onCancel={() => setVisible({ open: false, text: "", title: "", file: null })} open={visible?.open}>
                    <div>{visible?.text}</div>
                    <div className="row">
                        <div className="col-12">
                            {visible?.file ? <img width="100%" src={visible?.file} alt="" /> : ''}
                        </div>
                    </div>
                </Modal>
                <Modal footer={false} title={"Apelatsiya uchun izohingizni yozing!"} visible={apilatsiya} onCancel={() => setApilatsiya(false)}>
                    <Form form={form} onFinish={sendAppeal} layout="vertical">
                        <div className="d-flex py-1 justify-content-start">
                            <span>
                                <span style={{ fontWeight: "450" }}>Fan nomi: </span>
                                {studentExams[0]?.exam?.subject?.name}
                            </span>
                        </div>
                        <div className="d-flex py-1 justify-content-start">
                            <span>
                                <span style={{ fontWeight: "450" }}>Jami ball: </span><Tag>{studentExams[0]?.allBall}</Tag>
                            </span>
                        </div>
                        {studentExams[0]?.appeal?.teacher_conclusion ? <div className="d-flex py-1 justify-content-start">
                            <span>
                                <span style={{ fontWeight: "450" }}>Izohi: </span>{studentExams[0]?.appeal?.teacher_conclusion}
                            </span>
                        </div> : ''}
                        <div className="d-flex py-1 justify-content-start">
                            <span>
                                <span style={{ fontWeight: "450" }}>Holati: </span>{studentExams[0]?.appeal?.type ? appealTypes(studentExams[0]?.appeal?.type) : "Ma'lumot yo'q"}
                            </span>
                        </div>
                        <hr />
                        <Form.Item
                            label="Apelatsiya matni"
                            name={"appeal_text"}
                            rules={[{ required: true, message: "Maydonnni to'ldiring!" }]}
                        >
                            <Input.TextArea readOnly={!(isHasAuthRoute("exam-appeal_create") || isHasAuthRoute("exam-appeal_update"))} showCount maxLength={1500} style={{ height: "150px" }} placeholder="Izoh yozing" />
                        </Form.Item>
                        <Divider className="mb-2" ></Divider>
                        <div className="text-end">
                            {isHasAuthRoute("exam-appeal_create") || isHasAuthRoute("exam-appeal_update") ?
                                <>
                                    <Button htmlType="reset" type="primary" danger className="me-2" >Tozalash</Button>
                                    <Button htmlType="submit" type="primary">Saqlash</Button>
                                </> : <></>
                            }
                        </div>
                    </Form>
                </Modal>
        </Card>
    )
}

export default Result;