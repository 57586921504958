import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExam, IExamQuestion, IExamSurvey, IGetExamQuestion } from "models/exam";
import { GetExamInfo, GetExamQuestion } from "pages/std_exam/service.requests";


export type InitialStateExamStore = {
    isPrivate: boolean,
    isAuthenticatedExam: boolean,
    exam_info: {
        data: Partial<IExam>,
        isLoading: boolean,
        exam_survey_questions: (IExamSurvey & { ball: number })[] | null
    },
    pass_exam: {
        time: IGetExamQuestion['times'] | null
        exam_question: IExamQuestion | null
        exam_questions: IExamQuestion[] | null
        isLoading: boolean
        question_count: number[]
    },
    answer: {
        [key: string]: string
    }
}

const initialState: InitialStateExamStore = {
    isPrivate: false,
    isAuthenticatedExam: false,
    exam_info: {
        data: {},
        isLoading: false,
        exam_survey_questions: null
    },
    pass_exam: {
        time: null,
        exam_question: null,
        exam_questions: null,
        isLoading: false,
        question_count: [],
    },
    answer: {}

}

const examSlice = createSlice({
    name: 'exam',
    initialState: initialState,
    reducers: {
        // addSurveyAnswer(state, action: PayloadAction<{ survey_answer: IExamSurveyAnswer }>) {

        //     const { survey_answer } = action.payload;

        //     if (survey_answer && state.exam_info.exam_survey_questions?.length) {

        //         state.exam_info.exam_survey_questions = state.exam_info.exam_survey_questions.map((element) => {
        //             if (survey_answer.survey_question_id === element.id) {
        //                 return {
        //                     ...element,
        //                     ball: Number(survey_answer.ball)
        //                 }
        //             }
        //             return element;
        //         });
        //     }

        //     if (survey_answer) {

        //         const surveyAnswers = state.exam_info.data.surveyAnswer;

        //         if (surveyAnswers && surveyAnswers.length) {

        //             const findAnswer = surveyAnswers.find(e => e.id === survey_answer.id);

        //             console.log("findAnswer -> ", findAnswer, surveyAnswers, state.exam_info.data)

        //             if (findAnswer) {
        //                 state.exam_info.data.surveyAnswer = surveyAnswers.map((element) => {
        //                     if (survey_answer.id === findAnswer.id) {
        //                         return {
        //                             ...survey_answer,
        //                             ball: Number(survey_answer.ball)
        //                         }
        //                     }
        //                     return element;
        //                 });
        //             }
        //         } else {
        //             state.exam_info.data.surveyAnswer = [{ ...survey_answer, ball: Number(survey_answer.ball) }]
        //         }

        //     }
        // }
        selectExamQuestion(state, action: PayloadAction<{ exam_question_id: number }>) {

            const { exam_question_id } = action.payload;
            if (exam_question_id) {

                const findExamQuestion = state.pass_exam.exam_questions?.find((e: any) => e.id === exam_question_id);

                if (findExamQuestion) {
                    state.pass_exam.exam_question = findExamQuestion;
                }
            }
        },
        saveAnswer(state, action: PayloadAction<{ key: string, value: string }>) {
            state.answer[action.payload.key] = action.payload.value;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(GetExamInfo.pending, (state: any) => {
                state.exam_info.isLoading = true;
            })
            .addCase(GetExamInfo.fulfilled, (state: any, action: PayloadAction<{ exam_info: IExam, exam_survey_questions: (IExamSurvey & { ball: number })[] | null }>) => {
                state.exam_info.data = action.payload.exam_info;
                state.exam_info.exam_survey_questions = action.payload.exam_survey_questions;
                state.exam_info.isLoading = false;
            })
            .addCase(GetExamInfo.rejected, (state: any) => {
                state.exam_info.isLoading = false;
            })
            .addCase(GetExamQuestion.pending, (state: any) => {
                state.pass_exam.isLoading = true
            })
            .addCase(GetExamQuestion.fulfilled, (state: any, action: PayloadAction<{ time: IGetExamQuestion['times'], exam_questions: IExamQuestion[] }>) => {

                const { exam_questions, time } = action.payload;

                if (exam_questions.length) {

                    state.pass_exam.exam_question = exam_questions[0];
                    state.pass_exam.exam_questions = exam_questions;

                    if (exam_questions.length > 1) {

                        state.pass_exam.question_count = exam_questions.map((e: any) => e.id);

                    }
                }
                state.pass_exam.time = time;
                state.pass_exam.isLoading = false;

            })
            .addCase(GetExamQuestion.rejected, (state: any, action: any) => {

                state.pass_exam.isLoading = false;

            })
    }
})


export const EXAM_ACTIONS = examSlice.actions;

export default examSlice;