import { FC } from "react";
import { SelectStudentTheme } from "config/theme/colors";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { ExercisseWrapper } from "./styled";
import useGetAllData from "hooks/useGetAllData";
import { SpinnerLoading } from "components/Spinner";
import { EmptyData } from "components/EmptyTable";


const Topics: FC<{ subject_id: number, category_id: number, lang_id: number, user_id: number }> = ({ subject_id, category_id, lang_id, user_id }): JSX.Element => {

  let isMobile = /iPhone|Android/i.test(navigator.userAgent);
  const theme = SelectStudentTheme();

  const topics = useGetAllData({
    url: `/subject-topics?filter={"subject_id":${subject_id},"subject_category_id":${category_id},"lang_id":${lang_id}}`,
    isCall: "auto",
    perPage: 0,
  })

  return (
    <ExercisseWrapper theme={theme} isMobile={isMobile} >
      <SpinnerLoading loading={topics.loading} color="#1890ff" size={24}>
        {topics.items?.length ? <Table className="table table-responsive border-0">
          <Thead>
            <Tr className="head_tr" >
              <Th>№</Th>
              <Th>Mavzu nomi</Th>
              <Th style={{ width: 160 }} >Ajratilgan soat</Th>
            </Tr>
          </Thead>
          <Tbody>
            {topics.items?.map((item: any, i: number) => (
              <Tr key={i} className="body_tr" >
                <Td>{i + 1}</Td>
                <Td><div className="topic" >
                  <p className="text-uppercase " ><Link to={`/subject_content_1/${item?.id}/${user_id}`}>{item?.name}</Link></p>
                  <p>{item?.description && item?.description !== "undefined" ? item?.description : null}</p>
                </div>
                </Td>
                <Td><p>{item?.hours} soat</p></Td>
              </Tr>
            ))}
          </Tbody>
        </Table> : null}
      </SpinnerLoading>
      <EmptyData data={topics.items} description={"Ma'lumot topilmadi"} loading={topics.loading} />
    </ExercisseWrapper>
  )
}

export default Topics;