import { FC, useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { Button, Card } from 'antd';
import styles from './styles.module.scss';
import { Title } from 'pages/styles/style';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PFDFile from 'pages/download_contract/PDFFile';
import { useAppSelector } from 'store/services';
import useGetOneData from 'services/useGetOneData';
import instance from 'config/_axios';
import { FILE_URL } from 'config/utils';
import useGetAllData from 'hooks/useGetAllData';


const InteractiveServices: FC = (): JSX.Element => {
    const url_lang = localStorage.getItem('i18lang') || 'uz';
    const access_token = localStorage.getItem('access_token');
    const { data, loading } = useGetOneData({
        url: `students/me?expand=profile,user,faculty,direction,eduYear,eduType,eduPlan,eduLangs,eduForm,course,region,area,country,permanentArea,permanentCountry,permanentRegion,username,citizenship,nationality,email,contract_id,contract_number`,
        isCall: 'auto'
    });
    const [url, setUrl] = useState<string | null>(null)
    const [lImages, setLImages] = useState(false)

    useEffect(() => {
        (
            async () => {
                setLImages(true)
                const res: any = await fetch(`https://api-digital-tex.tsul.uz/api/web/${url_lang}/tex-contact-files`,{
                    method: 'get',
                    headers: {
                        "Content-Type": "application/pdf",
                        "Authorization": `Bearer ${access_token}`
                    }
                }).then(res => res.blob())
                setUrl(URL.createObjectURL(res))
                setLImages(false)
            }
        )()
    }, [])
    

    return (
        <Card bordered={false}>
            <Title color=''>Interaktiv xizmatlar</Title>
            <div className='pt-3'>
                <NavLink to={"/dormitory"} className="text-decoration-none">
                    <div className={styles.list_item} >
                        <span>
                            <BiInfoCircle fontSize={24} color="#1967d2" />
                            Talabalar turar joyi
                        </span>
                        <span>
                        </span>
                    </div>
                </NavLink>
                <div className={`d-f align-items-center mt-2 ${styles.list_item}`}>
                    <p className='mb-0 me-2'>Kontrakt shartnomasini yuklab olish:</p>
                    <Button type='primary'>
                    {
                        (loading || lImages) ? "Loading" : url !== null ? 
                        <a href={url} download={`${data?.user?.first_name}_${data?.user?.last_name}_${data?.user?.middle_name}`} target='_blank'>Download</a>
                        : 'Texnikum rekvizitlari yuklanmagan'
                    }
                    </Button>
                </div>
            </div>
        </Card>
    )
}

export default InteractiveServices;