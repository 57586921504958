import styled from "styled-components";

export const WrappaerTeacherStatistics = styled.div<{ theme: any }>`
  color: ${props => props.theme.text};
  p{
    margin: 0;
  }

  .avatar{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid ${p => p.theme.active_element};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .star{
    position: relative;
    width: 100%;
    & div{
      font-size: 18px;
      /* color: #FADB14; */
      /* background-color: #FADB14; */
    }
    .editStar{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: ${p => p.theme.blue};
      &:hover{
        color: ${p => p.theme.blue};
      }
    }
  }
  .flipInX{
    animation-fill-mode: both;
    animation-duration: 0.75s;
    animation-name: flipInX;
  }

  @keyframes flipInX {
    0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.ant-card-body{
    padding: 1rem;
  }
@media (max-width: 640px){
  .ant-card-body{
    padding: .5rem;
  }
}

.rounded{
    border-radius: .5rem !important;
    & > div{
      padding: 1rem !important;
    }
  }
  .tabs *{
    color: ${p => p.theme.text};
  }
  *{
    .rounded{
      border: 1px solid ${props => props.theme.active_element} !important;
      background-color: transparent;
    }
    .cards{
      background-color: ${props => props.theme.element};
    }
  }
  .ant-tabs-nav::before {
    content: "";
    border-bottom-color: ${(props) => props.theme.active_element};
  }
  .ant-tabs-tab-active{
    border-bottom-color: ${(props) => props.theme.blue};
    .ant-tabs-tab-btn{
      color: ${props => props.theme.blue};
    }
  }
`