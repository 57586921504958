import styled from "styled-components";

export const Wrapper = styled.div<{ theme: any }>`
*{
    box-sizing: border-box;
    color: ${props => props.theme.text};
    border-color: ${p=>p.theme.active_element};
    border-radius: 5px 5px 0 0;
  }
  .ant-collapse-item{
    border-radius: 5px 5px 0 0;
    border-color: ${p=>p.theme.active_element};
  }
  .ant-collapse-header{
    border-radius: 5px 5px 0 0;
    color: ${props => props.theme.header_text} !important;
    background-color: ${props => props.theme.element};
    border-color: ${p=>p.theme.active_element};
    /* border-top: 4px solid #74B4E0; */
  }
  .ant-collapse-content-box{
  border-color: ${p=>p.theme.active_element};
  /* padding: 0; */
  background-color: ${props => props.theme.card};
}
`;