import { useDispatch } from 'react-redux';
import './login.scss'
import SignIn from './signIn';
import { Button, Form, Input, message } from "antd";
import loginImg from '../../assets/images/loginImg.png'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Login: any = () => {


    const dispatch: any = useDispatch();
    const history = useHistory();

    const onFinish = async (values: any) => {
        const formdata = new FormData();
        for (const [key, value] of Object.entries<any>(values)) {
            formdata.append(key, value)
        }
        formdata.append("is_main", '0');
        const arg = {
            type: 'login',
            data: formdata
        }
        await dispatch(SignIn(arg));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        history.listen((location) => {
            if (location.pathname === localStorage.getItem("_url")) {
                localStorage.removeItem("_url");
            }
        })
    }, [])


    return (
        <div className="login-page" style={{ position: "relative", overflow: "hidden" }}>
            {/* <Snow /> */}
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img src={loginImg} alt="Login" />
                </div>
                <Form
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <p className="form-title">Welcome to STD.TSUL</p>
                    <p>Login to the student profile</p>
                    <p className="form-title">Login to the student profile</p>
                    <p>&nbsp;</p>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input
                            placeholder="Username"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            LOGIN
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default Login;
