import { FC, useState } from 'react';
import { Form, Row, Col, Radio, Input, Tabs, Divider, Checkbox, Card, Image, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FormLabelCrud from 'components/FormLabel/FormLabelCrud';
import useGetOneData from 'services/useGetOneData';
import { FILE_URL } from 'config/utils';
import user_male from 'assets/images/user_male.svg';
import user_female from 'assets/images/user_female.svg';
import styles from './styles.module.scss';
import ChangePassword from 'pages/change_pass';
import CustomBreadcrumb from 'components/Breadcrumb';
import PageTitle from 'components/PageTitle';

const { TabPane } = Tabs;

const Profile: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [isMilitary, setIsMilitary] = useState<boolean>(false);
    const { data } = useGetOneData({
        url: `students/me?expand=profile,user,faculty,direction,eduYear,eduType,eduPlan,eduLangs,eduForm,course,region,area,country,permanentArea,permanentCountry,permanentRegion,username,citizenship,nationality`,
        isCall: 'auto'
    });

    return (
        <Card bordered={false}>
                <ChangePassword visible={visible} setVisible={setVisible} />
                <Row gutter={[12, 12]}>
                    <Col md={24} xl={12} >
                        <PageTitle title={"Profile"} />
                    </Col>
                    <Col md={24} xl={12} className="text-end">
                        <Button size='small' type='primary' onClick={() => setVisible(true)}>Change password</Button>
                    </Col>
                </Row>
                <Card style={{ height: "100%", position: "relative" }} className="shadow-sm rounded mt-2">
                    <Form form={form} layout="vertical" >
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane key="1" tab={<span className={styles.profile_info_text_key} >Shaxsiy ma'lumotlar</span>}>
                                <Row gutter={[12, 0]}>
                                    <Col lg={{ span: 3 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item
                                            label={<FormLabelCrud>Image</FormLabelCrud>}
                                            name="avatar"
                                        >
                                            <Image
                                                width={150}
                                                src={data?.profile?.image ? FILE_URL + data?.profile?.image : Boolean(data?.profile?.gender) ? user_male : user_female}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 7 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Student first name</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.first_name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 7 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Student last name</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.last_name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 7 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Student middle name</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.middle_name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Citizenship</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.citizenship?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Nationality</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.nationality?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Date of birth</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.birthday} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Gender</FormLabelCrud>}>
                                            <Radio.Group disabled={true} value={String(data?.profile?.gender)}>
                                                <Radio value="1" >{t("Male")}</Radio>
                                                <Radio value="0" >{t("Female")}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Main phone number</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.phone} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Second phone number</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.phone_secondary} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Passport seria and number</FormLabelCrud>}>
                                            <Input disabled={true} value={`${data?.profile?.passport_seria} ${data?.profile?.passport_number}`} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col >
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Passport JSHIR</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.passport_pin} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Passport amal qilish muddati</FormLabelCrud>}>
                                            <Input disabled={true} value={`${data?.profile?.passport_given_date} - ${data?.profile?.passport_issued_date}`} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Passport given by</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.passport_given_by} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Passport file</FormLabelCrud>} className="w-100">
                                            {
                                                data?.profile?.passport_file ?
                                                    <a href={data?.profile?.passport_file} target="_blank">Passport faylini ko'rish</a> :
                                                    <span style={{ color: 'red' }}>Passport fayli yuklanmagan</span>
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Status</FormLabelCrud>} className="w-100">
                                            <Input disabled={true} value={data?.status === 10 ? "Active" : data?.status === 0 ? "Banned" : "Pending"} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col lg={{ span: 12 }} md={{ span: 16 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={<FormLabelCrud>Description</FormLabelCrud>}
                                name="description"
                                rules={[{ required: false }]}
                            >
                                <Input.TextArea rows={2} placeholder={t("enter additional information about student") + " ..."} />
                            </Form.Item>
                        </Col> */}
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Old educational institution name</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Diplom seria</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.diplom_seria} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Diplom number</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.diplom_number} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Diplom file</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.diplom_file} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Status of military service</FormLabelCrud>}>
                                            <Checkbox checked={isMilitary} disabled={true} ></Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {
                                        isMilitary ? <>
                                            <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                <Form.Item label={<FormLabelCrud>Place of military service</FormLabelCrud>}>
                                                    <Input disabled={true} value={data?.diplom_file} className={styles.ant_input_custom} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                <Form.Item label={<FormLabelCrud>Date of military service</FormLabelCrud>}>
                                                    <Input disabled={true} value={data?.diplom_file} className={styles.ant_input_custom} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                <Form.Item label={<FormLabelCrud>Military service certificate series and number</FormLabelCrud>}>
                                                    <Input disabled={true} value={data?.diplom_file} className={styles.ant_input_custom} />
                                                </Form.Item>
                                            </Col>
                                        </> : null
                                    }
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Email</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.email} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                </Row >
                            </TabPane >
                            <TabPane key="2" tab={<span className={styles.profile_info_text_key} >Education details</span>}>
                                <Row gutter={[12, 0]}>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ta'lim turi</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.eduType?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ta'lim shakli</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.eduForm?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Fakultet</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.faculty?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Yo'nalish</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.direction?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ta'lim yili</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.eduYear?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Kurs</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.course?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ta'lim rejasi</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.eduPlan?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ta'lim tili</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.eduYear?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>To'lov shakli</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.first_name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ta'lim toifasi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane key="3" tab={<span className={styles.profile_info_text_key} >Address details</span>}>
                                <Row gutter={[12, 0]}>
                                    <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ backgroundColor: "#fafafa" }} className="pt-2 mb-2">
                                        <span className='info_title_text d-block ps-1'>{t("Permanent address")}</span>
                                        <Divider className='mt-1 mb-0'></Divider>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Country</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.country?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Region</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.region?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Area</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.area?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Address</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.address} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ backgroundColor: "#fafafa" }} className="pt-2 mb-2">
                                        <span className='info_title_text d-block ps-1'>{t("Current address")}</span>
                                        <Divider className='mt-1 mb-0'></Divider>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Country</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.permanentCountry?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Region</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.permanentRegion?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Area</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.permanentArea?.name} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Address</FormLabelCrud>}>
                                            <Input disabled={true} value={data?.profile?.permanent_address} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Birga yashaydiganlar soni</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Birga yashaydiganlar toifasi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Yashash joyi statusi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Yashash joyi geolokatsiyasi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane key="4" tab={<span className={styles.profile_info_text_key} >Qo'shimcha ma'lumotlari</span>}>
                                <Row gutter={[12, 0]}>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ijtimoiy toifa</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Ishlaydi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={24} xs={24} >
                                        <Divider orientation='left' className={styles.line_title}>Sport sertifikatlari</Divider>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Sport sertifikati nomi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Sport sertifikati vaqti</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Sport sertifikati joyi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Sport sertifikati fayli</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={24} xs={24} >
                                        <Divider orientation='left' className={styles.line_title}>Olimpiyada sertifikatlari</Divider>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Olimpiyada sertifikati nomi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Olimpiyada sertifikati vaqti</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Olimpiyada sertifikati joyi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Olimpiyada sertifikati fayli</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={24} xs={24} >
                                        <Divider orientation='left' className={styles.line_title}>Boshqa sertifikatlari</Divider>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Boshqa sertifikati nomi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Boshqa sertifikati vaqti</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Boshqa sertifikati joyi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Boshqa sertifikati fayli</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane key="5" tab={<span className={styles.profile_info_text_key} >Parent information</span>}>
                                <Row gutter={12}>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Father F.I.O</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Father phone number</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Father info</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Mather F.I.O</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Mather phone number</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Mather info</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane key="6" tab={<span className={styles.profile_info_text_key} >Student documents</span>}>
                                <Row gutter={12}>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Hujjat nomi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Form.Item label={<FormLabelCrud>Jujjat tavsifi</FormLabelCrud>}>
                                            <Input disabled={true} className={styles.ant_input_custom} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs >
                    </Form>
                    <Divider />
                    <Col span={24} className="mt-2 text-end">
                        {/* <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }} icon={<FaUndo className="me-1" />}>{t("Reset")}</Button>
                    <Button type="primary" danger htmlType="button" className="me-2">{t("Cancel")}</Button>
                    {Number(activeTabKey) > 1 && <Button type="primary" htmlType="button" className="me-2" disabled={clicked}>{t("Prev")}</Button>}
                    <Button type="primary" style={{ display: Number(activeTabKey) < 6 ? "" : "none" }} htmlType="button" disabled={clicked} onClick={() => { Number(activeTabKey) <= 3 && setActiveTabKey(String(Number(activeTabKey) + 1)) }}>{t("Next")}</Button>
                    <Button type="primary" style={{ display: Number(activeTabKey) < 6 ? "none" : "" }}
                        htmlType={errorTabs?.length > 0 ? "button" : "submit"} onClick={() => catchErrors(undefined)} disabled={clicked}>{t("Submit")}</Button> */}
                    </Col>
                    <div className="text-end" >
                        {/* <Button type="primary" onClick={() => setVisible(true)} className="me-2" ><span style={{ color: "#fff" }} >Parolni o'zgartirish</span></Button> */}
                    </div>
                </Card>
        </Card>
    )
}

export default Profile;