import { useEffect, useState } from "react"
import "./common/style.scss";
import { SelectStudentTheme } from "config/theme/colors";
import React from "react";
import { FC } from "react";
import { Button, Col, Divider, message, Popconfirm, Row, Spin, Typography } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { IStudentTimeTable, ITimeOption, ITimeTable, IWeek } from "models/others";
import { IEducationSemester, IEducationSemesterSubject, IPara } from "models/education";
import useDeleteOne from "hooks/useDeleteOne";
import styles from './styles.module.scss';
import { NavLink } from "react-router-dom";
import { SelectSemesterBtn } from "pages/subjects/common/styled";
import { BiArrowFromLeft, BiX } from "react-icons/bi";
import isHasAuthRoute from "utils/isHasRoute";
import instance from "config/_axios";
import { Card } from "antd";
import { Title } from "pages/styles/style";
import { EmptyData } from "components/EmptyTable";


const { Text } = Typography;

const StudentLessonScheduleVariant: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const [eduSemesters, setEduSemesters] = useState<IEducationSemester[]>([]);
    const [eduSemester, setEduSemester] = useState<IEducationSemester>();
    const [subjectIds, setSubjectIds] = useState<number[]>([]);
    const para = useGetAllData({ url: `/paras`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IPara>;
    /*{
            "items": [
                {
                    "id": 1,
                    "name": "1",
                    "start_time": "08:30",
                    "end_time": "09:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1642997338,
                    "updated_at": 1651036105,
                    "created_by": 1,
                    "updated_by": 4
                },
                {
                    "id": 2,
                    "name": "2",
                    "start_time": "09:30",
                    "end_time": "10:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1642997345,
                    "updated_at": 1662457732,
                    "created_by": 1,
                    "updated_by": 11
                },
                {
                    "id": 3,
                    "name": "3",
                    "start_time": "10:30",
                    "end_time": "11:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1642997350,
                    "updated_at": 1662457753,
                    "created_by": 1,
                    "updated_by": 11
                },
                {
                    "id": 4,
                    "name": "4",
                    "start_time": "11:30",
                    "end_time": "12:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1642997355,
                    "updated_at": 1662457779,
                    "created_by": 1,
                    "updated_by": 11
                },
                {
                    "id": 5,
                    "name": "5",
                    "start_time": "12:30",
                    "end_time": "13:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1642997360,
                    "updated_at": 1662457792,
                    "created_by": 1,
                    "updated_by": 11
                },
                {
                    "id": 6,
                    "name": "6",
                    "start_time": "13:30",
                    "end_time": "14:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1642997365,
                    "updated_at": 1662457811,
                    "created_by": 1,
                    "updated_by": 11
                },
                {
                    "id": 7,
                    "name": "7",
                    "start_time": "14:30",
                    "end_time": "15:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1651036184,
                    "updated_at": 1662457825,
                    "created_by": 4,
                    "updated_by": 11
                },
                {
                    "id": 8,
                    "name": "8",
                    "start_time": "15:30",
                    "end_time": "16:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1651058786,
                    "updated_at": 1662457841,
                    "created_by": 112,
                    "updated_by": 11
                },
                {
                    "id": 9,
                    "name": "9",
                    "start_time": "16:30",
                    "end_time": "17:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1652767491,
                    "updated_at": 1662457866,
                    "created_by": 2,
                    "updated_by": 11
                },
                {
                    "id": 11,
                    "name": "10",
                    "start_time": "17:30",
                    "end_time": "18:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1662457928,
                    "updated_at": 1662457928,
                    "created_by": 11,
                    "updated_by": 0
                },
                {
                    "id": 12,
                    "name": "11",
                    "start_time": "18:30",
                    "end_time": "19:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1662458012,
                    "updated_at": 1662458012,
                    "created_by": 11,
                    "updated_by": 0
                },
                {
                    "id": 13,
                    "name": "12",
                    "start_time": "19:30",
                    "end_time": "20:30",
                    "order": 1,
                    "status": 1,
                    "created_at": 1662458050,
                    "updated_at": 1662458050,
                    "created_by": 11,
                    "updated_by": 0
                }
            ],
            "_links": {
                "self": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/paras?per-page=0&page=1"
                },
                "first": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/paras?per-page=0&page=1"
                },
                "last": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/paras?per-page=0&page=1"
                }
            },
            "_meta": {
                "totalCount": 12,
                "pageCount": 1,
                "currentPage": 1,
                "perPage": 0
            }
    }*/
    const weeks = useGetAllData({ url: `/weeks`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IWeek>;
    const edu_semesters = useGetAllData({ url: `/edu-semestrs?expand=semestr`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemester>
    /*{
            "items": [
                {
                    "id": 325,
                    "name": "2019-2020-1-1",
                    "edu_plan_id": 56,
                    "course_id": 1,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 1,
                    "credit": 0,
                    "edu_year_id": 13,
                    "start_date": "2019-09-04 00:00:00",
                    "end_date": "2019-09-04 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 0,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 1,
                        "name": "1",
                        "order": 1,
                        "status": 1,
                        "type": 1,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                {
                    "id": 326,
                    "name": "2019-2020-1-2",
                    "edu_plan_id": 56,
                    "course_id": 1,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 2,
                    "credit": 0,
                    "edu_year_id": 13,
                    "start_date": "2020-02-03 00:00:00",
                    "end_date": "2020-06-26 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 0,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 2,
                        "name": "2",
                        "order": 1,
                        "status": 1,
                        "type": 2,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                {
                    "id": 327,
                    "name": "2020-2021-2-3",
                    "edu_plan_id": 56,
                    "course_id": 2,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 3,
                    "credit": 0,
                    "edu_year_id": 14,
                    "start_date": "2020-09-04 00:00:00",
                    "end_date": "2020-09-04 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 0,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 3,
                        "name": "3",
                        "order": 1,
                        "status": 1,
                        "type": 1,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                {
                    "id": 328,
                    "name": "2020-2021-2-4",
                    "edu_plan_id": 56,
                    "course_id": 2,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 4,
                    "credit": 0,
                    "edu_year_id": 14,
                    "start_date": "2021-02-03 00:00:00",
                    "end_date": "2021-06-26 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 0,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 4,
                        "name": "4",
                        "order": 1,
                        "status": 1,
                        "type": 2,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                {
                    "id": 329,
                    "name": "2021-2022-3-5",
                    "edu_plan_id": 56,
                    "course_id": 3,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 5,
                    "credit": 0,
                    "edu_year_id": 15,
                    "start_date": "2021-09-04 00:00:00",
                    "end_date": "2021-09-04 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 0,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 5,
                        "name": "5",
                        "order": 1,
                        "status": 1,
                        "type": 1,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                {
                    "id": 330,
                    "name": "2021-2022-3-6",
                    "edu_plan_id": 56,
                    "course_id": 3,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 6,
                    "credit": 0,
                    "edu_year_id": 15,
                    "start_date": "2022-02-03 00:00:00",
                    "end_date": "2022-06-26 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 0,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 6,
                        "name": "6",
                        "order": 1,
                        "status": 1,
                        "type": 2,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                {
                    "id": 331,
                    "name": "2022-2023-4-7",
                    "edu_plan_id": 56,
                    "course_id": 4,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 7,
                    "credit": 0,
                    "edu_year_id": 16,
                    "start_date": "2022-09-04 00:00:00",
                    "end_date": "2023-01-31 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 1,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 7,
                        "name": "7",
                        "order": 1,
                        "status": 1,
                        "type": 1,
                        "created_at": 0,
                        "updated_at": 0,
                        "created_by": 0,
                        "updated_by": 0
                    }
                },
                {
                    "id": 332,
                    "name": "2022-2023-4-8",
                    "edu_plan_id": 56,
                    "course_id": 4,
                    "optional_subject_count": 0,
                    "required_subject_count": 0,
                    "semestr_id": 8,
                    "credit": 0,
                    "edu_year_id": 16,
                    "start_date": "2023-02-03 00:00:00",
                    "end_date": "2023-06-26 00:00:00",
                    "is_checked": 1,
                    "order": 1,
                    "status": 0,
                    "created_at": 1652782323,
                    "updated_at": 1667541873,
                    "created_by": 599,
                    "updated_by": 1,
                    "semestr": {
                        "id": 8,
                        "name": "8",
                        "order": 1,
                        "status": 1,
                        "type": 2,
                        "created_at": 0,
                        "updated_at": 1657791399,
                        "created_by": 0,
                        "updated_by": 112
                    }
                }
            ],
            "_links": {
                "self": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/edu-semestrs?expand=semestr&per-page=0&page=1"
                },
                "first": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/edu-semestrs?expand=semestr&per-page=0&page=1"
                },
                "last": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/edu-semestrs?expand=semestr&per-page=0&page=1"
                }
            },
            "_meta": {
                "totalCount": 8,
                "pageCount": 1,
                "currentPage": 1,
                "perPage": 0
            }
    }*/
    const subjects = useGetAllData({ url: `/edu-semestr-subjects?expand=subject&filter=${JSON.stringify({ edu_semestr_id: eduSemester?.id })}`, perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemesterSubject>;
    const timeOptions = useGetAllData({ url: `/time-options?expand=selected,timeTables.seminar,timeTables.subject,timeTables.seminar.selected`, perPage: 0, isCall: "auto" }) as TypeReturnUseGetAllData<ITimeOption>;
    /*{
            "items": [
                {
                    "id": 151,
                    "name": "A(uz)",
                    "capacity": 73,
                    "key": "A",
                    "faculty_id": 2,
                    "edu_plan_id": 56,
                    "edu_year_id": 16,
                    "edu_semester_id": 331,
                    "language_id": 1,
                    "type": null,
                    "description": null,
                    "status": 1,
                    "created_at": 1663163186,
                    "updated_at": 1663339657,
                    "created_by": 1,
                    "updated_by": 11,
                    "timeTables": [
                        {
                            "id": 671,
                            "teacher_access_id": 1859,
                            "room_id": 69,
                            "para_id": 3,
                            "week_id": 3,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 151,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 286,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662547004,
                            "updated_at": 1663326382,
                            "created_by": 591,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 673,
                                    "teacher_access_id": 1859,
                                    "room_id": 62,
                                    "para_id": 3,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 671,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662547061,
                                    "updated_at": 1671429751,
                                    "created_by": 591,
                                    "updated_by": 7401,
                                    "selected": 0
                                },
                                {
                                    "id": 675,
                                    "teacher_access_id": 1995,
                                    "room_id": 70,
                                    "para_id": 3,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 671,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662547104,
                                    "updated_at": 1671199158,
                                    "created_by": 591,
                                    "updated_by": 7401,
                                    "selected": 0
                                },
                                {
                                    "id": 2790,
                                    "teacher_access_id": 2086,
                                    "room_id": 72,
                                    "para_id": 3,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 671,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662819513,
                                    "updated_at": 1662819513,
                                    "created_by": 1,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 286,
                                "name": "Mahalliy hokimiyat huquqi",
                                "kafedra_id": 15,
                                "semestr_id": 7,
                                "parent_id": 286,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1660303924,
                                "updated_at": 1660303939,
                                "created_by": 591,
                                "updated_by": 591
                            }
                        },
                        {
                            "id": 744,
                            "teacher_access_id": 2549,
                            "room_id": 59,
                            "para_id": 1,
                            "week_id": 3,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 151,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 300,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662549394,
                            "updated_at": 1663325636,
                            "created_by": 596,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 749,
                                    "teacher_access_id": 2549,
                                    "room_id": 62,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 744,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662549462,
                                    "updated_at": 1662549462,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 751,
                                    "teacher_access_id": 2673,
                                    "room_id": 70,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 744,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662549540,
                                    "updated_at": 1662549540,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 753,
                                    "teacher_access_id": 2674,
                                    "room_id": 72,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 744,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662549603,
                                    "updated_at": 1662549603,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 300,
                                "name": "Yuridik texnika",
                                "kafedra_id": 1,
                                "semestr_id": 1,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1661259600,
                                "updated_at": 1661259600,
                                "created_by": 596,
                                "updated_by": 0
                            }
                        },
                        {
                            "id": 795,
                            "teacher_access_id": 2402,
                            "room_id": 69,
                            "para_id": 1,
                            "week_id": 4,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 151,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 309,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662550422,
                            "updated_at": 1663326281,
                            "created_by": 591,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 798,
                                    "teacher_access_id": 2402,
                                    "room_id": 62,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 795,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662550494,
                                    "updated_at": 1662550494,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 804,
                                    "teacher_access_id": 2401,
                                    "room_id": 70,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 795,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662550547,
                                    "updated_at": 1662550547,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 833,
                                    "teacher_access_id": 2842,
                                    "room_id": 72,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 795,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662550970,
                                    "updated_at": 1662550970,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 309,
                                "name": "Nodavlat notijorat tashkilotlar huquqi",
                                "kafedra_id": 15,
                                "semestr_id": 7,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1661523979,
                                "updated_at": 1661670599,
                                "created_by": 591,
                                "updated_by": 591
                            }
                        },
                        {
                            "id": 3135,
                            "teacher_access_id": 3125,
                            "room_id": 69,
                            "para_id": 1,
                            "week_id": 5,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 151,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 357,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662871792,
                            "updated_at": 1663326909,
                            "created_by": 10,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 3137,
                                    "teacher_access_id": 3125,
                                    "room_id": 62,
                                    "para_id": 3,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3135,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 357,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662871863,
                                    "updated_at": 1662871863,
                                    "created_by": 10,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 3139,
                                    "teacher_access_id": 3121,
                                    "room_id": 70,
                                    "para_id": 3,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3135,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 357,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662871905,
                                    "updated_at": 1662871905,
                                    "created_by": 10,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 3141,
                                    "teacher_access_id": 3124,
                                    "room_id": 72,
                                    "para_id": 3,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3135,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 357,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662871947,
                                    "updated_at": 1662871947,
                                    "created_by": 10,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 357,
                                "name": "Yuridik xizmat",
                                "kafedra_id": 6,
                                "semestr_id": 7,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1662698284,
                                "updated_at": 1662871153,
                                "created_by": 7401,
                                "updated_by": 599
                            }
                        }
                    ],
                    "selected": 0
                },
                {
                    "id": 152,
                    "name": "B(uz)",
                    "capacity": 77,
                    "key": "B",
                    "faculty_id": 2,
                    "edu_plan_id": 56,
                    "edu_year_id": 16,
                    "edu_semester_id": 331,
                    "language_id": 1,
                    "type": null,
                    "description": null,
                    "status": 1,
                    "created_at": 1663163186,
                    "updated_at": 1663855674,
                    "created_by": 1,
                    "updated_by": 11,
                    "timeTables": [
                        {
                            "id": 759,
                            "teacher_access_id": 3518,
                            "room_id": 67,
                            "para_id": 1,
                            "week_id": 4,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 152,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 300,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662549716,
                            "updated_at": 1668667605,
                            "created_by": 596,
                            "updated_by": 7401,
                            "seminar": [
                                {
                                    "id": 763,
                                    "teacher_access_id": 3518,
                                    "room_id": 55,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 759,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662549795,
                                    "updated_at": 1668667632,
                                    "created_by": 596,
                                    "updated_by": 7401,
                                    "selected": 0
                                },
                                {
                                    "id": 767,
                                    "teacher_access_id": 2674,
                                    "room_id": 56,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 759,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662549864,
                                    "updated_at": 1662549864,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 771,
                                    "teacher_access_id": 2675,
                                    "room_id": 57,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 759,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662549926,
                                    "updated_at": 1662549926,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 300,
                                "name": "Yuridik texnika",
                                "kafedra_id": 1,
                                "semestr_id": 1,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1661259600,
                                "updated_at": 1661259600,
                                "created_by": 596,
                                "updated_by": 0
                            }
                        },
                        {
                            "id": 856,
                            "teacher_access_id": 3046,
                            "room_id": 67,
                            "para_id": 1,
                            "week_id": 5,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 152,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 309,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662551605,
                            "updated_at": 1663326271,
                            "created_by": 591,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 859,
                                    "teacher_access_id": 3046,
                                    "room_id": 55,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 856,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662551654,
                                    "updated_at": 1662653798,
                                    "created_by": 591,
                                    "updated_by": 591,
                                    "selected": 0
                                },
                                {
                                    "id": 861,
                                    "teacher_access_id": 2401,
                                    "room_id": 56,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 856,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662551702,
                                    "updated_at": 1662821584,
                                    "created_by": 591,
                                    "updated_by": 591,
                                    "selected": 0
                                },
                                {
                                    "id": 2310,
                                    "teacher_access_id": 2399,
                                    "room_id": 57,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 856,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662654953,
                                    "updated_at": 1662654953,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 309,
                                "name": "Nodavlat notijorat tashkilotlar huquqi",
                                "kafedra_id": 15,
                                "semestr_id": 7,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1661523979,
                                "updated_at": 1661670599,
                                "created_by": 591,
                                "updated_by": 591
                            }
                        },
                        {
                            "id": 869,
                            "teacher_access_id": 2047,
                            "room_id": 67,
                            "para_id": 3,
                            "week_id": 4,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 152,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 286,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662551906,
                            "updated_at": 1663326377,
                            "created_by": 591,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 1076,
                                    "teacher_access_id": 2072,
                                    "room_id": 65,
                                    "para_id": 3,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 869,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662556449,
                                    "updated_at": 1662556449,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 1080,
                                    "teacher_access_id": 2007,
                                    "room_id": 68,
                                    "para_id": 3,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 869,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662556499,
                                    "updated_at": 1662556499,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 1233,
                                    "teacher_access_id": 2896,
                                    "room_id": 94,
                                    "para_id": 3,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 869,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662562139,
                                    "updated_at": 1662562139,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 286,
                                "name": "Mahalliy hokimiyat huquqi",
                                "kafedra_id": 15,
                                "semestr_id": 7,
                                "parent_id": 286,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1660303924,
                                "updated_at": 1660303939,
                                "created_by": 591,
                                "updated_by": 591
                            }
                        },
                        {
                            "id": 3143,
                            "teacher_access_id": 3121,
                            "room_id": 67,
                            "para_id": 1,
                            "week_id": 3,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 152,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 357,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662872020,
                            "updated_at": 1663326527,
                            "created_by": 10,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 3147,
                                    "teacher_access_id": 3266,
                                    "room_id": 65,
                                    "para_id": 3,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3143,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 357,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662872157,
                                    "updated_at": 1663326666,
                                    "created_by": 10,
                                    "updated_by": 10,
                                    "selected": 0
                                },
                                {
                                    "id": 3149,
                                    "teacher_access_id": 3127,
                                    "room_id": 68,
                                    "para_id": 3,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3143,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 357,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662872247,
                                    "updated_at": 1662872247,
                                    "created_by": 10,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 3697,
                                    "teacher_access_id": 3121,
                                    "room_id": 69,
                                    "para_id": 3,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3143,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 357,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1663330039,
                                    "updated_at": 1663330039,
                                    "created_by": 10,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 357,
                                "name": "Yuridik xizmat",
                                "kafedra_id": 6,
                                "semestr_id": 7,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1662698284,
                                "updated_at": 1662871153,
                                "created_by": 7401,
                                "updated_by": 599
                            }
                        }
                    ],
                    "selected": 0
                },
                {
                    "id": 153,
                    "name": "C(uz)",
                    "capacity": 55,
                    "key": "C",
                    "faculty_id": 2,
                    "edu_plan_id": 56,
                    "edu_year_id": 16,
                    "edu_semester_id": 331,
                    "language_id": 1,
                    "type": null,
                    "description": null,
                    "status": 1,
                    "created_at": 1663163186,
                    "updated_at": 1663340839,
                    "created_by": 1,
                    "updated_by": 11,
                    "timeTables": [
                        {
                            "id": 776,
                            "teacher_access_id": 2549,
                            "room_id": 59,
                            "para_id": 1,
                            "week_id": 5,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 300,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662550020,
                            "updated_at": 1663325597,
                            "created_by": 596,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 786,
                                    "teacher_access_id": 2549,
                                    "room_id": 55,
                                    "para_id": 3,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 776,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662550142,
                                    "updated_at": 1662550142,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "selected": 0
                                },
                                {
                                    "id": 1427,
                                    "teacher_access_id": 2673,
                                    "room_id": 56,
                                    "para_id": 3,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 776,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662616632,
                                    "updated_at": 1662616632,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 300,
                                "name": "Yuridik texnika",
                                "kafedra_id": 1,
                                "semestr_id": 1,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1661259600,
                                "updated_at": 1661259600,
                                "created_by": 596,
                                "updated_by": 0
                            }
                        },
                        {
                            "id": 1093,
                            "teacher_access_id": 2842,
                            "room_id": 59,
                            "para_id": 3,
                            "week_id": 4,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 309,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662556656,
                            "updated_at": 1669186033,
                            "created_by": 591,
                            "updated_by": 7401,
                            "seminar": [
                                {
                                    "id": 1101,
                                    "teacher_access_id": 2894,
                                    "room_id": 94,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 1093,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662556735,
                                    "updated_at": 1662821514,
                                    "created_by": 591,
                                    "updated_by": 591,
                                    "selected": 0
                                },
                                {
                                    "id": 2812,
                                    "teacher_access_id": 2872,
                                    "room_id": 64,
                                    "para_id": 1,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 1093,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662821672,
                                    "updated_at": 1666681085,
                                    "created_by": 591,
                                    "updated_by": 11,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 309,
                                "name": "Nodavlat notijorat tashkilotlar huquqi",
                                "kafedra_id": 15,
                                "semestr_id": 7,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1661523979,
                                "updated_at": 1661670599,
                                "created_by": 591,
                                "updated_by": 591
                            }
                        },
                        {
                            "id": 1148,
                            "teacher_access_id": 2047,
                            "room_id": 59,
                            "para_id": 3,
                            "week_id": 5,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 286,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662558400,
                            "updated_at": 1663326365,
                            "created_by": 591,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 1153,
                                    "teacher_access_id": 2047,
                                    "room_id": 94,
                                    "para_id": 1,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 1148,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662558535,
                                    "updated_at": 1662823620,
                                    "created_by": 591,
                                    "updated_by": 591,
                                    "selected": 0
                                },
                                {
                                    "id": 1156,
                                    "teacher_access_id": 2007,
                                    "room_id": 64,
                                    "para_id": 1,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 1148,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662558606,
                                    "updated_at": 1662558606,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "selected": 0
                                }
                            ],
                            "subject": {
                                "id": 286,
                                "name": "Mahalliy hokimiyat huquqi",
                                "kafedra_id": 15,
                                "semestr_id": 7,
                                "parent_id": 286,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1660303924,
                                "updated_at": 1660303939,
                                "created_by": 591,
                                "updated_by": 591
                            }
                        },
                        {
                            "id": 3313,
                            "teacher_access_id": 1744,
                            "room_id": 59,
                            "para_id": 1,
                            "week_id": 4,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 209,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662991000,
                            "updated_at": 1663327087,
                            "created_by": 601,
                            "updated_by": 308,
                            "seminar": [
                                {
                                    "id": 3315,
                                    "teacher_access_id": 3439,
                                    "room_id": 94,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3313,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 209,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662991038,
                                    "updated_at": 1666683804,
                                    "created_by": 601,
                                    "updated_by": 7401,
                                    "selected": 0
                                },
                                {
                                    "id": 3319,
                                    "teacher_access_id": 2637,
                                    "room_id": 64,
                                    "para_id": 1,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": null,
                                    "lecture_id": 3313,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 209,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662991075,
                                    "updated_at": 1662991075,
                                    "created_by": 601,
                                    "updated_by": 0,
                                    "selected": 1
                                }
                            ],
                            "subject": {
                                "id": 209,
                                "name": "Xalqaro xususiy huquq",
                                "kafedra_id": 20,
                                "semestr_id": 5,
                                "parent_id": null,
                                "is_deleted": 0,
                                "order": 1,
                                "status": 1,
                                "created_at": 1655183474,
                                "updated_at": 1655183540,
                                "created_by": 601,
                                "updated_by": 601
                            }
                        }
                    ],
                    "selected": 1
                }
            ],
            "_links": {
                "self": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/time-options?expand=selected%2CtimeTables.seminar%2CtimeTables.subject%2CtimeTables.seminar.selected&semester_id=undefined&per-page=0&page=1"
                },
                "first": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/time-options?expand=selected%2CtimeTables.seminar%2CtimeTables.subject%2CtimeTables.seminar.selected&semester_id=undefined&per-page=0&page=1"
                },
                "last": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/time-options?expand=selected%2CtimeTables.seminar%2CtimeTables.subject%2CtimeTables.seminar.selected&semester_id=undefined&per-page=0&page=1"
                }
            },
            "_meta": {
                "totalCount": 3,
                "pageCount": 1,
                "currentPage": 1,
                "perPage": 0
            },
            fetch: () => {},
            loading: false
    }*/
    const [refetch, setRefetch] = React.useState<boolean>(false);
    const [notSelected, setNotSelected] = React.useState<ITimeTable[]>([]);
    const _delete = useDeleteOne();

    const timeTables =  useGetAllData({
        url: `/student-time-tables?sort=-id&expand=subject,teacher,subjectCategory,building,room,para,week,timeTable.language`,
        perPage: 0,
        refetch: [_delete.refetch, refetch]
    }) as TypeReturnUseGetAllData<IStudentTimeTable>;
    /*{
            "items": [
                {
                    "id": 143510,
                    "student_id": 5229,
                    "time_table_id": 3320,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674209707,
                    "updated_at": 1674209707,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 209,
                        "name": "Xalqaro xususiy huquq",
                        "kafedra_id": 20,
                        "semestr_id": 5,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1655183474,
                        "updated_at": 1655183540,
                        "created_by": 601,
                        "updated_by": 601
                    },
                    "teacher": {
                        "user_id": 4688,
                        "first_name": "AKMALJON",
                        "last_name": "AKRAMOV",
                        "middle_name": "ANVARJON O‘G‘LI"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 64,
                        "name": "210",
                        "building_id": 4,
                        "capacity": 32,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977841,
                        "updated_at": 1662203186,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 2,
                        "name": "Seshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644820316,
                        "updated_at": 1644851771,
                        "created_by": 112,
                        "updated_by": 112
                    },
                    "para": {
                        "id": 2,
                        "name": "2",
                        "start_time": "09:30",
                        "end_time": "10:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997345,
                        "updated_at": 1662457732,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "subjectCategory": {
                        "id": 2,
                        "name": "Semenar",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784526,
                        "updated_at": 1643784526,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 3320,
                        "teacher_access_id": 2637,
                        "room_id": 64,
                        "para_id": 2,
                        "week_id": 2,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": 3319,
                        "lecture_id": 3313,
                        "time_option_id": null,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 209,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 2,
                        "status": 1,
                        "created_at": 1662991087,
                        "updated_at": 1662991087,
                        "created_by": 601,
                        "updated_by": 0,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143509,
                    "student_id": 5229,
                    "time_table_id": 3319,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674209707,
                    "updated_at": 1674209707,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 209,
                        "name": "Xalqaro xususiy huquq",
                        "kafedra_id": 20,
                        "semestr_id": 5,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1655183474,
                        "updated_at": 1655183540,
                        "created_by": 601,
                        "updated_by": 601
                    },
                    "teacher": {
                        "user_id": 4688,
                        "first_name": "AKMALJON",
                        "last_name": "AKRAMOV",
                        "middle_name": "ANVARJON O‘G‘LI"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 64,
                        "name": "210",
                        "building_id": 4,
                        "capacity": 32,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977841,
                        "updated_at": 1662203186,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 2,
                        "name": "Seshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644820316,
                        "updated_at": 1644851771,
                        "created_by": 112,
                        "updated_by": 112
                    },
                    "para": {
                        "id": 1,
                        "name": "1",
                        "start_time": "08:30",
                        "end_time": "09:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997338,
                        "updated_at": 1651036105,
                        "created_by": 1,
                        "updated_by": 4
                    },
                    "subjectCategory": {
                        "id": 2,
                        "name": "Semenar",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784526,
                        "updated_at": 1643784526,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 3319,
                        "teacher_access_id": 2637,
                        "room_id": 64,
                        "para_id": 1,
                        "week_id": 2,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": 3313,
                        "time_option_id": null,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 209,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 2,
                        "status": 1,
                        "created_at": 1662991075,
                        "updated_at": 1662991075,
                        "created_by": 601,
                        "updated_by": 0,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143508,
                    "student_id": 5229,
                    "time_table_id": 3314,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 209,
                        "name": "Xalqaro xususiy huquq",
                        "kafedra_id": 20,
                        "semestr_id": 5,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1655183474,
                        "updated_at": 1655183540,
                        "created_by": 601,
                        "updated_by": 601
                    },
                    "teacher": {
                        "user_id": 601,
                        "first_name": "Odilbek",
                        "last_name": "Xazratqulov",
                        "middle_name": "Tursunovich"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 4,
                        "name": "Payshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852031,
                        "updated_at": 1659070110,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 2,
                        "name": "2",
                        "start_time": "09:30",
                        "end_time": "10:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997345,
                        "updated_at": 1662457732,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 3314,
                        "teacher_access_id": 1744,
                        "room_id": 59,
                        "para_id": 2,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": 3313,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 209,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662991017,
                        "updated_at": 1662991017,
                        "created_by": 601,
                        "updated_by": 0,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143507,
                    "student_id": 5229,
                    "time_table_id": 3313,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 209,
                        "name": "Xalqaro xususiy huquq",
                        "kafedra_id": 20,
                        "semestr_id": 5,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1655183474,
                        "updated_at": 1655183540,
                        "created_by": 601,
                        "updated_by": 601
                    },
                    "teacher": {
                        "user_id": 601,
                        "first_name": "Odilbek",
                        "last_name": "Xazratqulov",
                        "middle_name": "Tursunovich"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 4,
                        "name": "Payshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852031,
                        "updated_at": 1659070110,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 1,
                        "name": "1",
                        "start_time": "08:30",
                        "end_time": "09:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997338,
                        "updated_at": 1651036105,
                        "created_by": 1,
                        "updated_by": 4
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 3313,
                        "teacher_access_id": 1744,
                        "room_id": 59,
                        "para_id": 1,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 209,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662991000,
                        "updated_at": 1663327087,
                        "created_by": 601,
                        "updated_by": 308,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143506,
                    "student_id": 5229,
                    "time_table_id": 1149,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 286,
                        "name": "Mahalliy hokimiyat huquqi",
                        "kafedra_id": 15,
                        "semestr_id": 7,
                        "parent_id": 286,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1660303924,
                        "updated_at": 1660303939,
                        "created_by": 591,
                        "updated_by": 591
                    },
                    "teacher": {
                        "user_id": 4556,
                        "first_name": "ESHMUXAMAD",
                        "last_name": "KADIROV",
                        "middle_name": "OMANTURDIYEVICH"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 5,
                        "name": "Juma",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852242,
                        "updated_at": 1659070142,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 4,
                        "name": "4",
                        "start_time": "11:30",
                        "end_time": "12:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997355,
                        "updated_at": 1662457779,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 1149,
                        "teacher_access_id": 2047,
                        "room_id": 59,
                        "para_id": 4,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": 1148,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 286,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662558417,
                        "updated_at": 1662558417,
                        "created_by": 591,
                        "updated_by": 0,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143505,
                    "student_id": 5229,
                    "time_table_id": 1148,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 286,
                        "name": "Mahalliy hokimiyat huquqi",
                        "kafedra_id": 15,
                        "semestr_id": 7,
                        "parent_id": 286,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1660303924,
                        "updated_at": 1660303939,
                        "created_by": 591,
                        "updated_by": 591
                    },
                    "teacher": {
                        "user_id": 4556,
                        "first_name": "ESHMUXAMAD",
                        "last_name": "KADIROV",
                        "middle_name": "OMANTURDIYEVICH"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 5,
                        "name": "Juma",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852242,
                        "updated_at": 1659070142,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 3,
                        "name": "3",
                        "start_time": "10:30",
                        "end_time": "11:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997350,
                        "updated_at": 1662457753,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 1148,
                        "teacher_access_id": 2047,
                        "room_id": 59,
                        "para_id": 3,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 286,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662558400,
                        "updated_at": 1663326365,
                        "created_by": 591,
                        "updated_by": 308,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143504,
                    "student_id": 5229,
                    "time_table_id": 1095,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 309,
                        "name": "Nodavlat notijorat tashkilotlar huquqi",
                        "kafedra_id": 15,
                        "semestr_id": 7,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1661523979,
                        "updated_at": 1661670599,
                        "created_by": 591,
                        "updated_by": 591
                    },
                    "teacher": {
                        "user_id": 4552,
                        "first_name": "JAVLONBEK",
                        "last_name": "BAXODIROV",
                        "middle_name": "BAXODIROVICH"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 4,
                        "name": "Payshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852031,
                        "updated_at": 1659070110,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 4,
                        "name": "4",
                        "start_time": "11:30",
                        "end_time": "12:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997355,
                        "updated_at": 1662457779,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 1095,
                        "teacher_access_id": 2842,
                        "room_id": 59,
                        "para_id": 4,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": 1093,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 309,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662556684,
                        "updated_at": 1669186038,
                        "created_by": 591,
                        "updated_by": 7401,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143503,
                    "student_id": 5229,
                    "time_table_id": 1093,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 309,
                        "name": "Nodavlat notijorat tashkilotlar huquqi",
                        "kafedra_id": 15,
                        "semestr_id": 7,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1661523979,
                        "updated_at": 1661670599,
                        "created_by": 591,
                        "updated_by": 591
                    },
                    "teacher": {
                        "user_id": 4552,
                        "first_name": "JAVLONBEK",
                        "last_name": "BAXODIROV",
                        "middle_name": "BAXODIROVICH"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 4,
                        "name": "Payshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852031,
                        "updated_at": 1659070110,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 3,
                        "name": "3",
                        "start_time": "10:30",
                        "end_time": "11:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997350,
                        "updated_at": 1662457753,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 1093,
                        "teacher_access_id": 2842,
                        "room_id": 59,
                        "para_id": 3,
                        "week_id": 4,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 309,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662556656,
                        "updated_at": 1669186033,
                        "created_by": 591,
                        "updated_by": 7401,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143502,
                    "student_id": 5229,
                    "time_table_id": 779,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 300,
                        "name": "Yuridik texnika",
                        "kafedra_id": 1,
                        "semestr_id": 1,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1661259600,
                        "updated_at": 1661259600,
                        "created_by": 596,
                        "updated_by": 0
                    },
                    "teacher": {
                        "user_id": 7462,
                        "first_name": "AKMAL",
                        "last_name": "SODIKOV",
                        "middle_name": "SHAVKAT O‘G‘LI"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 5,
                        "name": "Juma",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852242,
                        "updated_at": 1659070142,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 2,
                        "name": "2",
                        "start_time": "09:30",
                        "end_time": "10:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997345,
                        "updated_at": 1662457732,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 779,
                        "teacher_access_id": 2549,
                        "room_id": 59,
                        "para_id": 2,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": 776,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 300,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662550047,
                        "updated_at": 1662550047,
                        "created_by": 596,
                        "updated_by": 0,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                },
                {
                    "id": 143501,
                    "student_id": 5229,
                    "time_table_id": 776,
                    "order": 1,
                    "status": 1,
                    "created_at": 1674208866,
                    "updated_at": 1674208866,
                    "created_by": 6976,
                    "updated_by": 0,
                    "subject": {
                        "id": 300,
                        "name": "Yuridik texnika",
                        "kafedra_id": 1,
                        "semestr_id": 1,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1661259600,
                        "updated_at": 1661259600,
                        "created_by": 596,
                        "updated_by": 0
                    },
                    "teacher": {
                        "user_id": 7462,
                        "first_name": "AKMAL",
                        "last_name": "SODIKOV",
                        "middle_name": "SHAVKAT O‘G‘LI"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 5,
                        "name": "Juma",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852242,
                        "updated_at": 1659070142,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "para": {
                        "id": 1,
                        "name": "1",
                        "start_time": "08:30",
                        "end_time": "09:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997338,
                        "updated_at": 1651036105,
                        "created_by": 1,
                        "updated_by": 4
                    },
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "timeTable": {
                        "id": 776,
                        "teacher_access_id": 2549,
                        "room_id": 59,
                        "para_id": 1,
                        "week_id": 5,
                        "course_id": 4,
                        "semester_id": 7,
                        "parent_id": null,
                        "lecture_id": null,
                        "time_option_id": 153,
                        "edu_semester_id": 331,
                        "edu_year_id": 16,
                        "subject_id": 300,
                        "language_id": 1,
                        "order": 1,
                        "edu_plan_id": 56,
                        "building_id": 4,
                        "subject_category_id": 1,
                        "status": 1,
                        "created_at": 1662550020,
                        "updated_at": 1663325597,
                        "created_by": 596,
                        "updated_by": 308,
                        "language": {
                            "name": "O'zbekcha",
                            "lang_code": "uz"
                        }
                    }
                }
            ],
            "_links": {
                "self": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/student-time-tables?sort=-id&expand=subject%2Cteacher%2CsubjectCategory%2Cbuilding%2Croom%2Cpara%2Cweek%2CtimeTable.language&semester_id=undefined&per-page=0&page=1"
                },
                "first": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/student-time-tables?sort=-id&expand=subject%2Cteacher%2CsubjectCategory%2Cbuilding%2Croom%2Cpara%2Cweek%2CtimeTable.language&semester_id=undefined&per-page=0&page=1"
                },
                "last": {
                    "href": "https://api-digital.tsul.uz/api/web/uz/student-time-tables?sort=-id&expand=subject%2Cteacher%2CsubjectCategory%2Cbuilding%2Croom%2Cpara%2Cweek%2CtimeTable.language&semester_id=undefined&per-page=0&page=1"
                }
            },
            "_meta": {
                "totalCount": 10,
                "pageCount": 1,
                "currentPage": 1,
                "perPage": 0
            },
            fetch: () => {},
            loading: false
    }*/

    // useEffect(() => {
    //     if (edu_semesters.items.length) {
    //         const findActiveEduSemester = edu_semesters.items.find((element) => element.status === 1)

    //         setEduSemester(findActiveEduSemester);
    //         if (eduSemester) {
    //             timeTables.fetch();
    //         }
    //     }
    // }, [edu_semesters.items, eduSemester, _delete.refetch])


    useEffect(() => {
        (
            async () => {
                const response = await instance({ url: '/edu-semestrs', method: 'GET' })

                if (response.data.status === 1) {

                    const items = response.data?.data?.items as IEducationSemester[];
                    setEduSemesters(items ?? []);
                    const findActiveSemester = items?.find(element => element.status === 1);
                    setEduSemester(findActiveSemester)
                    timeTables.fetch();
                    timeOptions.fetch();
                }
            }
        )()
    }, [_delete.refetch])


    const filterTimeTable = React.useCallback((para_id: number, week_id: number) => {
        if (timeTables.items.length) {

            const filteredTimeTables = timeTables.items.filter(element => element?.week?.id === week_id && element?.para?.id === para_id);

            return filteredTimeTables;

        }
    }, [timeTables])


    React.useEffect(() => {

        if (timeOptions.items.length) {

            let new_object: ITimeTable[] = []

            const findSelected = timeOptions.items.find(element => Number(element.selected) === 1)

            if (findSelected) {

                const onlyLectureTimeTables = findSelected.timeTables?.filter(e => e.subject_category_id === 1 && !Boolean(e.parent_id))


                if (onlyLectureTimeTables?.length) {

                    onlyLectureTimeTables.forEach((element: any) => {

                        const isSelected = element.seminar.some((e: any) => Number(e.selected) === 1);

                        // console.log("is selected -> ", isSelected, element.seminar)

                        if (!isSelected) {
                            new_object.push(element);
                        }

                    })

                    setNotSelected(new_object);

                }

            } else (
                message.warning("Dars jadvali hali  tanlanmagan")
            )
        }

    }, [timeOptions.items.length])

    // console.log("new_object - > ", notSelected)

    const isSelectedTimeOption = () => {

        if (timeOptions.items.length) {

            const findElement = timeOptions.items.find(element => Number(element.selected) === 1);

            return findElement
        }

        return null;

    }
    

    return (
        <Card bordered={false}>
                <div className="not_user_select">
                    <div className="d-flex justify-content-between">
                        <Title color={theme.header_text} >Dars jadval</Title>
                        <div>
                            {edu_semesters.items.length ? <>Semestr:&nbsp;{edu_semesters.items.map((e, i) => {
                                return <SelectSemesterBtn key={i} theme={theme} style={eduSemester?.semestr_id === e.semestr_id ? { background: "#1890FF", color: "#fff" } : {}} onClick={() => { setEduSemester(e) }}>{e?.semestr?.name}</SelectSemesterBtn>
                            })}</> 
                            : null}
                        </div>
                    </div>
                    <hr />
                    <div>
                        {

                            notSelected.map((element) => {
                                return (
                                    <p>{element.subject?.name}</p>
                                )
                            })

                        }
                    </div>
                    <div className={styles.notice_time_table}>
                        <Row>
                            <Col xl={18} lg={18} md={16} sm={24} xs={24}>
                                {
                                    isSelectedTimeOption() ?
                                        <span>Siz tanlagan variant: {isSelectedTimeOption()?.name}</span> :
                                        <span>Dars jadval tanlang</span>
                                }
                            </Col>
                            <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                                <NavLink to={`/time_table_variants/${eduSemester?.id}`}><Button type="primary" className="w-100">Dars jadvalini tanlash &nbsp;<BiArrowFromLeft size={18} /> </Button></NavLink>
                            </Col>
                        </Row>
                    </div>
            <Spin spinning={timeTables.loading} >
                    <Row gutter={[24, 12]} className="mt-2 px-2" >
                        {(weeks.items.length || timeTables.items.length) ? weeks.items.map((weekItem, weekIndex) => {
                            if (!weekItem?.status) return null
                            return (
                                <Col key={weekIndex} xl={weekIndex === 4 ? 4 : 5} lg={weekIndex === 4 ? 4 : 5} md={12} sm={12} xs={24} style={{ border: "1px solid #F1F4F9" }} className="px-0" >
                                    {timeTables.items.length ? 
                                    <div className={styles.time_table_week_name}> {weekItem.name} </div> : null}
                                    <div className="px-1">
                                        {
                                            para.items.length ? para.items.map((paraItem) => {

                                                const _options = filterTimeTable(paraItem.id, weekItem.id);

                                                if (!_options?.length) return null;

                                                return (
                                                    <Card className="mx-1 my-2" title={
                                                        `${paraItem?.name}. ${paraItem?.start_time}-${paraItem?.end_time}`
                                                    }>
                                                        {
                                                            <span>
                                                                {
                                                                    _options?.map((timeTableItem) => {
                                                                        return (
                                                                            <span
                                                                                key={timeTableItem.id}
                                                                                className={styles.created_time_table_box}
                                                                            >
                                                                                <span className={styles.info_text_subject} ><span>{timeTableItem?.building?.name}&nbsp;&nbsp;{parseInt(timeTableItem?.room?.name ? timeTableItem?.room?.name : '')}-xona&nbsp;&nbsp;</span>{timeTableItem?.subject?.name}<br /><i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name} ({timeTableItem?.timeTable?.language?.name})</i></span><br />
                                                                                <span className={styles.info_text_teacher}>{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0)}.</span>
                                                                                {
                                                                                    isHasAuthRoute('student-time-table_delete') && !timeTableItem.timeTable?.parent_id ?
                                                                                        <Popconfirm
                                                                                            placement="topRight"
                                                                                            title={"O'chirilsinmi ?"}
                                                                                            onConfirm={() => _delete.fetch(`/student-time-tables/${timeTableItem.id}`)}
                                                                                            okText="Ha"
                                                                                            cancelText="Yo'q"
                                                                                        >
                                                                                            <button className={styles.remove_time_table} ><BiX size={16} /></button>
                                                                                        </Popconfirm>
                                                                                        : null
                                                                                }
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </span>
                                                        }
                                                    </Card>
                                                )
                                            }) : null
                                        }
                                    </div>
                                </Col>
                            )
                        }) : <EmptyData data={timeTables.items} loading={timeTables.loading}/>}
                    </Row>
            </Spin>
                </div>
        </Card>
    );
}


export default StudentLessonScheduleVariant;



/**
student-time-table_create
student-time-table_delete
student-time-table_index
student-time-table_view
 */