import { Button, Col, message, Row } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { SelectStudentTheme } from "config/theme/colors";
import moment from "moment";
import { Title, Wrapper } from "pages/styles/style";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Card } from "antd";
import { getAllExams } from "services";
import { examType } from "../types";
import Questionnaire from "./Questionnaire";
import StudentExamView from "./view";


const SubjectExams: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const history = useHistory()
    const url: string = useLocation().pathname;
    let a: Array<string> = url.split("/");
    const theme = SelectStudentTheme();
    const [exams, setExams] = useState<Array<examType>>([])
    const [exam, setExam] = useState<examType>()
    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "/std_exams",
        },
        {
            name: "Exams",
            path: url,
        }
    ];


    useEffect(() => {
        (
            async () => {
                const resp = await getAllExams(undefined, undefined, `filter={"edu_semestr_subject_id":${a[2]}}&expand=examType`);
                setExams(resp?.items)
            }
        )()
    }, [])

    useEffect(() => {
        if (a?.length > 3) {
            setExam(exams?.filter((item: examType) => item?.id === Number(a[a?.length - 1]))[0])
        }
    }, [exams, url])

    // console.log("sinov uchun imtihon"?.includes("intensiv"));


    return (
        <Card bordered={false}>
                <Title color="">{t('Imtihonlar')}</Title> 
                <CustomBreadcrumb arr={_breadcrump} />
                <Row className="p-2 px-0">
                    <Col span={24}>
                        <Wrapper theme={theme}>
                            <Table className="table hover">
                                <Thead>
                                    <Tr>
                                        <Th>{t("Name")}</Th>
                                        <Th>{t("Exam type")}</Th>
                                        <Th>{t("Start time")}</Th>
                                        <Th>{t("Dead line")}</Th>
                                        <Th>{t("Duration")}</Th>
                                        <Th>{t("Max ball")}</Th>
                                        <Th>{t("Natija")}</Th>
                                        <Th>{t('Amallar')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        exams?.map((item: examType, i: number) =>
                                            <Tr className="hover table-row-cursor-pointer" key={i}>
                                                <Td>{item?.name}</Td>
                                                <Td>{item?.examType?.name}</Td>
                                                <Td>{item?.start}</Td>
                                                <Td>{item?.finish}</Td>
                                                <Td>{item?.duration ? moment.utc(Number(item?.duration) * 1000).format("HH:mm") : null}</Td>
                                                <Td>{item?.max_ball}</Td>
                                                <Td>
                                                    {
                                                        (item?.status === 4 || item?.status === 3) ?
                                                        <Link to={`/student-exams/${a[2]}/exam-result/${item?.id}`}>
                                                            <Button type="primary">
                                                                <span style={{color: 'white'}}>
                                                                    {t("View")}
                                                                </span>
                                                            </Button>
                                                        </Link>
                                                        :
                                                        <Button onClick={() => {
                                                            if(item?.status === 0) message.warning('Imtihon faol emas')
                                                            else if(item?.status === 1) message.warning('Imtihon hali faol')
                                                        }}>
                                                            {t("View")}
                                                        </Button>
                                                    }
                                                </Td>
                                                <Td>
                                                    {
                                                        item?.status === 1 ?
                                                        <Link to={`/student-exams/${a[2]}/view/${item?.id}`}>
                                                            <Button type="primary">
                                                                <span style={{color: 'white'}}>
                                                                    {t('Kirish')}
                                                                </span>
                                                            </Button>
                                                        </Link>
                                                        :
                                                        <Button disabled onClick={() => {
                                                            if(item?.status === 0) message.warning('Imtihon faol emas')
                                                            else if(item?.status === 3) message.warning('Imtihon yakunlangan')
                                                            else if(item?.status === 4) message.warning('Imtihon taqsimlangan')
                                                        }}>
                                                            {t('Kirish')}
                                                        </Button>
                                                    }
                                                </Td>
                                            </Tr>
                                        )
                                    }
                                </Tbody>
                            </Table>
                        </Wrapper>
                    </Col>
                </Row>
        </Card>
    )
}

export default SubjectExams;