import { FC } from "react"
import { Spin } from "antd"
import { BiLoader } from 'react-icons/bi'
import { TableLoadingStyled } from "./style"


export const SpinnerLoading: FC<{ loading: boolean, color: string, size: number }> = ({ loading, color, size, children }) => {
  // if (!loading) {
  //     return null
  // }
  return (
    <>
      {
        loading ? <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
          <BiLoader className="icon-spin" color={color} size={size} />
        </div> : children
      }
    </>
  )
}

export const TableLoading: FC<{ height: number }> = ({ height }): JSX.Element => {

  return (
    <TableLoadingStyled height={height}>
      <Spin />
    </TableLoadingStyled>
  )
}