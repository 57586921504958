import { Button, Upload, Row, Col, Collapse, Divider, Tag, Form, message, Input, Modal, Card } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card as CardWrapper, CardBody as CardBodyWrapper } from "reactstrap";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import CustomBreadcrumb from "components/Breadcrumb";
import { match, useRouteMatch } from "react-router"
import "./style.scss"
import moment from "moment";
import { SpinnerLoading } from "components/Spinner";
import { FILE_URL } from "config/utils";
import { FaUpload } from "react-icons/fa";
import FormLabel from "components/FormLabel";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { IExamControl, IExamControlStudent } from "models/exam";
import OpenFileNewWindowButton from "components/openFileNewWindowButton";
import { FINISH_TIME } from "pages/subjects/pages/exercisse";
import FormLabelCrud from "components/FormLabel/FormLabelCrud";
import { IoClose } from "react-icons/io5";

type QuestionsType = {
    [key: string]: { question1: string, question2: string }
}

const ExamControl = () => {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const match: match<any> | null = useRouteMatch("/exam_control/:time_table_id/:question");
    const [examControl, setExamControl] = useState<IExamControl>();
    const [examControlStudent, setExamControlStudent] = useState<IExamControlStudent>();
    const [now, setNow] = useState<any>();
    const [appeal, setAppeal] = useState<string>();
    const [isAppeal, setIsAppeal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [click, setClick] = useState<boolean>(false);
    const [reFetch, setReFetch] = useState<boolean>(false);
    const [checkSize, setCheckSize] = useState<boolean>(false)

    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: `std_subjects`,
        },
        {
            name: "Topshiriqlar",
            path: `std_subject/subject_exercisses/${match?.params?.time_table_id}`,
        },
        {
            name: "Oraliq nazorat",
            path: "",
        }
    ];

    // const exam_control = useGetAllData({ url: `/exam-controls?expand=subject&filter=${JSON.stringify({ time_table_id: match?.params?.time_table_id })}`, isCall: 'auto' }) as TypeReturnUseGetAllData<IExamControl>;
    // const { data, loading } = useGetOneData({ url: `/time-tables/${match?.params?.time_table_id}?expand=examControl,examControl.examControlStudents`, isCall: 'auto' }) as ReturnTypeUseGetOneData<ITimeTable>;

    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(p => !p)
                    const responce = await instance({ url: `/time-tables/${match?.params?.time_table_id}?expand=examControl,examControl.examControlStudents,now` });
                    const exam_control = responce.data.data.examControl;
                    if (responce.data.status === 1) {
                        setExamControl(exam_control);
                        if (exam_control?.examControlStudent?.length) setExamControlStudent(exam_control?.examControlStudent[0])
                        setNow(responce.data.data?.now)

                        if (exam_control?.examControlStudents[0]?.answer)
                            saveAnswer(exam_control?.examControlStudents[0]?.answer, "question1")
                        if (exam_control?.examControlStudents[0]?.answer2)
                            saveAnswer(exam_control?.examControlStudents[0]?.answer2, "question2")
                    }

                    setLoading(p => !p);
                } catch (error) { setLoading(p => !p); }
            }
        )()
    }, [reFetch])

    const saveAnswer = (question: string, name: "question1" | "question2", onSubmit?: boolean) => {
        let data: QuestionsType = JSON.parse(localStorage.getItem("questions") || "{}")

        // if (firstLoad && !data[match?.params?.time_table_id]) {
        //     data = {
        //         ...data,
        //         [match?.params?.time_table_id]: {
        //             [name]: question
        //         }
        //     }
        // }

        if (onSubmit) {
            delete data[match?.params?.time_table_id][name]
        } else {
            data = {
                ...data,
                [match?.params?.time_table_id]: {
                    ...data[match?.params?.time_table_id],
                    [name]: question
                }
            }
        }

        localStorage.setItem("questions", JSON.stringify(data));
    }

    const writeAnswer = (name: "question1" | "question2") => {
        const data: QuestionsType = JSON.parse(localStorage.getItem("questions") || "{}")

        if (data[match?.params?.time_table_id] && data[match?.params?.time_table_id][name])
            return data[match?.params?.time_table_id][name];
        else return undefined
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    const checkFinished = (q: string): boolean => {
        if (q === "1" && now?.length) {
            return !!(now[0] > (examControl?.start ?? now[0] + 1) && now[0] < (examControl?.finish ?? 0))
        } else if (q === "2" && now?.length) {
            return !!(now[0] > (examControl?.start2 ?? now[0] + 1) && now[0] < (examControl?.finish2 ?? 0))
        } else return false
    }

    const checkFileSize = (e: any) => {
        if (e?.file?.size > 5242880 && e?.file?.status !== 'removed') {
            message.error(`Fayl hajmi 5 Mb dan oshmasligi kerak!!!`);
            setCheckSize(true);
        } else {
            setCheckSize(false);
        }
    }

    const onSubmit = async (values: any) => {
        try {

            if (!examControlStudent?.appeal_text && !examControlStudent?.appeal2_text || !examControlStudent.ball) {
                setClick(true);
                const formdata = new FormData();

                Object.entries(values).forEach(([key, value]: [key: string, value: any]) => {
                    if (value) {
                        if (key.startsWith("upload")) {
                            formdata.append(key, value?.file?.originFileObj);
                        } else {
                            formdata.append(key, value);
                        }
                    }
                })

                formdata.append("exam_control_id", `${examControl?.id}`)

                const respons = await instance({ url: `/exam-control-students${examControl?.examControlStudents?.length ? "/" + examControl?.examControlStudents[0].id : ""}`, data: formdata, method: examControl?.examControlStudents?.length ? "put" : "post" })

                if (respons.data.status === 1) {
                    asyncN("success", "create", "Ma'lumot saqlandi");
                    saveAnswer("", "question1", true)
                    saveAnswer("", "question2", true)
                    setReFetch(p => !p);
                    setClick(false);
                } else {
                    setClick(false);
                    message.error(respons.data?.message)
                }
            } else {
                message.warning("Bu imtihon uchun apelatsiya berilgan yoki baho qo'yilgan. Javob yuklashga ruxsat yo'q!");
            }
        } catch (err) {
            setClick(false);
        }
    }

    // const checkTime = (date: string, isTask: number) => {
    //     const _date = moment(Number(date) * 1000).format("YYYY-MM-DD")
    //     const isBefore = moment(_date).isBefore("2022-12-27")

    //     if (isBefore) {

    //       if (isTask === 1) {

    //         if (examControlStudent?.appeal) return false;

    //         if (examControlStudent?.ball) return false;

    //         return true;

    //       } else if (isTask === 2) {

    //         if (examControlStudent?.appeal2) return false;

    //         if (examControlStudent?.ball2) return false;

    //         return true;
    //       }


    //     } else {

    //     }
    //     // return  examControl?.created_at && (date > examControl?.created_at)
    //   }


    return (
        <Card bordered={false}>
        <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
        >
            <Modal
                open={isAppeal}
                style={{ top: 60, padding: 0 }}
                footer={null}
                title={"Apelyatsiya berish"}
                className="appeal-modal"
                closeIcon={<IoClose size={24} onClick={() => { setIsAppeal(false); setAppeal('') }} />}
            >
                <Input.TextArea value={appeal} onChange={(e) => setAppeal(e.target.value)} placeholder="Apelyatsiya matnini kiriting" rows={6} className="w-100" />
                <Divider className="mb-2 mt-3" />
                <div className="text-end">
                    <Button type="primary" size="small" danger onClick={() => { setIsAppeal(false); setAppeal('') }} className="me-2" >{t("Cancel")}</Button>
                    <Button type="primary" size="small"  >{t("Save")}</Button>
                </div>
            </Modal>
            <SpinnerLoading loading={loading} color="#1890ff" size={28}>
                <Card bordered={false}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <CustomBreadcrumb arr={_breadcrump} />
                            </Col>
                            {/* <Col xs={24} sm={24} md={24} lg={12} xl={12} className="d-f justify-content-end">
                                {!checkFinished(match?.params?.question) ? <Button size="small" type="primary" onClick={() => setIsAppeal(true)} >Apelyatsiya berish</Button> : null}
                            </Col> */}
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <p><strong>Nomi:</strong>&nbsp;{examControl?.name || null}</p>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                {match?.params?.question === "1" ? <p><strong>Muddati:</strong>&nbsp;{examControl?.start ? moment.unix(Number(examControl?.start)).format("DD.MM.YYYY | HH:mm") : null} - {examControl?.finish ? moment.unix(Number(examControl?.finish)).format("DD.MM.YYYY | HH:mm") : null}</p> : null}
                                {match?.params?.question === "2" ? <p><strong>Muddati:</strong>&nbsp;{examControl?.start2 ? moment.unix(Number(examControl?.start2)).format("DD.MM.YYYY | HH:mm") : null} - {examControl?.finish2 ? moment.unix(Number(examControl?.finish2)).format("DD.MM.YYYY | HH:mm") : null}</p> : null}
                            </Col>
                        </Row>
                </Card>
                {match?.params?.question === "1" && (examControl?.question || examControl?.question_file) ? <Card bordered={false}>
                    <Collapse bordered={false} ghost activeKey={["1"]} className="rounded-3" >
                        <Collapse.Panel key="1" header={<div className="d-f justify-content-between w-100" >
                            <h6 style={{ color: "rgb(24,144,255)" }} >1-Topshiriq: </h6>
                            <span style={{ color: "#1B73E8", backgroundColor: "#E8F0FE", borderRadius: 3, fontSize: 13 }} className="py-1 px-2 me-2 mb-2" >Maksimal ball: <strong>{examControl?.max_ball}</strong></span>
                        </div>} className="">
                            <Row gutter={[12, 12]}>
                                <Col xs={24} sm={24} md={24} lg={examControl?.question_file ? 12 : 24} xl={examControl?.question_file ? 12 : 24} >
                                    <fieldset className="field_set_box">
                                        <legend style={{ width: "auto" }}>{t("Savol matni")}</legend>
                                        <div dangerouslySetInnerHTML={{ __html: examControl?.question ?? 'Savol matni mavjud emas' }} ></div>
                                    </fieldset>
                                </Col>
                                {
                                    examControl?.question_file ? <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                        <iframe src={FILE_URL + examControl?.question_file} className="ifream_style" />
                                    </Col> : null
                                }

                                <Col span={24}>
                                    <Divider orientation="left" plain className="mb-2" ><span style={{ color: "#40AD5D", fontSize: 15, fontWeight: 500 }}>{t("Javob")}</span></Divider>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    {checkFinished(match?.params?.question) ? <div className="d-f">
                                        <Form.Item label={<FormLabel>Javobni (pdf) fayl shaklida yuklash</FormLabel>} name='upload_file' className="mb-2" >
                                            <Upload
                                                maxCount={1}
                                                accept={"application/pdf"}
                                                className="bg-dark"
                                                customRequest={dummyRequest}
                                                onChange={checkFileSize}
                                            >
                                                <Button icon={<FaUpload />} type="dashed" style={{ color: checkSize ? "#EA4335" : "", borderColor: checkSize ? "#EA4335" : "" }} className="mb- upload_btn bg-light">&nbsp; Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div> : null}
                                    {examControl?.examControlStudents?.length && examControl?.examControlStudents[0]?.answer_file ? <div className="d-f answer-color mt-2"><span>Javob fayli:&nbsp;</span><OpenFileNewWindowButton url={examControl?.examControlStudents[0].answer_file} /></div> : null}
                                    <Form.Item name='answer' className="mt-3">
                                        <SunEditor
                                            setOptions={{
                                                katex: "katex",
                                                buttonList: [
                                                    [
                                                        'undo',
                                                        'redo'
                                                    ],
                                                    [
                                                        'fullScreen',
                                                        'preview'
                                                    ],
                                                    [
                                                        'bold',
                                                        'underline',
                                                        'italic',
                                                        'strike',
                                                        'fontSize'
                                                    ],
                                                    [
                                                        'subscript',
                                                        'superscript'
                                                    ],
                                                    [
                                                        'outdent',
                                                        'indent',
                                                        'list',
                                                        'align'
                                                    ],
                                                    [
                                                        'removeFormat',
                                                        'table'
                                                    ]
                                                ]
                                            }}
                                            setContents={writeAnswer("question1")}
                                            onChange={(e) => { saveAnswer(e, "question1") }}
                                            height={240 + "px"}
                                            placeholder={t("Enter an answer")}
                                            setAllPlugins={true}
                                            disable={!checkFinished(match?.params?.question)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="text-end mt-3">{checkFinished(match?.params?.question) ?
                                <Button type="primary" htmlType="submit" loading={click} className="px-5">{t('Save')}</Button> : FINISH_TIME(match?.params?.question)}</div>
                        </Collapse.Panel>
                    </Collapse>
                </Card> : null}
                {match?.params?.question === '2' && (examControl?.question2 || examControl?.question2_file) ? <Card bordered={false}>
                    <Collapse bordered={false} ghost activeKey={["1"]} className="rounded-3" >
                        <Collapse.Panel key="1" header={<div className="d-f justify-content-between w-100" >
                            <h6 style={{ color: "rgb(24,144,255)" }} >2-Topshiriq: </h6>
                            <span style={{ color: "#1B73E8", backgroundColor: "#E8F0FE", borderRadius: 3, fontSize: 13 }} className="py-1 px-2 me-2 mb-2" >Maksimal ball: <strong>{examControl?.max_ball2}</strong></span>
                        </div>} className="">
                            <Row gutter={[12, 12]}>
                                <Col xs={24} sm={24} md={24} lg={examControl?.question2_file ? 12 : 24} xl={examControl?.question2_file ? 12 : 24} >
                                    <fieldset className="field_set_box">
                                        <legend style={{ width: "auto" }}>{t("Savol matni")}</legend>
                                        <div dangerouslySetInnerHTML={{ __html: `${examControl?.question2}` }} ></div>
                                    </fieldset>
                                </Col>
                                {
                                    examControl?.question2_file ? <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                        <iframe src={FILE_URL + examControl?.question2_file} className="ifream_style" />
                                    </Col> : null
                                }
                            </Row>
                            <Col span={24}>
                                <Divider orientation="left" plain className="mb-2" ><span style={{ color: "#40AD5D", fontSize: 15, fontWeight: 500 }}>{t("Javob")}</span></Divider>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {checkFinished(match?.params?.question) ? <div className="d-f">
                                    <Form.Item label={<FormLabel>Javobni (pdf) fayl shaklida yuklash</FormLabel>} name='upload2_file' className="mb-3" >
                                        <Upload
                                            maxCount={1}
                                            accept={"application/pdf"}
                                            className="bg-dark"
                                            customRequest={dummyRequest}
                                            onChange={checkFileSize}
                                        >
                                            <Button icon={<FaUpload />} type="dashed" style={{ color: checkSize ? "#EA4335" : "", borderColor: checkSize ? "#EA4335" : "" }} className="mb- upload_btn bg-light">&nbsp; Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </div> : null}
                                {examControl?.examControlStudents?.length && examControl?.examControlStudents[0]?.answer2_file ? <div className="d-f answer-color mt-2"><span>Javob fayli:&nbsp;</span><OpenFileNewWindowButton url={examControl?.examControlStudents[0].answer2_file} /></div> : null}
                                <Form.Item name='answer2' className="mt-3">
                                    <SunEditor
                                        setOptions={{
                                            katex: "katex",
                                            buttonList: [
                                                [
                                                    'undo',
                                                    'redo'
                                                ],
                                                [
                                                    'fullScreen',
                                                    'preview'
                                                ],
                                                [
                                                    'bold',
                                                    'underline',
                                                    'italic',
                                                    'strike',
                                                    'fontSize'
                                                ],
                                                [
                                                    'subscript',
                                                    'superscript'
                                                ],
                                                [
                                                    'outdent',
                                                    'indent',
                                                    'list',
                                                    'align'
                                                ],
                                                [
                                                    'removeFormat',
                                                    'table'
                                                ]
                                            ]
                                        }}
                                        // onPaste={(e) => { e.preventDefault() }}
                                        // onDrop={(e) => { e.preventDefault() }}
                                        setContents={writeAnswer("question2")}
                                        onChange={(e) => { saveAnswer(e, "question2") }}
                                        height={240 + "px"}
                                        placeholder={t("Enter an answer")}
                                        setAllPlugins={true}
                                        disable={!checkFinished(match?.params?.question)}
                                    />
                                </Form.Item>
                            </Col>
                            <div className="text-end mt-3">{checkFinished(match?.params?.question) ?
                                <Button type="primary" htmlType="submit" disabled={checkSize} loading={click} className="px-5">{t('Save')}</Button> : FINISH_TIME(match?.params?.question)}</div>
                        </Collapse.Panel>
                    </Collapse>
                </Card> : null}
            </SpinnerLoading>
        </Form>
        </Card>
    )
}

export default ExamControl;