import { FC } from "react";
import { Empty } from "antd";
import empty from '../../assets/images/data-update.svg'

const EmptyTable: FC<{ data: Array<any>, loading: boolean, description?: string, searchText?: string}> = ({ data, loading, description, searchText}): any => {
    if (data && !data.length && !loading) {
        return (
            <div>
                <Empty description={<span style={{ color: '#1890ff' }}> {description ? description : (searchText ? "Ma'lumot topilmadi !" : "Ma'lumot qo'shilmagan !")}</span>} className="mt-5" />
            </div>
        )
    }

    return null;
}

export default EmptyTable;


export const EmptyData: FC<{ data: Array<any>, loading: boolean, description?: string, searchText?: string, isDark?: boolean }> = ({ data, loading, description, searchText, isDark }): any => {

    if (data && !data.length && !loading) {
        return (
            <div className="d-f justify-content-center w-100 my-5 flex-column" >
                <div className="text-center w-100" >
                    <img style={{ width: 80 }} src={empty} alt="" />
                    <p className="mt-3" style={{ color: '#1890ff' }}> {description ? description : (searchText ? "Ma'lumot topilmadi !" : "Ma'lumot qo'shilmagan !")}</p>
                </div>
            </div>
        )
    }

    return null;
}
