import { FC, useEffect, useMemo } from "react"
import { BiRadioCircleMarked, BiRadioCircle } from 'react-icons/bi'
import { EmptyData } from "components/EmptyTable";
import { SpinnerLoading } from 'components/Spinner'
import { Alert, Button, Col, message, Row } from "antd";
import SubjectCart from "./common/subject_cart";
import { useHistory } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import { EduSemestrType, StudentAttendType, subjectType } from "./common/type";
import "./common/styles.scss";
import { Card, CardBody } from "reactstrap";
import TitleAndSemestrs from "components/TitleAndSemestr";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import useGetSujectByTimeTable from "hooks/useGetSubjectByTimeTable";

const StudentSubjects: FC = (): JSX.Element => {

  const isMobile = /iPhone|Android/i.test(navigator.userAgent);
  const { width } = useWindowSize()
  const history = useHistory();
  const { value, writeToUrl } = useUrlQueryParams({})

  // const { items: timeTables, loading: timeTableLoading, fetch } = useGetAllData({ url: `/student-time-tables?expand=subject,teacher,subjectCategory,timeTable,studentAttends&semester_id=${value.filter?.semestr_id}`, perPage:0}) as TypeReturnUseGetAllData<subjectType>
  const { items: semestrs, loading: semestrLoading } = useGetAllData({ url: `/edu-semestrs?expand=semestr,eduSemestrSubjects`, isCall: "auto" }) as TypeReturnUseGetAllData<EduSemestrType>

  useEffect(() => {
    if (semestrs.length && !value.filter?.semestr_id) {
      const semestr_id = semestrs.find(e => e.status === 1)?.semestr_id
      if (semestr_id) writeToUrl({ name: "semestr_id", value: semestr_id })
    }
  }, [semestrs])

  // useEffect(()=> {
  //   if(value.filter?.semestr_id){
  //     fetch()
  //   }
  // },[value.filter?.semestr_id])

  // const subjects = useMemo(() => {
  //   let obj: { [key: string]: subjectType[] } = {}

  //   if (timeTables.length) {
  //     const newData: subjectType[] = timeTables?.filter((e: any) => Number(e?.timeTable?.semester_id) === value.filter?.semestr_id);

  //     newData?.map((item: subjectType) => {
  //       if (obj[`${item?.subject?.id}`]) {
  //         let arr = [...obj[`${item?.subject?.id}`]];
  //         if (!obj[`${item?.subject?.id}`]?.find((e: subjectType) => e?.subjectCategory?.id === item?.subjectCategory?.id && e?.timeTable?.parent_id === null)) arr.push(item)
  //         obj = { ...obj, [`${item?.subject?.id}`]: arr }
  //       } else {
  //         obj = { ...obj, [`${item?.subject?.id}`]: [item] }
  //       }
  //     })
  //   }

  //   return obj
  // }, [timeTables])

  const {subjects, timeTables, loading} = useGetSujectByTimeTable(value.filter.semestr_id, []);

  const attends = useMemo(() => {
    let obj: { [key: string]: StudentAttendType[] } = {}
    if (timeTables.length) {
      timeTables.forEach(item => {
        if (obj[`${item?.subject?.id}`]) {
          obj = { ...obj, [`${item?.subject?.id}`]: [...obj[`${item?.subject?.id}`], ...item?.studentAttends] }
        } else {
          obj = { ...obj, [`${item?.subject?.id}`]: [...item?.studentAttends] }
        }
      })
    }

    return obj
  }, [timeTables])

  return (
    <Card>
      <CardBody>
        <div id="resize" >
          <TitleAndSemestrs title="My subjects" semesters={semestrs} />
          <Alert type="warning" banner className="my-2" message="O'quv semestiri o'zgarganligini inobatga olgan holda o'tgan semestr ma'lumotini ko'rish uchun tegishli semestrni tanlang!" />
          <SpinnerLoading loading={loading || semestrLoading} color="#1890ff" size={28} >
            {Object.entries(subjects).length ? <div className="mb-4 mt-4">
              {!isMobile || width > 640 ? (
                <div className="subject-grid" >
                  <>
                    <div className="header" >Type</div>
                    <div className="header" >Fan nomi</div>
                    <div className="header" >O'qituvchi</div>
                    <div className="header" >Davomat</div>
                    <div className="header" >Topshiriqlar</div>
                  </>
                  {Object.entries(subjects)?.map(([key, value], i) => {
                    return (<>
                      <div className="column" > {Number(key) === 1 ? <BiRadioCircleMarked color="#1890ff" size={20} /> : <BiRadioCircle color="#1890ff" size={20} />}</div>
                      <div className="column" >
                        {/* <span className="fio_link" onClick={() => history.push({ pathname: `/subjects/${key}/${timeTables[0]?.timeTable?.edu_semester_id}/${timeTables[0]?.timeTable?.semester_id}/${timeTables[0]?.timeTable?.language_id}`, state: { id: key, subjects: value } })} >{value[0]?.subject?.name}</span> */}
                        <span className="fio_link" onClick={() => history.push(`/subjects/${key}?edu_semestr_id=${timeTables[0]?.timeTable?.edu_semester_id}&semestr_id=${timeTables[0]?.timeTable?.semester_id}&language_id=${timeTables[0]?.timeTable?.language_id}`)} >{value[0]?.subject?.name}</span>
                      </div>
                      <div className="column flex-column align-items-start justify-content-around" >{value?.map((e: any, i: number) => i < 3 && (
                        <p className="teacher" key={i} >{e?.subjectCategory?.name}: - {e?.teacher?.last_name.toLowerCase()} {e?.teacher?.first_name.toLowerCase()} {e?.teacher?.middle_name.toLowerCase()}</p>
                      ))}</div>
                      <div className="column" >
                        <Button type="text" className="subject-btn" onClick={() => history.push(`/std_attendances/${key}`)} >{attends[key]?.length}</Button>
                      </div>
                      <div className="column" >
                        <Button
                          type="text" className="subject-btn"
                          onClick={() => value.filter(e => e.subjectCategory?.id !== 1).length ? history.push(`/std_subject/subject_exercisses/${value.find(e => e.subjectCategory.id !== 1)?.time_table_id}`) : message.warning("Seminar tanlanmagan")}
                        > {/* <span>3/5 </span> */} &nbsp; Topshiriqlar</Button>
                      </div>
                    </>
                    )
                  })}
                </div>
              ) : (
                <Row gutter={[24, 24]}>
                  {Object.entries(subjects)?.map(([key, value]: any, i: number) => (
                    <Col key={i} className="w-100" sm={24} md={12} lg={8} xl={6} >
                      <SubjectCart id={key} value={value} data={timeTables} attend={attends[key]} />
                    </Col>
                  ))
                  }
                  {Object.keys(subjects)?.length && <Col span={24} >Jami {Object.keys(subjects)?.length} ta</Col>}
                </Row>)
              }
            </div> : null}

          </SpinnerLoading>
          <EmptyData data={Object.keys(subjects)} loading={loading || semestrLoading} />
        </div>
      </CardBody>
    </Card>
  )
}

export default StudentSubjects;