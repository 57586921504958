import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import instance from "../../config/_axios";

const SignIn = createAsyncThunk(
    'user/SignIn',
    async (data: { type: string, data?: any }) => {
        try {
            let response = null;
            if (data.type === 'login') {
                response = await instance({ url: '/auth/login', method: 'POST', data: data?.data ? data.data : null });
            } else {
                response = await instance({ url: '/users/me', method: 'GET', params: { is_main: 0 } });
            }
            const responseData = response.data;
            if (responseData?.data?.access_token) {
                localStorage.setItem('access_token', responseData.data.access_token)
            }

            if (responseData.status === 1) {

                if (responseData?.data?.permissions.length < 0) {
                    message.error("ERR: user permissions are invalid !!!");
                } else {
                    message.success(responseData?.message);
                }
            }
            return responseData;
        } catch (error: any) {
            const {status} = error.response
            if(status === 401){
                message.error('Incorrect login or password.')
            } else if(status === 500){
                message.warn('Try it later')
            }
            return {
                message: error.response.data.message,
                status: status
            };
        }
    }
)

export default SignIn;

