import { Button, Col, Drawer, Form, Row, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import SunEditor from "suneditor-react";
import "./styles.scss";



const ExamView = () => {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [questions, setQuestions] = useState<Array<any>>([]);
    const [subQuestions, setSubQuestions] = useState<Array<any>>([])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const formdata = new FormData();
    //             formdata.append("exam_id", '344')
    //             const response = await instance({ url: "/exam-student-answers/get-question?expand=type,exam,examStudent,question", data: formdata, method: "POST" });
    //             if (response.data?.status === 1) {
    //                 const _data = response.data?.data;
    //                 if (Array.isArray(_data?.questions) && _data.questions.length) {
    //                     setQuestions(_data?.questions);

    //                     if (Array.isArray(_data?.questions[0]?.question?.subQuestion) && _data?.questions[0]?.question?.subQuestion.length) {
    //                         setSubQuestions(_data?.questions[0]?.question?.subQuestion);
    //                     }
    //                 }
    //             }
    //         }
    //     )()
    // }, [])


    const onSubmit = async (values: any) => {
        // console.log("values -> ", values)
    }


    return (
        <Card>
            <CardBody>
                <div className=" p-3">
                    <Form form={form} onFinish={onSubmit}>
                        <Row gutter={[24, 24]}>
                            <Col xl={20}>
                                <div className="card p-3">
                                    <span>{questions[0]?.question_type}</span>
                                    <div dangerouslySetInnerHTML={{ __html: questions[0]?.question?.question }} className="subquestion_style"></div>
                                </div>
                                {
                                    subQuestions.length ?
                                        subQuestions.map((element, index) => {
                                            return (
                                                <div key={index} className="card mt-3 py-3 px-3">
                                                    <div className="d-flex justify-content-between">
                                                        <span>{index + 1} - savol </span>
                                                        <Tag color="magenta">{element?.percent}</Tag>
                                                    </div>
                                                    <hr className="mt-1" />
                                                    <span dangerouslySetInnerHTML={{ __html: element?.question }} className="subquestion_style"></span>
                                                    <Form.Item

                                                        name={`${element?.id}_answer`}
                                                    >
                                                        <TextArea rows={6} />
                                                        <SunEditor
                                                            onPaste={(e) => { e.preventDefault() }}
                                                            onDrop={(e) => { e.preventDefault() }}
                                                            height={100 + "px"}
                                                            placeholder={t("Enter an answer")}
                                                            setAllPlugins={true}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )
                                        }, []) : null
                                }

                                <Button type="primary" htmlType="submit">submit</Button>
                            </Col>
                            <Col xl={4} className="card">
                                <div>
                                    sdfgsdfgd
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div >
            </CardBody>
        </Card>
    )
}




export default ExamView;