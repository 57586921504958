/** @format */

import { FC, useEffect, useState } from "react";
import { useRouteMatch, match, useHistory } from 'react-router';
import { SelectSubjectWrapper, TypeTopics } from ".";
import { SelectStudentTheme } from "config/theme/colors";
import { EmptyData } from "components/EmptyTable";
import { FaArrowLeft } from "react-icons/fa";
import { Title } from "pages/styles/style";
import { Card } from "antd";


const SubjectTopic: FC = (): JSX.Element => {
  const theme = SelectStudentTheme();
  const history = useHistory();
  const match: match<any> | null = useRouteMatch("/subject_topic/:subject_name");
  const [topics, setTopics] = useState<TypeTopics>([])

  useEffect(() => {
    if (localStorage.getItem('topics')) {
      setTopics(JSON.parse(localStorage.getItem('topics') || "[]"))
    }
  }, [])

  console.log(topics);
  

  return (
    <Card>
        <SelectSubjectWrapper theme={theme}>
          <div className="d-f">
            <FaArrowLeft onClick={() => history.goBack()} color="#5A8DEE" size={13} style={{ cursor: "pointer" }} />
            <Title color={theme.header_text} className="mx-2" >{match?.params?.subject_name} </Title>
            <span>mavzulari</span>
          </div>
          {
            topics?.length ? <div className="mt-3">
              {
                topics.length ? topics?.map((element: any, i: number) => (
                  <div key={i} className="subject-box" >
                    <div>
                      <p style={{ fontSize: 16, fontWeight: 500 }} >{element?.name}</p>
                      <span style={{ fontSize: 14 }} >{element?.description}</span>
                    </div>
                  </div>
                )) : null
              }
            </div> : <EmptyData data={topics} loading={false} />
          }
        </SelectSubjectWrapper>
    </Card>
  )
};

export default SubjectTopic;

