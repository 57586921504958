import { FC } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { Col, Row } from 'antd';
import { SelectStudentTheme } from 'config/theme/colors';
import { IoLibrarySharp } from 'react-icons/io5';
import { Wrapper } from './style';
import { Title } from 'pages/styles/style';
import { Card, CardBody } from 'reactstrap';

const StudentLibrary: FC = (): JSX.Element => {

    const theme = SelectStudentTheme();
    const history = useHistory();

    return (
        <Card>
            <CardBody>
                <Wrapper theme={theme}>
                    <Row className="p-2 px-0">
                        <Col span={24}>
                            <Title color={theme.header_text} >Kutubxona</Title>
                        </Col>
                    </Row>
                    <Row className="row" gutter={16}>
                        <Col xs={12} sm={8} md={6} lg={4} onClick={() => { history.push({ pathname: "/std_library_books" }) }} >
                            <div><h1><IoLibrarySharp /></h1> Soliq huquqi</div></Col>
                        <Col xs={12} sm={8} md={6} lg={4} onClick={() => { history.push({ pathname: "/std_library_books" }) }} >
                            <div><h1><IoLibrarySharp /></h1> Iqtisodiyot nazariyasi</div></Col>
                        <Col xs={12} sm={8} md={6} lg={4} onClick={() => { history.push({ pathname: "/std_library_books" }) }} >
                            <div><h1><IoLibrarySharp /></h1> O'zbekiston Respublikasi konstitutsiya huquqi</div></Col>
                        <Col xs={12} sm={8} md={6} lg={4} onClick={() => { history.push({ pathname: "/std_library_books" }) }} >
                            <div><h1><IoLibrarySharp /></h1> Dalillar nazariyasi</div></Col>
                        <Col xs={12} sm={8} md={6} lg={4} onClick={() => { history.push({ pathname: "/std_library_books" }) }} >
                            <div><h1><IoLibrarySharp /></h1> O'zbek (rus) tili</div></Col>
                        <Col xs={12} sm={8} md={6} lg={4} onClick={() => { history.push({ pathname: "/std_library_books" }) }} >
                            <div><h1><IoLibrarySharp /></h1> Bojxona huquqi</div></Col>
                    </Row>
                </Wrapper>
            </CardBody>
        </Card>
    )
}

export default withRouter(StudentLibrary);