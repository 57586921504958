import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SignIn from './signIn';
import { useHistory, useLocation } from 'react-router-dom';
import './login.scss'

const QrLogin: any = () => {

    const dispatch: any = useDispatch();
    const pathParts: Array<string> = useLocation().pathname.split("/")
    const history=useHistory();

    useEffect(() => {
        (
            async () => {
                if (pathParts.length===3) {
                    const formdata = new FormData();
                    formdata.append("username", pathParts[1])
                    formdata.append("password", pathParts[2])
                    const arg = {
                        type: 'login',
                        data: formdata
                    }
                    await dispatch(SignIn(arg));
                    history.push("/")
                }else{
                    history.push("/")
                }
            }
        )()
    }, [])

    // console.log(pathParts);

    return (
        <div></div>
    )
}
export default QrLogin;