import { Button, Col, Empty, Popconfirm, Row, Spin } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { IClubStudent } from "models/club";
import { Card, CardBody } from "reactstrap";
import "./styles.scss";
import { IWeek } from "models/others";
import { PageInfoContainer } from "components/Notice";
import { BiRightArrowAlt, BiX } from "react-icons/bi";
import { NavLink, useHistory } from "react-router-dom";
import useDeleteOne from "hooks/useDeleteOne";
import { FILE_URL } from "config/utils";

const StudentClub = () => {
    const history = useHistory();
    const _delete = useDeleteOne();
    const weeks = useGetAllData({ url: `/weeks`, isCall: 'auto' }) as TypeReturnUseGetAllData<IWeek>;
    const studentClubs = useGetAllData({ url: `/student-clubs?expand=club.description,club.clubTimes,clubTime,clubCategory`, isCall: 'auto', refetch: [_delete.refetch] }) as TypeReturnUseGetAllData<IClubStudent>;

    const findWeek = (week_id: number) => {
        if (weeks.items.length) {
            const findElement = weeks.items.find(e => e.id === week_id);
            return findElement;
        }
    }

    const setStorege = () => {
        let data: Array<any> = [];
        studentClubs.items?.forEach(e => data.push({ club_id: e?.club_id, club_time_id: e?.club_time_id }));

        localStorage.setItem("selectedClubs", JSON.stringify(data))
    }

    return (
        <Spin spinning={studentClubs.loading && weeks.loading} >
            <Card>
                <CardBody>
                    <PageInfoContainer>
                        <Row>
                            <Col xl={16} lg={16} md={14} xs={24}><span className="fs_14" >Mening mashg'ulotlarim</span></Col>
                            <Col xl={8} lg={8} md={10} xs={24} className="text-end" ><NavLink to={`/club_category`} className="text-end fs_12" ><Button type="primary" size="small" onClick={setStorege} >Mash'gulotga a'zo bo'lish <BiRightArrowAlt size={20} /></Button> </NavLink></Col>
                        </Row>
                    </PageInfoContainer>
                    <div style={{ backgroundColor: "#F5F7F9" }} className="mt-3 p-2" >
                        {
                            studentClubs.items.length ?
                                studentClubs.items.map((element) => {
                                    return (
                                        <Card key={element.id} className="mt-3" >
                                            <CardBody>
                                                <Row>
                                                    <Col lg={24} md={24} sm={24} xs={24} xl={18}>
                                                        <div className="student_club_card">
                                                            <div className="student_club_card_image">
                                                                <img src={FILE_URL + element.club?.image} alt="Rasm topilmadi" />
                                                            </div>
                                                            <div className="student_club_card_info">
                                                                <p>
                                                                    <i className="fio_link" onClick={() => {history.push(`/club/${element?.club?.id}`); setStorege()}} >{element.club?.name}</i>
                                                                    <span>{element.club?.description}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={12} md={24} sm={24} xs={24} >
                                                        {
                                                            Object.entries(element?.clubTime?.times)?.map(([key, value]) => <span key={key} className="student_club_times" >{findWeek(Number(key))?.name}: &nbsp;{value}</span>)
                                                        }
                                                    </Col>
                                                </Row>
                                                <span className="student_club_remove_btn">
                                                    <Popconfirm placement="topRight" title={"O'chirilsinmi ?"} onConfirm={() => {_delete.fetch(`/student-clubs/${element.id}`); setStorege()}} okText="Ha" cancelText="Yo'q">
                                                        <BiX size={24} color="#ED161E" />
                                                    </Popconfirm>
                                                </span>
                                            </CardBody>
                                        </Card>
                                    )
                                }) : <Card><CardBody><Empty description={<>Mashg'ulotga a'zo bo'lmagansiz.  <NavLink to={`/club_category`} onClick={setStorege} >A'zo bo'lish</NavLink></>} /></CardBody></Card>
                        }
                    </div>
                </CardBody>
            </Card>
        </Spin >
    )

}


export default StudentClub;