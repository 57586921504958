import { Button, Card, Col, Empty, Form, Radio, Row, Space } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubjectContentType } from "../common/type";
import { FaFile, FaFilm, FaImage, FaMusic, } from "react-icons/fa";
import { FiType } from "react-icons/fi";
import { SelectStudentTheme } from "config/theme/colors";
import { WrapperContent_1 } from "../common/styled";
import word from 'assets/images/word.svg';
import powerpoint from 'assets/images/powerpoint.svg';
import zip from 'assets/images/zip.png';
import { GoTextSize } from "react-icons/go";
import { CardBody, Card as CardWrapper } from "reactstrap";
import { useRouteMatch, match } from "react-router";
import { EmptyData } from "components/EmptyTable";
import { BsFillBookmarksFill } from "react-icons/bs";
import useGetAllData from "hooks/useGetAllData";
import TopicRefarence from "../common/topic_reference";

const _breadcrump = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "Subjects",
        path: `/std_subjects`,
    },
    {
        name: "Subject contents",
        path: "",
    }
];

const SubjectContent_1: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const theme = SelectStudentTheme();
    const [form] = Form.useForm();
    const [questins, setQuestions] = useState<Array<any>>([1, 2, 3, 4]);
    const [textSize, setTextSize] = useState<number>(14);
    const [fileFormat, setFileFormat] = useState<number>(6);
    const match: match<any> | null = useRouteMatch("/subject_content_1/:topic_id/:user_id");

    const contents = useGetAllData({
        url: `/subject-contents?expand=subjectTopic,subject,lang,updatedBy,createdBy&sort=-id&filter={"subject_topic_id":${match?.params?.topic_id}}`,
        isCall: "auto",
        perPage: 0,
    });

    const topicReference = useGetAllData({
        url: `subject-topic-references?filter={"subject_topic_id":${match?.params?.topic_id},"user_id":${match?.params?.user_id}}`,
        isCall: "auto",
    })

    const isFileReadInPageByType = (url: string) => {
        let a: Array<string> = url.split(".");
        if (a[a.length - 1] === "pdf") {
            return true
        } else {
            return false
        }
    }

    const checkFileFormat = (file: string) => {
        const a: Array<string> = file.split('.');
        if (a.includes("doc") || a.includes("docx")) {
            return { url: word, format: "file" }
        }
        if (a.includes("ppt") || a.includes('pptx')) {
            return { url: powerpoint, format: "file" }
        }
        if (a.includes("zip")) {
            return { url: zip, format: "file" }
        }
        if (a.includes("png") || a.includes('jpg') || a.includes('gimp') || a.includes('bmp') || a.includes('jpeg')) {
            return { url: file, format: "img" }
        }
        if (a.includes("mp4") || a.includes('avi')) {
            return { url: file, format: "video" }
        }
        if (a.includes("mp3") || a.includes('ogg')) {
            return { url: file, format: "audio" }
        }
    }

    const changeSize = (s: number) => {
        (textSize > 12 && s === -1) && setTextSize(textSize + s);
        (s === 1 && textSize < 20) && setTextSize(textSize + s);
    }

    const formatFilter = (type: number) => (fileFormat === 0 || type === fileFormat) && true

    const filteredContents = React.useMemo((): Array<any> => {
        if (fileFormat === 0) return contents.items;
        return contents.items?.filter(e => e?.type === fileFormat)
    }, [fileFormat, contents.items])

    return (
        <CardWrapper>
            <CardBody>
                <WrapperContent_1 theme={theme} size={textSize}>
                    <Row>
                        <Col span={24} className="text-start">
                            <CustomBreadcrumb arr={_breadcrump} />
                        </Col>
                    </Row>
                    <hr />
                    {contents.loading ? (
                        <div className="loader-line"></div>
                    ) : (
                        <div style={{ height: "3px" }}></div>
                    )}
                    {contents.items?.length ? <Row gutter={24}>
                        <Col className="p-2 mx-3 title_filter" span={24}>
                            <div className="me-4 pe-2">
                                <strong style={{ fontSize: "15px" }} >{t("Mavzu")}: &nbsp;</strong>
                                <span style={{ fontSize: "15px" }} >{contents.items[0]?.subjectTopic?.name + " (" + contents.items[0]?.subject?.name + ")"}</span>
                            </div>
                            <div className="btn-group">
                                <div>
                                    {/* <Button className="btn-filter" size="small" style={{ borderColor: fileFormat === 0 ? theme.blue : theme.active_element }} onClick={() => setFileFormat(0)} >Barchasi</Button> */}
                                    <Button className="btn-filter" size="small" style={{ borderColor: fileFormat === 6 ? theme.blue : theme.active_element }} onClick={() => {setFileFormat(6); !topicReference.items && topicReference.fetch() }} ><BsFillBookmarksFill/><span className="btn-filter-text">&nbsp;&nbsp;Sarlavha</span></Button>
                                    <Button className="btn-filter" size="small" style={{ borderColor: fileFormat === 1 ? theme.blue : theme.active_element }} onClick={() => setFileFormat(1)} ><FiType/><span className="btn-filter-text">&nbsp;&nbsp;Matn</span></Button>
                                    <Button className="btn-filter" size="small" style={{ borderColor: fileFormat === 3 ? theme.blue : theme.active_element }} onClick={() => setFileFormat(3)} ><FaImage/><span className="btn-filter-text">&nbsp;&nbsp;Rasmlar</span></Button>
                                    <Button className="btn-filter" size="small" style={{ borderColor: fileFormat === 4 ? theme.blue : theme.active_element }} onClick={() => setFileFormat(4)} ><FaFilm/><span className="btn-filter-text">&nbsp;&nbsp;Videolar</span></Button>
                                    <Button className="btn-filter" size="small" style={{ borderColor: fileFormat === 5 ? theme.blue : theme.active_element }} onClick={() => setFileFormat(5)} ><FaMusic/><span className="btn-filter-text">&nbsp;&nbsp;Audiolar</span></Button>
                                    <Button className="btn-filter" size="small" style={{ borderColor: fileFormat === 2 ? theme.blue : theme.active_element }} onClick={() => setFileFormat(2)} ><FaFile/><span className="btn-filter-text">&nbsp;&nbsp;Fayllar</span></Button>
                                </div>
                                <div className="btn-filter text-size d-f">
                                    <Button className="btn-filter" size="small" onClick={() => changeSize(-1)} ><GoTextSize /> <sup>-</sup> </Button>
                                    <span className="ms-1" >{textSize}</span>
                                    <Button className="btn-filter" size="small" onClick={() => changeSize(1)} ><GoTextSize /><sup>+</sup></Button>
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Row style={{ height: "80vh", overflowY: "auto" }} className="content" gutter={24}>
                                {
                                  filteredContents.length ? filteredContents?.map((item: SubjectContentType, i: number) =>
                                        <Col key={i} span={24}>
                                            <Card id={String(i)} className="m-2 cards" loading={contents.loading}>
                                                { item?.content !== "undefined" ? <div dangerouslySetInnerHTML={{ __html: item?.content }}></div> : <div></div>}
                                                {
                                                    item?.file_url && <>
                                                        {
                                                            isFileReadInPageByType(item?.file_url) ?
                                                                <>
                                                                    <div className="d-flex justify-content-center">
                                                                        <iframe src={FILE_URL + item?.file_url} className="ifream_style" />
                                                                    </div>
                                                                    <div className="d-flex justify-content-center mt-3">
                                                                        <strong>{t("Description")}: </strong>
                                                                        <span>{item?.description}</span>
                                                                    </div>
                                                                </> :
                                                                <>
                                                                    <div className="d-flex">
                                                                        {
                                                                            checkFileFormat(item?.file_url)?.format === "file" ? (<Button style={{ height: "max-content" }} type="link" href={FILE_URL + item?.file_url} target="_blank"><img style={{ width: 60 }} src={checkFileFormat(item?.file_url)?.url} alt="" /> </Button>
                                                                            ) : checkFileFormat(item?.file_url)?.format === "img" ? (<img className="d-inline-block image" src={FILE_URL + checkFileFormat(item?.file_url)?.url} alt="image" />
                                                                            ) : checkFileFormat(item?.file_url)?.format === "video" ? (<video className="d-inline-block video" src={FILE_URL + checkFileFormat(item?.file_url)?.url} controls></video>
                                                                            ) : checkFileFormat(item?.file_url)?.format === "audio" ? (<audio src={FILE_URL + checkFileFormat(item?.file_url)?.url} controls></audio>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                    <div className="d-flex justify-content-start mt-3">
                                                                        <strong className="me-1">{t("Description")}: </strong>
                                                                        <span>{item?.description}</span>
                                                                    </div>
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </Card>
                                        </Col>
                                    ) : fileFormat === 6 ? (
                                        <Col span={24}>
                                            <TopicRefarence topicReferens={topicReference.items} />
                                        </Col>
                                    ) : <Col span={24}>
                                            <Card className="cards m-2" >
                                                <EmptyData data={[]} loading={false} description={fileFormat === 0 ? "Content topilmadi" : "Ushbu tipdagi content topilmadi"} />
                                            </Card>
                                        </Col>
                                }
                                <Card className="cards w-100 p-0" title={<h6 style={{ fontSize: 15, color: theme.text }} >Contentni baholash</h6>} >
                                    <Form
                                        layout="vertical"
                                        form={form}
                                    >
                                        {
                                            questins?.map((e: number, i: number) => (
                                                <Col key={i} span={24} className="m-0 p-0">
                                                    <Card className="cards" style={{ borderRadius: i === 0 ? ".5rem .5rem 0 0" : "0" }} title={<strong style={{color: theme.text}}> {e}. Content bo'yicha fikringiz?</strong>}>
                                                        <Radio.Group>
                                                            <Space direction="vertical">
                                                                <Radio value={1}>
                                                                    A'lo
                                                                </Radio>
                                                                <Radio value={2}>
                                                                    Yaxshi
                                                                </Radio>
                                                                <Radio value={3}>
                                                                    Qoniqarli
                                                                </Radio>
                                                                <Radio value={4}>
                                                                    Qoniqarsiz
                                                                </Radio>
                                                            </Space>
                                                        </Radio.Group>
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                        <div className="d-f justify-content-end w-100" >
                                            <Button className="m-2 button" style={{ backgroundColor: theme.blue, borderRadius: ".25rem" }} ><span style={{color: "#fff"}} >{t("Submit")}</span></Button>
                                        </div>
                                    </Form>
                                </Card>
                            </Row>
                        </Col>
                    </Row> : <Empty className="my-3" />}
                </WrapperContent_1>
            </CardBody>
        </CardWrapper>
    )
}

export default SubjectContent_1