import { FC, useState } from "react"
import { FaArrowLeft } from 'react-icons/fa';
import { useRouteMatch, match } from 'react-router';
import { SelectStudentTheme } from "config/theme/colors";
import { NoticeTimeTableSelect } from ".././common/styled";
import { Col, Row, message, Spin, Tag, Popconfirm, Button } from "antd";
import instance from "config/_axios";
import { useHistory } from "react-router-dom";
import "../../subjects/common/styles.scss";
import ".././common/style.scss";
import { ITimeOption, ITimeTable } from "models/others";
import styles from '../styles.module.scss';
import useGetOneData, { ReturnTypeUseGetOneData } from "hooks/useGetOneData";
import { Card } from "antd";
import React from "react";
import { TimeTableVariantItem } from "../components/TimeTableVariantItem";
import { Title } from "pages/styles/style";
import { EmptyData } from "components/EmptyTable";


const TimeTableBySubjects: FC = (): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const history = useHistory();
    const [semenars, setSemenars] = React.useState<Record<string, ITimeTable[]>>({});
    const match: match<any> | null = useRouteMatch("/time_tables_by_subject/:time_option_id");
    const [reFetch, setReFetch] = useState<boolean>(false);

    const { data, loading } = useGetOneData({
        url: `/time-options/${match?.params?.time_option_id}?expand=selected,selectedCount,faculty,room,eduSemester.semestr,timeTables.week,timeTables.subject,timeTables.para,timeTables.subjectCategory,timeTables.teacher,timeTables.room,timeTables.building,timeTables.selectedCount,timeTables.selected,timeTables.language,timeTables.child.week,timeTables.child.para,timeTables.child.room,timeTables.child.building,timeTables.child.subjectCategory,timeTables.child.language,timeTables.child.teacher,timeTables.seminar.week,timeTables.seminar.room,timeTables.seminar.building,timeTables.seminar.subjectCategory,timeTables.seminar.language,timeTables.seminar.teacher,timeTables.seminar.para,timeTables.seminar.selected,timeTables.seminar.selectedCount,timeTables.seminar.child.week,timeTables.seminar.child.room,timeTables.seminar.child.building,timeTables.seminar.child.language,timeTables.seminar.child.subjectCategory,timeTables.seminar.child.teacher,timeTables.seminar.child.para`,
        isCall: 'auto',
        refetch: [reFetch]
    }) as ReturnTypeUseGetOneData<ITimeOption>
    /*{
        "data": {
            "id": 153,
            "name": "C(uz)",
            "capacity": 55,
            "key": "C",
            "faculty_id": 2,
            "edu_plan_id": 56,
            "edu_year_id": 16,
            "edu_semester_id": 331,
            "language_id": 1,
            "type": null,
            "description": null,
            "status": 1,
            "created_at": 1663163186,
            "updated_at": 1663340839,
            "created_by": 1,
            "updated_by": 11,
            "faculty": {
                "id": 2,
                "name": "Ommaviy huquq",
                "user_id": 8988,
                "order": 1,
                "status": 1,
                "created_at": 1,
                "updated_at": 1674460994,
                "created_by": 0,
                "updated_by": 7401
            },
            "eduSemester": {
                "id": 331,
                "name": "2022-2023-4-7",
                "edu_plan_id": 56,
                "course_id": 4,
                "optional_subject_count": 0,
                "required_subject_count": 0,
                "semestr_id": 7,
                "credit": 0,
                "edu_year_id": 16,
                "start_date": "2022-09-04 00:00:00",
                "end_date": "2023-01-31 00:00:00",
                "is_checked": 1,
                "order": 1,
                "status": 1,
                "created_at": 1652782323,
                "updated_at": 1667541873,
                "created_by": 599,
                "updated_by": 1,
                "semestr": {
                    "id": 7,
                    "name": "7",
                    "order": 1,
                    "status": 1,
                    "type": 1,
                    "created_at": 0,
                    "updated_at": 0,
                    "created_by": 0,
                    "updated_by": 0
                }
            },
            "timeTables": [
                {
                    "id": 776,
                    "teacher_access_id": 2549,
                    "room_id": 59,
                    "para_id": 1,
                    "week_id": 5,
                    "course_id": 4,
                    "semester_id": 7,
                    "parent_id": null,
                    "lecture_id": null,
                    "time_option_id": 153,
                    "edu_semester_id": 331,
                    "edu_year_id": 16,
                    "subject_id": 300,
                    "language_id": 1,
                    "order": 1,
                    "edu_plan_id": 56,
                    "building_id": 4,
                    "subject_category_id": 1,
                    "status": 1,
                    "created_at": 1662550020,
                    "updated_at": 1663325597,
                    "created_by": 596,
                    "updated_by": 308,
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "child": [
                        {
                            "id": 779,
                            "teacher_access_id": 2549,
                            "room_id": 59,
                            "para_id": 2,
                            "week_id": 5,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": 776,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 300,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662550047,
                            "updated_at": 1662550047,
                            "created_by": 596,
                            "updated_by": 0,
                            "subjectCategory": {
                                "id": 1,
                                "name": "Ma'ruza",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784509,
                                "updated_at": 1643784509,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 2,
                                "name": "2",
                                "start_time": "09:30",
                                "end_time": "10:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997345,
                                "updated_at": 1662457732,
                                "created_by": 1,
                                "updated_by": 11
                            },
                            "room": {
                                "id": 59,
                                "name": "119",
                                "building_id": 4,
                                "capacity": 105,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977592,
                                "updated_at": 1662202990,
                                "created_by": 4,
                                "updated_by": 7402
                            },
                            "week": {
                                "id": 5,
                                "name": "Juma",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852242,
                                "updated_at": 1659070142,
                                "created_by": 112,
                                "updated_by": 1
                            },
                            "teacher": {
                                "user_id": 7462,
                                "first_name": "AKMAL",
                                "last_name": "SODIKOV",
                                "middle_name": "SHAVKAT O‘G‘LI"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "seminar": [
                        {
                            "id": 786,
                            "teacher_access_id": 2549,
                            "room_id": 55,
                            "para_id": 3,
                            "week_id": 3,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 776,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 300,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662550142,
                            "updated_at": 1662550142,
                            "created_by": 596,
                            "updated_by": 0,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 787,
                                    "teacher_access_id": 2549,
                                    "room_id": 55,
                                    "para_id": 4,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 786,
                                    "lecture_id": 776,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662550176,
                                    "updated_at": 1662550176,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 4,
                                        "name": "4",
                                        "start_time": "11:30",
                                        "end_time": "12:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997355,
                                        "updated_at": 1662457779,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 55,
                                        "name": "106",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1650977476,
                                        "updated_at": 1650977476,
                                        "created_by": 4,
                                        "updated_by": 0
                                    },
                                    "week": {
                                        "id": 3,
                                        "name": "Chorshanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644852021,
                                        "updated_at": 1644852021,
                                        "created_by": 112,
                                        "updated_by": 0
                                    },
                                    "teacher": {
                                        "user_id": 7462,
                                        "first_name": "AKMAL",
                                        "last_name": "SODIKOV",
                                        "middle_name": "SHAVKAT O‘G‘LI"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 0,
                            "selectedCount": 24,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 3,
                                "name": "3",
                                "start_time": "10:30",
                                "end_time": "11:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997350,
                                "updated_at": 1662457753,
                                "created_by": 1,
                                "updated_by": 11
                            },
                            "room": {
                                "id": 55,
                                "name": "106",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977476,
                                "updated_at": 1650977476,
                                "created_by": 4,
                                "updated_by": 0
                            },
                            "week": {
                                "id": 3,
                                "name": "Chorshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852021,
                                "updated_at": 1644852021,
                                "created_by": 112,
                                "updated_by": 0
                            },
                            "teacher": {
                                "user_id": 7462,
                                "first_name": "AKMAL",
                                "last_name": "SODIKOV",
                                "middle_name": "SHAVKAT O‘G‘LI"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        },
                        {
                            "id": 1427,
                            "teacher_access_id": 2673,
                            "room_id": 56,
                            "para_id": 3,
                            "week_id": 3,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 776,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 300,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662616632,
                            "updated_at": 1662616632,
                            "created_by": 596,
                            "updated_by": 0,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 1440,
                                    "teacher_access_id": 2673,
                                    "room_id": 56,
                                    "para_id": 4,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 1427,
                                    "lecture_id": 776,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 300,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662616990,
                                    "updated_at": 1662616990,
                                    "created_by": 596,
                                    "updated_by": 0,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 4,
                                        "name": "4",
                                        "start_time": "11:30",
                                        "end_time": "12:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997355,
                                        "updated_at": 1662457779,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 56,
                                        "name": "107",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1650977490,
                                        "updated_at": 1650977490,
                                        "created_by": 4,
                                        "updated_by": 0
                                    },
                                    "week": {
                                        "id": 3,
                                        "name": "Chorshanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644852021,
                                        "updated_at": 1644852021,
                                        "created_by": 112,
                                        "updated_by": 0
                                    },
                                    "teacher": {
                                        "user_id": 7482,
                                        "first_name": "ILXOMJON",
                                        "last_name": "YUSUPOV",
                                        "middle_name": "IBODILLAYEVICH"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 0,
                            "selectedCount": 26,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 3,
                                "name": "3",
                                "start_time": "10:30",
                                "end_time": "11:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997350,
                                "updated_at": 1662457753,
                                "created_by": 1,
                                "updated_by": 11
                            },
                            "room": {
                                "id": 56,
                                "name": "107",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977490,
                                "updated_at": 1650977490,
                                "created_by": 4,
                                "updated_by": 0
                            },
                            "week": {
                                "id": 3,
                                "name": "Chorshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852021,
                                "updated_at": 1644852021,
                                "created_by": 112,
                                "updated_by": 0
                            },
                            "teacher": {
                                "user_id": 7482,
                                "first_name": "ILXOMJON",
                                "last_name": "YUSUPOV",
                                "middle_name": "IBODILLAYEVICH"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "selected": 1,
                    "selectedCount": 51,
                    "language": {
                        "name": "O'zbekcha",
                        "lang_code": "uz"
                    },
                    "para": {
                        "id": 1,
                        "name": "1",
                        "start_time": "08:30",
                        "end_time": "09:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997338,
                        "updated_at": 1651036105,
                        "created_by": 1,
                        "updated_by": 4
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 5,
                        "name": "Juma",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852242,
                        "updated_at": 1659070142,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "subject": {
                        "id": 300,
                        "name": "Yuridik texnika",
                        "kafedra_id": 1,
                        "semestr_id": 1,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1661259600,
                        "updated_at": 1661259600,
                        "created_by": 596,
                        "updated_by": 0
                    },
                    "teacher": {
                        "user_id": 7462,
                        "first_name": "AKMAL",
                        "last_name": "SODIKOV",
                        "middle_name": "SHAVKAT O‘G‘LI"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    }
                },
                {
                    "id": 1093,
                    "teacher_access_id": 2842,
                    "room_id": 59,
                    "para_id": 3,
                    "week_id": 4,
                    "course_id": 4,
                    "semester_id": 7,
                    "parent_id": null,
                    "lecture_id": null,
                    "time_option_id": 153,
                    "edu_semester_id": 331,
                    "edu_year_id": 16,
                    "subject_id": 309,
                    "language_id": 1,
                    "order": 1,
                    "edu_plan_id": 56,
                    "building_id": 4,
                    "subject_category_id": 1,
                    "status": 1,
                    "created_at": 1662556656,
                    "updated_at": 1669186033,
                    "created_by": 591,
                    "updated_by": 7401,
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "child": [
                        {
                            "id": 1095,
                            "teacher_access_id": 2842,
                            "room_id": 59,
                            "para_id": 4,
                            "week_id": 4,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": 1093,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 309,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662556684,
                            "updated_at": 1669186038,
                            "created_by": 591,
                            "updated_by": 7401,
                            "subjectCategory": {
                                "id": 1,
                                "name": "Ma'ruza",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784509,
                                "updated_at": 1643784509,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 4,
                                "name": "4",
                                "start_time": "11:30",
                                "end_time": "12:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997355,
                                "updated_at": 1662457779,
                                "created_by": 1,
                                "updated_by": 11
                            },
                            "room": {
                                "id": 59,
                                "name": "119",
                                "building_id": 4,
                                "capacity": 105,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977592,
                                "updated_at": 1662202990,
                                "created_by": 4,
                                "updated_by": 7402
                            },
                            "week": {
                                "id": 4,
                                "name": "Payshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852031,
                                "updated_at": 1659070110,
                                "created_by": 112,
                                "updated_by": 1
                            },
                            "teacher": {
                                "user_id": 4552,
                                "first_name": "JAVLONBEK",
                                "last_name": "BAXODIROV",
                                "middle_name": "BAXODIROVICH"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "seminar": [
                        {
                            "id": 1101,
                            "teacher_access_id": 2894,
                            "room_id": 94,
                            "para_id": 1,
                            "week_id": 1,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 1093,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 309,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662556735,
                            "updated_at": 1662821514,
                            "created_by": 591,
                            "updated_by": 591,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 1104,
                                    "teacher_access_id": 2894,
                                    "room_id": 94,
                                    "para_id": 2,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 1101,
                                    "lecture_id": 1093,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662556753,
                                    "updated_at": 1662556753,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 2,
                                        "name": "2",
                                        "start_time": "09:30",
                                        "end_time": "10:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997345,
                                        "updated_at": 1662457732,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 94,
                                        "name": "208",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1662351165,
                                        "updated_at": 1662351165,
                                        "created_by": 7402,
                                        "updated_by": 0
                                    },
                                    "week": {
                                        "id": 1,
                                        "name": "Dushanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644820290,
                                        "updated_at": 1644820290,
                                        "created_by": 112,
                                        "updated_by": 0
                                    },
                                    "teacher": {
                                        "user_id": 4541,
                                        "first_name": "Sardor",
                                        "last_name": "Mirzokarimov",
                                        "middle_name": "Sadiralizoda"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 0,
                            "selectedCount": 28,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 1,
                                "name": "1",
                                "start_time": "08:30",
                                "end_time": "09:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997338,
                                "updated_at": 1651036105,
                                "created_by": 1,
                                "updated_by": 4
                            },
                            "room": {
                                "id": 94,
                                "name": "208",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1662351165,
                                "updated_at": 1662351165,
                                "created_by": 7402,
                                "updated_by": 0
                            },
                            "week": {
                                "id": 1,
                                "name": "Dushanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644820290,
                                "updated_at": 1644820290,
                                "created_by": 112,
                                "updated_by": 0
                            },
                            "teacher": {
                                "user_id": 4541,
                                "first_name": "Sardor",
                                "last_name": "Mirzokarimov",
                                "middle_name": "Sadiralizoda"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        },
                        {
                            "id": 2812,
                            "teacher_access_id": 2872,
                            "room_id": 64,
                            "para_id": 1,
                            "week_id": 1,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 1093,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 309,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662821672,
                            "updated_at": 1666681085,
                            "created_by": 591,
                            "updated_by": 11,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 2813,
                                    "teacher_access_id": 2872,
                                    "room_id": 64,
                                    "para_id": 2,
                                    "week_id": 1,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 2812,
                                    "lecture_id": 1093,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 309,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662821703,
                                    "updated_at": 1666681091,
                                    "created_by": 591,
                                    "updated_by": 11,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 2,
                                        "name": "2",
                                        "start_time": "09:30",
                                        "end_time": "10:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997345,
                                        "updated_at": 1662457732,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 64,
                                        "name": "210",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1650977841,
                                        "updated_at": 1662203186,
                                        "created_by": 4,
                                        "updated_by": 7402
                                    },
                                    "week": {
                                        "id": 1,
                                        "name": "Dushanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644820290,
                                        "updated_at": 1644820290,
                                        "created_by": 112,
                                        "updated_by": 0
                                    },
                                    "teacher": {
                                        "user_id": 4561,
                                        "first_name": "XURSHID",
                                        "last_name": "BOTIROV",
                                        "middle_name": "ILYOS O‘G‘LI"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 0,
                            "selectedCount": 22,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 1,
                                "name": "1",
                                "start_time": "08:30",
                                "end_time": "09:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997338,
                                "updated_at": 1651036105,
                                "created_by": 1,
                                "updated_by": 4
                            },
                            "room": {
                                "id": 64,
                                "name": "210",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977841,
                                "updated_at": 1662203186,
                                "created_by": 4,
                                "updated_by": 7402
                            },
                            "week": {
                                "id": 1,
                                "name": "Dushanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644820290,
                                "updated_at": 1644820290,
                                "created_by": 112,
                                "updated_by": 0
                            },
                            "teacher": {
                                "user_id": 4561,
                                "first_name": "XURSHID",
                                "last_name": "BOTIROV",
                                "middle_name": "ILYOS O‘G‘LI"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "selected": 1,
                    "selectedCount": 51,
                    "language": {
                        "name": "O'zbekcha",
                        "lang_code": "uz"
                    },
                    "para": {
                        "id": 3,
                        "name": "3",
                        "start_time": "10:30",
                        "end_time": "11:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997350,
                        "updated_at": 1662457753,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 4,
                        "name": "Payshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852031,
                        "updated_at": 1659070110,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "subject": {
                        "id": 309,
                        "name": "Nodavlat notijorat tashkilotlar huquqi",
                        "kafedra_id": 15,
                        "semestr_id": 7,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1661523979,
                        "updated_at": 1661670599,
                        "created_by": 591,
                        "updated_by": 591
                    },
                    "teacher": {
                        "user_id": 4552,
                        "first_name": "JAVLONBEK",
                        "last_name": "BAXODIROV",
                        "middle_name": "BAXODIROVICH"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    }
                },
                {
                    "id": 1148,
                    "teacher_access_id": 2047,
                    "room_id": 59,
                    "para_id": 3,
                    "week_id": 5,
                    "course_id": 4,
                    "semester_id": 7,
                    "parent_id": null,
                    "lecture_id": null,
                    "time_option_id": 153,
                    "edu_semester_id": 331,
                    "edu_year_id": 16,
                    "subject_id": 286,
                    "language_id": 1,
                    "order": 1,
                    "edu_plan_id": 56,
                    "building_id": 4,
                    "subject_category_id": 1,
                    "status": 1,
                    "created_at": 1662558400,
                    "updated_at": 1663326365,
                    "created_by": 591,
                    "updated_by": 308,
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "child": [
                        {
                            "id": 1149,
                            "teacher_access_id": 2047,
                            "room_id": 59,
                            "para_id": 4,
                            "week_id": 5,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": 1148,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 286,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662558417,
                            "updated_at": 1662558417,
                            "created_by": 591,
                            "updated_by": 0,
                            "subjectCategory": {
                                "id": 1,
                                "name": "Ma'ruza",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784509,
                                "updated_at": 1643784509,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 4,
                                "name": "4",
                                "start_time": "11:30",
                                "end_time": "12:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997355,
                                "updated_at": 1662457779,
                                "created_by": 1,
                                "updated_by": 11
                            },
                            "room": {
                                "id": 59,
                                "name": "119",
                                "building_id": 4,
                                "capacity": 105,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977592,
                                "updated_at": 1662202990,
                                "created_by": 4,
                                "updated_by": 7402
                            },
                            "week": {
                                "id": 5,
                                "name": "Juma",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852242,
                                "updated_at": 1659070142,
                                "created_by": 112,
                                "updated_by": 1
                            },
                            "teacher": {
                                "user_id": 4556,
                                "first_name": "ESHMUXAMAD",
                                "last_name": "KADIROV",
                                "middle_name": "OMANTURDIYEVICH"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "seminar": [
                        {
                            "id": 1153,
                            "teacher_access_id": 2047,
                            "room_id": 94,
                            "para_id": 1,
                            "week_id": 3,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 1148,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 286,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662558535,
                            "updated_at": 1662823620,
                            "created_by": 591,
                            "updated_by": 591,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 1154,
                                    "teacher_access_id": 2047,
                                    "room_id": 94,
                                    "para_id": 2,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 1153,
                                    "lecture_id": 1148,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662558557,
                                    "updated_at": 1662823629,
                                    "created_by": 591,
                                    "updated_by": 591,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 2,
                                        "name": "2",
                                        "start_time": "09:30",
                                        "end_time": "10:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997345,
                                        "updated_at": 1662457732,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 94,
                                        "name": "208",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1662351165,
                                        "updated_at": 1662351165,
                                        "created_by": 7402,
                                        "updated_by": 0
                                    },
                                    "week": {
                                        "id": 3,
                                        "name": "Chorshanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644852021,
                                        "updated_at": 1644852021,
                                        "created_by": 112,
                                        "updated_by": 0
                                    },
                                    "teacher": {
                                        "user_id": 4556,
                                        "first_name": "ESHMUXAMAD",
                                        "last_name": "KADIROV",
                                        "middle_name": "OMANTURDIYEVICH"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 0,
                            "selectedCount": 30,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 1,
                                "name": "1",
                                "start_time": "08:30",
                                "end_time": "09:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997338,
                                "updated_at": 1651036105,
                                "created_by": 1,
                                "updated_by": 4
                            },
                            "room": {
                                "id": 94,
                                "name": "208",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1662351165,
                                "updated_at": 1662351165,
                                "created_by": 7402,
                                "updated_by": 0
                            },
                            "week": {
                                "id": 3,
                                "name": "Chorshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852021,
                                "updated_at": 1644852021,
                                "created_by": 112,
                                "updated_by": 0
                            },
                            "teacher": {
                                "user_id": 4556,
                                "first_name": "ESHMUXAMAD",
                                "last_name": "KADIROV",
                                "middle_name": "OMANTURDIYEVICH"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        },
                        {
                            "id": 1156,
                            "teacher_access_id": 2007,
                            "room_id": 64,
                            "para_id": 1,
                            "week_id": 3,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 1148,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 286,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662558606,
                            "updated_at": 1662558606,
                            "created_by": 591,
                            "updated_by": 0,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 1157,
                                    "teacher_access_id": 2007,
                                    "room_id": 64,
                                    "para_id": 2,
                                    "week_id": 3,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 1156,
                                    "lecture_id": 1148,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 286,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662558630,
                                    "updated_at": 1662558630,
                                    "created_by": 591,
                                    "updated_by": 0,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 2,
                                        "name": "2",
                                        "start_time": "09:30",
                                        "end_time": "10:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997345,
                                        "updated_at": 1662457732,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 64,
                                        "name": "210",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1650977841,
                                        "updated_at": 1662203186,
                                        "created_by": 4,
                                        "updated_by": 7402
                                    },
                                    "week": {
                                        "id": 3,
                                        "name": "Chorshanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644852021,
                                        "updated_at": 1644852021,
                                        "created_by": 112,
                                        "updated_by": 0
                                    },
                                    "teacher": {
                                        "user_id": 4560,
                                        "first_name": "FAXRIDDIN",
                                        "last_name": "MADIYEV",
                                        "middle_name": "XOSHIM UG‘LI"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 0,
                            "selectedCount": 20,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 1,
                                "name": "1",
                                "start_time": "08:30",
                                "end_time": "09:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997338,
                                "updated_at": 1651036105,
                                "created_by": 1,
                                "updated_by": 4
                            },
                            "room": {
                                "id": 64,
                                "name": "210",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977841,
                                "updated_at": 1662203186,
                                "created_by": 4,
                                "updated_by": 7402
                            },
                            "week": {
                                "id": 3,
                                "name": "Chorshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852021,
                                "updated_at": 1644852021,
                                "created_by": 112,
                                "updated_by": 0
                            },
                            "teacher": {
                                "user_id": 4560,
                                "first_name": "FAXRIDDIN",
                                "last_name": "MADIYEV",
                                "middle_name": "XOSHIM UG‘LI"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "selected": 1,
                    "selectedCount": 51,
                    "language": {
                        "name": "O'zbekcha",
                        "lang_code": "uz"
                    },
                    "para": {
                        "id": 3,
                        "name": "3",
                        "start_time": "10:30",
                        "end_time": "11:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997350,
                        "updated_at": 1662457753,
                        "created_by": 1,
                        "updated_by": 11
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 5,
                        "name": "Juma",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852242,
                        "updated_at": 1659070142,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "subject": {
                        "id": 286,
                        "name": "Mahalliy hokimiyat huquqi",
                        "kafedra_id": 15,
                        "semestr_id": 7,
                        "parent_id": 286,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1660303924,
                        "updated_at": 1660303939,
                        "created_by": 591,
                        "updated_by": 591
                    },
                    "teacher": {
                        "user_id": 4556,
                        "first_name": "ESHMUXAMAD",
                        "last_name": "KADIROV",
                        "middle_name": "OMANTURDIYEVICH"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    }
                },
                {
                    "id": 3313,
                    "teacher_access_id": 1744,
                    "room_id": 59,
                    "para_id": 1,
                    "week_id": 4,
                    "course_id": 4,
                    "semester_id": 7,
                    "parent_id": null,
                    "lecture_id": null,
                    "time_option_id": 153,
                    "edu_semester_id": 331,
                    "edu_year_id": 16,
                    "subject_id": 209,
                    "language_id": 1,
                    "order": 1,
                    "edu_plan_id": 56,
                    "building_id": 4,
                    "subject_category_id": 1,
                    "status": 1,
                    "created_at": 1662991000,
                    "updated_at": 1663327087,
                    "created_by": 601,
                    "updated_by": 308,
                    "subjectCategory": {
                        "id": 1,
                        "name": "Ma'ruza",
                        "order": 1,
                        "type": 1,
                        "status": 1,
                        "created_at": 1643784509,
                        "updated_at": 1643784509,
                        "created_by": 2,
                        "updated_by": 0
                    },
                    "child": [
                        {
                            "id": 3314,
                            "teacher_access_id": 1744,
                            "room_id": 59,
                            "para_id": 2,
                            "week_id": 4,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": 3313,
                            "lecture_id": null,
                            "time_option_id": 153,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 209,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 1,
                            "status": 1,
                            "created_at": 1662991017,
                            "updated_at": 1662991017,
                            "created_by": 601,
                            "updated_by": 0,
                            "subjectCategory": {
                                "id": 1,
                                "name": "Ma'ruza",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784509,
                                "updated_at": 1643784509,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 2,
                                "name": "2",
                                "start_time": "09:30",
                                "end_time": "10:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997345,
                                "updated_at": 1662457732,
                                "created_by": 1,
                                "updated_by": 11
                            },
                            "room": {
                                "id": 59,
                                "name": "119",
                                "building_id": 4,
                                "capacity": 105,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977592,
                                "updated_at": 1662202990,
                                "created_by": 4,
                                "updated_by": 7402
                            },
                            "week": {
                                "id": 4,
                                "name": "Payshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644852031,
                                "updated_at": 1659070110,
                                "created_by": 112,
                                "updated_by": 1
                            },
                            "teacher": {
                                "user_id": 601,
                                "first_name": "Odilbek",
                                "last_name": "Xazratqulov",
                                "middle_name": "Tursunovich"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "seminar": [
                        {
                            "id": 3315,
                            "teacher_access_id": 3439,
                            "room_id": 94,
                            "para_id": 1,
                            "week_id": 2,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 3313,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 209,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662991038,
                            "updated_at": 1666683804,
                            "created_by": 601,
                            "updated_by": 7401,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 3317,
                                    "teacher_access_id": 3439,
                                    "room_id": 94,
                                    "para_id": 2,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 3315,
                                    "lecture_id": 3313,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 209,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662991056,
                                    "updated_at": 1666683813,
                                    "created_by": 601,
                                    "updated_by": 7401,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 2,
                                        "name": "2",
                                        "start_time": "09:30",
                                        "end_time": "10:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997345,
                                        "updated_at": 1662457732,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 94,
                                        "name": "208",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1662351165,
                                        "updated_at": 1662351165,
                                        "created_by": 7402,
                                        "updated_by": 0
                                    },
                                    "week": {
                                        "id": 2,
                                        "name": "Seshanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644820316,
                                        "updated_at": 1644851771,
                                        "created_by": 112,
                                        "updated_by": 112
                                    },
                                    "teacher": {
                                        "user_id": 7479,
                                        "first_name": "Nozimbek",
                                        "last_name": "Dilboboyev",
                                        "middle_name": "Shavkat o`g`li"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 0,
                            "selectedCount": 31,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 1,
                                "name": "1",
                                "start_time": "08:30",
                                "end_time": "09:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997338,
                                "updated_at": 1651036105,
                                "created_by": 1,
                                "updated_by": 4
                            },
                            "room": {
                                "id": 94,
                                "name": "208",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1662351165,
                                "updated_at": 1662351165,
                                "created_by": 7402,
                                "updated_by": 0
                            },
                            "week": {
                                "id": 2,
                                "name": "Seshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644820316,
                                "updated_at": 1644851771,
                                "created_by": 112,
                                "updated_by": 112
                            },
                            "teacher": {
                                "user_id": 7479,
                                "first_name": "Nozimbek",
                                "last_name": "Dilboboyev",
                                "middle_name": "Shavkat o`g`li"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        },
                        {
                            "id": 3319,
                            "teacher_access_id": 2637,
                            "room_id": 64,
                            "para_id": 1,
                            "week_id": 2,
                            "course_id": 4,
                            "semester_id": 7,
                            "parent_id": null,
                            "lecture_id": 3313,
                            "time_option_id": null,
                            "edu_semester_id": 331,
                            "edu_year_id": 16,
                            "subject_id": 209,
                            "language_id": 1,
                            "order": 1,
                            "edu_plan_id": 56,
                            "building_id": 4,
                            "subject_category_id": 2,
                            "status": 1,
                            "created_at": 1662991075,
                            "updated_at": 1662991075,
                            "created_by": 601,
                            "updated_by": 0,
                            "subjectCategory": {
                                "id": 2,
                                "name": "Semenar",
                                "order": 1,
                                "type": 1,
                                "status": 1,
                                "created_at": 1643784526,
                                "updated_at": 1643784526,
                                "created_by": 2,
                                "updated_by": 0
                            },
                            "child": [
                                {
                                    "id": 3320,
                                    "teacher_access_id": 2637,
                                    "room_id": 64,
                                    "para_id": 2,
                                    "week_id": 2,
                                    "course_id": 4,
                                    "semester_id": 7,
                                    "parent_id": 3319,
                                    "lecture_id": 3313,
                                    "time_option_id": null,
                                    "edu_semester_id": 331,
                                    "edu_year_id": 16,
                                    "subject_id": 209,
                                    "language_id": 1,
                                    "order": 1,
                                    "edu_plan_id": 56,
                                    "building_id": 4,
                                    "subject_category_id": 2,
                                    "status": 1,
                                    "created_at": 1662991087,
                                    "updated_at": 1662991087,
                                    "created_by": 601,
                                    "updated_by": 0,
                                    "subjectCategory": {
                                        "id": 2,
                                        "name": "Semenar",
                                        "order": 1,
                                        "type": 1,
                                        "status": 1,
                                        "created_at": 1643784526,
                                        "updated_at": 1643784526,
                                        "created_by": 2,
                                        "updated_by": 0
                                    },
                                    "language": {
                                        "name": "O'zbekcha",
                                        "lang_code": "uz"
                                    },
                                    "para": {
                                        "id": 2,
                                        "name": "2",
                                        "start_time": "09:30",
                                        "end_time": "10:30",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1642997345,
                                        "updated_at": 1662457732,
                                        "created_by": 1,
                                        "updated_by": 11
                                    },
                                    "room": {
                                        "id": 64,
                                        "name": "210",
                                        "building_id": 4,
                                        "capacity": 32,
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1650977841,
                                        "updated_at": 1662203186,
                                        "created_by": 4,
                                        "updated_by": 7402
                                    },
                                    "week": {
                                        "id": 2,
                                        "name": "Seshanba",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1644820316,
                                        "updated_at": 1644851771,
                                        "created_by": 112,
                                        "updated_by": 112
                                    },
                                    "teacher": {
                                        "user_id": 4688,
                                        "first_name": "AKMALJON",
                                        "last_name": "AKRAMOV",
                                        "middle_name": "ANVARJON O‘G‘LI"
                                    },
                                    "building": {
                                        "id": 4,
                                        "name": "3 Bino(ADO)",
                                        "order": 1,
                                        "status": 1,
                                        "created_at": 1643663440,
                                        "updated_at": 1643663440,
                                        "created_by": 3,
                                        "updated_by": 0
                                    }
                                }
                            ],
                            "selected": 1,
                            "selectedCount": 20,
                            "language": {
                                "name": "O'zbekcha",
                                "lang_code": "uz"
                            },
                            "para": {
                                "id": 1,
                                "name": "1",
                                "start_time": "08:30",
                                "end_time": "09:30",
                                "order": 1,
                                "status": 1,
                                "created_at": 1642997338,
                                "updated_at": 1651036105,
                                "created_by": 1,
                                "updated_by": 4
                            },
                            "room": {
                                "id": 64,
                                "name": "210",
                                "building_id": 4,
                                "capacity": 32,
                                "order": 1,
                                "status": 1,
                                "created_at": 1650977841,
                                "updated_at": 1662203186,
                                "created_by": 4,
                                "updated_by": 7402
                            },
                            "week": {
                                "id": 2,
                                "name": "Seshanba",
                                "order": 1,
                                "status": 1,
                                "created_at": 1644820316,
                                "updated_at": 1644851771,
                                "created_by": 112,
                                "updated_by": 112
                            },
                            "teacher": {
                                "user_id": 4688,
                                "first_name": "AKMALJON",
                                "last_name": "AKRAMOV",
                                "middle_name": "ANVARJON O‘G‘LI"
                            },
                            "building": {
                                "id": 4,
                                "name": "3 Bino(ADO)",
                                "order": 1,
                                "status": 1,
                                "created_at": 1643663440,
                                "updated_at": 1643663440,
                                "created_by": 3,
                                "updated_by": 0
                            }
                        }
                    ],
                    "selected": 1,
                    "selectedCount": 51,
                    "language": {
                        "name": "O'zbekcha",
                        "lang_code": "uz"
                    },
                    "para": {
                        "id": 1,
                        "name": "1",
                        "start_time": "08:30",
                        "end_time": "09:30",
                        "order": 1,
                        "status": 1,
                        "created_at": 1642997338,
                        "updated_at": 1651036105,
                        "created_by": 1,
                        "updated_by": 4
                    },
                    "room": {
                        "id": 59,
                        "name": "119",
                        "building_id": 4,
                        "capacity": 105,
                        "order": 1,
                        "status": 1,
                        "created_at": 1650977592,
                        "updated_at": 1662202990,
                        "created_by": 4,
                        "updated_by": 7402
                    },
                    "week": {
                        "id": 4,
                        "name": "Payshanba",
                        "order": 1,
                        "status": 1,
                        "created_at": 1644852031,
                        "updated_at": 1659070110,
                        "created_by": 112,
                        "updated_by": 1
                    },
                    "subject": {
                        "id": 209,
                        "name": "Xalqaro xususiy huquq",
                        "kafedra_id": 20,
                        "semestr_id": 5,
                        "parent_id": null,
                        "is_deleted": 0,
                        "order": 1,
                        "status": 1,
                        "created_at": 1655183474,
                        "updated_at": 1655183540,
                        "created_by": 601,
                        "updated_by": 601
                    },
                    "teacher": {
                        "user_id": 601,
                        "first_name": "Odilbek",
                        "last_name": "Xazratqulov",
                        "middle_name": "Tursunovich"
                    },
                    "building": {
                        "id": 4,
                        "name": "3 Bino(ADO)",
                        "order": 1,
                        "status": 1,
                        "created_at": 1643663440,
                        "updated_at": 1643663440,
                        "created_by": 3,
                        "updated_by": 0
                    }
                }
            ],
            "selected": 1,
            "selectedCount": 51
        },
        loading: false
    }*/

    const selectTimeTable = async (id: number) => {
        try {
            let formdata = new FormData();
            formdata.append("time_table_id", String(id));
            const response = await instance({ url: "/student-time-tables", method: "POST", data: formdata })

            if (response.data.status === 1) {
                setReFetch(prevState => !prevState)
                message.success(response.data?.message)
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }


    const selectTimeOption = async () => {
        try {
            if (!data.id) {
                message.error("Ma'lumot yuborishda xatolik!")
                return;
            }
            let formdata = new FormData();
            formdata.append("time_option_id", String(data?.id))
            const response = await instance({ url: "/student-time-options?", method: "POST", data: formdata })

            if (response.data.status === 1) {
                setReFetch(prevState => !prevState)
                message.success(response.data?.message)
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }

    React.useEffect(() => {

        if (data.timeTables?.length) {
            const filterOnlysemenarSubjects = data.timeTables.filter(e => !Boolean(e.parent_id) && !Boolean(e.lecture_id) && e.subject_category_id !== 1);

            let new_semenars: Record<string, /*ITimeTable*/any[]> = {};

            if (filterOnlysemenarSubjects.length) {
                filterOnlysemenarSubjects.forEach((element) => {

                    if (new_semenars.hasOwnProperty(element.subject_id)) {
                        new_semenars[element.subject_id].push(element);
                    } else {
                        new_semenars[element.subject_id] = [element]
                    }
                })

            }

            setSemenars(new_semenars);
        }
    }, [data.timeTables])

    const checkSemenarIsSelected = (semenar: ITimeTable[]) => {

        if (!semenar.length) return false;

        const isSelectded = semenar.some(e => Number(e.selected) === 1);

        return isSelectded;
    }

    return (
        <Card>
                <Spin spinning={loading} >
                    <div className={styles.time_table_variants}>
                        <Title color="">
                            Variant - {data?.name}{data?.selected ? 
                            <span><i style={{ color: '#1967D2', fontSize: '12px' }} >( Tanlangan )</i></span> 
                            : null}
                        </Title>
                        <div>
                            <span className={styles.selected_count} style={Boolean(data.selected) ? { backgroundColor: "#1F92FD" } : { backgroundColor: "#c26c03" }} >
                                Tanlandi: {data?.selectedCount} / {data?.capacity}
                            </span>
                            {
                                !Boolean(data.selected) ?
                                    <span className={`${styles.selected_count} ms-3 p-0`} style={Number(data.selectedCount) < Number(data.capacity) ? { backgroundColor: "#1F92FD" } : { backgroundColor: '#f14646' } }>
                                    {
                                        Number(data.selectedCount) < Number(data.capacity) ? 
                                            <Popconfirm placement="topRight" title={"Tanlamoqchimisiz ?"} onConfirm={selectTimeOption} okText="Ha" cancelText="Yo'q">
                                                <Button type="primary" size="small" className="px-4">Tanlash</Button>
                                            </Popconfirm> 
                                            : <div className="px-2 py-1">Bu variant uchun joy to'lgan</div>
                                        }
                                    </span>
                                : null
                            }
                            {
                                // !Boolean(data.selected) ?
                                //     (Number(data.selectedCount) < Number(data.capacity) ?
                                //         <Popconfirm placement="topRight" title={"Tanlamoqchimisiz ?"} onConfirm={selectTimeOption} okText="Ha" cancelText="Yo'q">
                                //             <Button type="primary" size="small" className="ms-3 px-4">Tanlash</Button>
                                //         </Popconfirm> : <Tag color="red">Bu variant uchun joy to'lgan</Tag>) : null
                            }
                        </div>
                    </div>
                    <NoticeTimeTableSelect className="mt-2" theme={theme}>
                        <FaArrowLeft onClick={() => history.goBack()} color="#5A8DEE" size={13} /> 
                        <span className="mx-2" style={{ fontSize: 13, color: "#000" }}>Dars jadval tanlash</span>
                    </NoticeTimeTableSelect>
                    <p style={{ fontSize: '14px', border: "1px solid #DADCE0", borderRadius: "4px" }} className="py-1 px-2 mt-3">
                        <strong style={{ color: '#C26C03' }} >Eslatma !</strong><br />
                        Quyidagi variantni tanlash orqali bu variantga tegishli barcha ma'ruza dars jadvallarini tanlagan hisoblanasiz. Bunda keltirilgan fanlarning seminar dars jadvalini tanlashingiz mumkin.
                    </p>
                    <div className={styles.selectible_time_table_container}>
                        <div className="px-3">
                            {
                                data.timeTables?.length ?
                                    data.timeTables.map((element: any) => {
                                        if (!Boolean(element.parent_id) && !Boolean(element.lecture_id) && element.subject_category_id !== 1) return null;

                                        const isSelectded = checkSemenarIsSelected(element.seminar);

                                        return (
                                            <Card key={element.id} className="mt-3">
                                                    <div className={styles.time_table_subjects}>
                                                        <span>{element.subject?.name}&nbsp;&nbsp;
                                                            <i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{element?.language?.name})</i>
                                                        </span>
                                                    </div>
                                                    <Row gutter={[12, 12]} className="mt-3" align="stretch">
                                                        <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                                                            <TimeTableVariantItem isSelected={Boolean(data.selected)} selectTimeTable={selectTimeTable} element={element} />
                                                        </Col>
                                                        {
                                                            element?.seminar?.map((parentElement: any) => {
                                                                return (
                                                                    <Col xl={6} lg={6} md={8} sm={12} xs={24} key={parentElement.id}>
                                                                        <TimeTableVariantItem isSelected={Boolean(data.selected)} selectTimeTable={selectTimeTable} element={parentElement} isSelectedSeminar={isSelectded} />
                                                                    </Col >
                                                                )
                                                            })
                                                        }
                                                    </Row >
                                            </Card>
                                        )
                                    }) : <EmptyData data={data.timeTables ? data.timeTables : []} loading={loading}/>
                            }
                            {
                                Object.entries(semenars)?.map(([key, obj]: [string, ITimeTable[]]) => {

                                    if (!obj.length) return null;

                                    const isSelectded = checkSemenarIsSelected(semenars[key]);

                                    return (
                                        <Card key={key} className="mt-3">
                                                <div className={styles.time_table_subjects}>
                                                    <span>{obj[0]?.subject?.name}&nbsp;&nbsp;<i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{obj[0]?.language?.name})</i></span>
                                                </div>
                                                <Row gutter={[12, 12]} className="mt-3" >
                                                    {obj.map((semenarItem) => {

                                                        return (
                                                            <Col xl={6} lg={6} md={8} sm={12} xs={24} key={semenarItem.id}>
                                                                <TimeTableVariantItem isSelected={Boolean(data.selected)} selectTimeTable={selectTimeTable} element={semenarItem} isSelectedSeminar={isSelectded} />
                                                            </Col >
                                                        )
                                                    })}
                                                </Row >
                                        </Card>
                                    )
                                })
                            }

                        </div>
                    </div>
                </Spin >
        </Card>
    )
}

export default TimeTableBySubjects;