import styled from "styled-components";
import { sidebar_lg, sidebar_md } from 'assets/variables';

export const SidebarBoxUi = styled.div<{theme: any, ui: any}>`
    background-color: ${p => p.theme.name === 'black' ? p.theme.card : 'white'};
    padding: 1rem 0;
    position:fixed;
    height: 100vh;
    /* width:${props => props.ui.sidebar === "lg" ? sidebar_lg : sidebar_md}; */
    width: 260px;
    box-shadow: ${ props => props.theme.name === "white" ? "0px 10px 30px rgb(209 213 223 / 50%)" : "rgba(0, 0, 0, 0.4) 1px 0px 10px" };
    /* box-shadow: 0px 10px 30px ${p => p.theme.name === 'black' ? p.theme.element : p.theme.text}; */
    /* -webkit-box-shadow: 0px 10px 30px rgb(209 213 223 / 50%); */
    transition: all 0.1s ease-in-out;
    z-index: 10;
    left: ${props => props.ui.sidebar === "lg" ? 0 : -260}px;
`

export const LinkBoxUi = styled.div`
    width: 100%;
    height: auto;
    padding: 0 .5rem;
    background-color: transparent;
`

export const LinkItemUi = styled.div<{ active: boolean, theme: any, sidebar: string }>`

        margin: 8px auto;
        width: 94%;
        padding: 3px 10px;
        border: 1px solid ${props => props.theme.element};
        border-radius: 4px;
        cursor:pointer;
        color:${props => props.active ? props.theme.active_text : props.theme.text};
        background-color: ${props => props.active ? props.theme.element : "transparent"};
        &:hover {
            background-color: ${props => props.theme.element};
        }
        & > svg {
            vertical-align:middle;
            color: ${props => props.active ? props.theme.active_icon : props.theme.icon};
        }
        & > span {
            vertical-align:middle;
            font-size: 16px;
            margin-left: 16px;
            font-weight: 400;
        }
        @media (max-width:1500px){
            margin: 6px auto;
            width: 94%;
            padding: 2px 10px;
            border: 1px solid ${props => props.theme.element};
            border-radius: 4px;
            & > svg {
                vertical-align:middle;
                color: ${props => props.active ? props.theme.active_icon : props.theme.icon};
            }
            & > span {
                vertical-align:middle;
                font-size: 14px;
                margin-left: 12px;
            }
        }

`

