import { SelectStudentTheme } from "config/theme/colors";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { BiX, BiCalendarPlus } from 'react-icons/bi';
import { CustomModalBox } from "./styled";
import "./styles.scss";


type PropsType = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    saveEvent: (text: string) => void,
    selectedDate: string
}

const CustomModal: FC<PropsType> = ({ isOpen, setIsOpen, saveEvent, selectedDate }): JSX.Element => {

    const theme = SelectStudentTheme();
    const [text, setText] = useState<string>('');

    useEffect(() => {
        if (!isOpen) {
            setText('');
        }
    }, [isOpen])

    return (
        <div className="custom_modal">
            <CustomModalBox isOpen={isOpen} theme={theme} >
                <form onSubmit={e => e.preventDefault()}>
                    <div className="custom_modal_header">
                        <span className="d-flex align-items-center"><BiCalendarPlus size={14} />&nbsp; {selectedDate}</span>
                        <BiX size={18} onClick={() => setIsOpen(false)} />
                    </div>
                    <textarea autoCorrect="true" value={text} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)} />
                    <button onClick={() => { saveEvent(text); }}>Add Activity</button>
                </form>
            </CustomModalBox>
        </div>
    )
}


export default CustomModal;