import styled from "styled-components";

export const Wrapper = styled.div<{ theme: any }>`
*{
    box-sizing: border-box !important;
    border: none !important;
    color: ${props => props.theme.text};
}

.ant-rate{
    color:#fadb14
}
.inputs{
    justify-content: end;
    .ant-select-selector{
        background-color: ${props=>props.theme.element} !important;
        border: 1px solid ${props=>props.theme.active_element} !important;
        border-radius: 0.25rem !important;
        color: ${props=>props.theme.text} !important;
        min-width: 260px;
        input{
            border: none !important;
        }
    }
    .ant-select-clear, .ant-select-item-empty, .ant-empty-image, #rc_select_0_list{
        background-color: ${props=>props.theme.element} !important;
    }
    .ant-select-item-empty{
        background-color: ${props=>props.theme.element} !important;
    }
    input{
        background-color: ${props=>props.theme.element};
        border: 1px solid ${props=>props.theme.active_element} !important;
        border-radius: 0.25rem !important;
        color: ${props => props.theme.text};
        width: 260px;
        margin-left: 1rem;
        &::placeholder{
            font-size: 15px;
            color: ${props => props.theme.text} !important;
        }
    }
}
.table{
    tbody{
        border: 1px solid ${props => props.theme.active_element};
        .upload{
            background-color: ${props => props.theme.element};
        }
    }
    tr:nth-child(2n-1){
        background-color: ${props => props.theme.element};
        .upload{
            background-color: ${props => props.theme.card};
        }
    }
    thead tr{
        border-bottom: 4px solid ${props => props.theme.card};
    }
    th{
        background-color: ${props=>props.theme.active_element};
        color: ${props => props.theme.header_text};
        font-weight: 500;
        &:first-child{
            border-top-left-radius: 5px !important;
        }
        &:last-child{
            border-top-right-radius: 5px !important;
        }
    }
    td{
        color: ${props => props.theme.text};
    }
    .hover:hover{
        background-color: ${props=>props.theme.active_element};
    }
}

@media (max-width: 540px){
    .inputs{
        flex-direction: column;
        justify-content: center;
        .ant-input, .ant-select-selector{
            min-width: 100%;
            margin: 0
        }
        .ant-input{
            margin-top: 0.5rem;
        }
    }
}
`;

export const Title = styled.h5<{color: string}>`
    font-size: 1.2rem;
    font-weight: 450px;
    margin: .5rem 0;
    color: ${props => props.color};
`