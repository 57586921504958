import { Spin } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { IClub } from "models/club";
import { Card, CardBody } from "reactstrap";
import "../styles.scss";
import { IWeek } from "models/others";
import { PageNotice } from "components/Notice";
import { match, useHistory, useRouteMatch } from "react-router-dom";
import { FILE_URL } from "config/utils";
import no_image from 'assets/images/no_image.svg'
import { EmptyData } from "components/EmptyTable";

const Clubs = () => {
    const match: match<any> | null = useRouteMatch("/club_selection/:id");
    const history = useHistory();
    const weeks = useGetAllData({ url: `/weeks`, isCall: 'auto' }) as TypeReturnUseGetAllData<IWeek>;
    const clubs = useGetAllData({ url: `/clubs?expand=clubTimes,clubCategory,description&filter=${JSON.stringify({ club_category_id: match?.params?.id })}`, isCall: 'auto' }) as TypeReturnUseGetAllData<IClub>;

    return (
        <Spin spinning={weeks.loading && clubs.loading}>
            <Card style={{ minHeight: clubs.items.length ? 'auto' : 500 }}>
                <CardBody>
                <PageNotice showIcon={true} isTitle={false} notice={<span style={{ fontSize: "14px", fontWeight: 500 }}>{clubs.items[0]?.clubCategory?.name}</span>} />
                    <div style={{ backgroundColor: "#F5F7F9" }} className="p-2 mt-3"  >
                        {
                            clubs.items.length ?
                                clubs.items.map((element) => {
                                    return (
                                        <Card key={element.id} className="mt-2 cursor-pointer" onClick={() => history.push(`/club/${element?.id}`)} >
                                            <CardBody>
                                                <div className="club_selection_card">
                                                    <div className="student_club_card_image">
                                                        <img src={element.image ? FILE_URL + element?.image : no_image} alt="Rasm topilmadi" />
                                                    </div>
                                                    <div className="student_club_card_info">
                                                        <p>
                                                            {element?.name}
                                                            <span>{element?.description}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )
                                }) : null
                        }
                        <EmptyData data={clubs.items} loading={clubs.loading} />
                    </div>
                </CardBody>
            </Card >
        </Spin>
    )

}


export default Clubs;