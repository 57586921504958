import { SelectStudentTheme } from "config/theme/colors";
import React, { FC } from "react";
import { Button, Col, Divider, message, Popconfirm, Row, Spin, Typography } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { ITimeOption } from "models/others";
import useDeleteOne from "hooks/useDeleteOne";
import styles from '../styles.module.scss';
import "../common/style.scss";
import { BiBookOpen, BiListPlus, BiTime, BiUserVoice } from "react-icons/bi";
import { Card, CardBody } from "reactstrap";
import instance from "config/_axios";
import isHasAuthRoute from "utils/isHasRoute";
import { delete_data } from "services";
import { useHistory } from "react-router";
import { FaTrashAlt } from "react-icons/fa";

const { Text } = Typography;

const TimeTableVariantsV1: FC<{ eduSemestrId: number, reFetch: () => void }> = ({ eduSemestrId, reFetch }): JSX.Element => {

    const theme: any = SelectStudentTheme();
    const history = useHistory();
    // const match: match<any> | null = useRouteMatch("/time_table_variants/:edu_semester_id");
    const _delete = useDeleteOne();
    const [refetch, setRefetch] = React.useState<boolean>(false)
    const timeOptions = useGetAllData({
        url: `/time-options?expand=selected,selectedCount,faculty,eduSemester.semestr,timeTables.child,timeTables.child.week,timeTables.child.para,timeTables.child.subjectType,timeTables.child.subjectCategory,timeTables.child.teacher,timeTables.child.subject,timeTables.week,timeTables.para,timeTables.subjectType,timeTables.subjectCategory,timeTables.teacher,timeTables.subject&filter=${JSON.stringify({ edu_semestr_id: eduSemestrId })}`,
        perPage: 0,
        isCall: 'auto',
        refetch: [refetch]
    }) as TypeReturnUseGetAllData<ITimeOption>;


    const selectTimeOption = async (id: number) => {
        try {
            let formdata = new FormData();
            formdata.append("time_option_id", String(id))
            const response = await instance({ url: "/student-time-options?", method: "POST", data: formdata })

            if (response.data.status === 1) {
                setRefetch(prevState => !prevState)
                reFetch();
                message.success(response.data?.message)
            }
        } catch (error: any) {
            message.warning(error?.response?.data?.errors[0]);
        }
    }


    const selectedCount = (): number => {
        return timeOptions?.items?.filter((el) => el?.selected == 1)?.length
    }

    return (
        <Spin spinning={timeOptions.loading} >
            <div className="not_user_select px-2">
                <div className="d-flex justify-content-between">
                    {/* <h6 className="d-f" color={theme.header_text} ><Button type="link" icon={<FaArrowLeft className="me-1" />} onClick={() => {
                        history.goBack();
                    }} /> Dars jadval</h6> */}
                </div>
                <Divider className="my-2" ></Divider>
                <div style={{ backgroundColor: "#EFF2F4", padding: "12px" }} className="px-10" >
                    {/* <Divider orientation="left" style={{ color: 'grey' }}>Dars jadval tanlash.</Divider> */}
                    <Row gutter={[12, 12]}>
                        <Col xl={24} xs={24}>
                            <Card>
                                <CardBody>
                                    <p style={{ fontSize: '14px' }}>
                                        Sizda tanlov fani mavjud emas. Iltimos tanlov fanini tanlang!
                                        <strong className="ms-1" style={{ color: '#C26C03' }} >Eslatma !</strong>
                                        &nbsp;<Text type="success">Tanlash <BiListPlus size={22} /> belgisini bosish orqali amalga oshiriladi. </Text>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        {
                            timeOptions.items?.map((element) => {
                                return (
                                    <Col key={element.id} xl={6} lg={6} md={12} sm={12} xs={24}>
                                        <div className={styles.link_variant_card}>
                                            <Card>
                                                <CardBody style={Boolean(element.selected) ? { border: "1px solid #1F92FD", borderRadius: "4px" } : {}}>
                                                    <div className={`d-f ${(isHasAuthRoute("student-time-option_create") && selectedCount() == 0) || (isHasAuthRoute("student-time-option_delete") && element.selected == 1) ? 'justify-content-between' : 'justify-content-end'}`}>
                                                        {isHasAuthRoute("student-time-option_create") && selectedCount() == 0 ? <Popconfirm title="Fan tanlash!" onConfirm={() => selectTimeOption(element?.id)}>
                                                            <Button type="link" icon={<BiListPlus size={24} />}></Button>
                                                        </Popconfirm> :
                                                            isHasAuthRoute("student-time-option_delete") && element.selected == 1 ?
                                                                <Popconfirm onConfirm={() => delete_data("/student-time-options", element?.id)} title={"Delete!"}>
                                                                    <Button type="link" danger>
                                                                        <FaTrashAlt />
                                                                    </Button>
                                                                </Popconfirm> : ''}
                                                        <span className={styles.selected_count} style={Boolean(element.selected) ? {
                                                            backgroundColor: "#1F92FD",
                                                            fontSize: "12px",
                                                            borderRadius: "4px",
                                                            color: "white",
                                                            padding: "6px 6px 5px 6px",
                                                            height: "100%",
                                                            marginRight: "6px",
                                                            textDecoration: "none"
                                                        } : {
                                                            backgroundColor: "#c26c03",
                                                            fontSize: "12px",
                                                            borderRadius: "4px",
                                                            color: "white",
                                                            padding: "6px 6px 5px 6px",
                                                            height: "100%",
                                                            marginRight: "6px",
                                                            textDecoration: "none"
                                                        }} >Tanlandi: {element?.selectedCount} / {element?.capacity}</span>
                                                    </div>
                                                    <hr />
                                                    <div className={styles.time_table_variants}>
                                                        {<span>{element.timeTables?.filter((item) => item?.subjectType == 6)[0]?.subject?.name}</span>}
                                                    </div>
                                                    {
                                                        element.timeTables?.filter((item) => item?.subjectType === 6)?.map((element) => {
                                                            return (
                                                                <div key={element.id} className={styles.time_table_select_card} >
                                                                    <span><BiTime />{element.week?.name} / {element.para?.start_time}-{element.para?.end_time} / {element.subjectCategory?.name}</span>
                                                                    <span><BiBookOpen />{element.subject?.name}</span>
                                                                    <span><BiUserVoice />{element.teacher?.last_name}&nbsp;{element.teacher?.first_name}.&nbsp;{String(element.teacher?.middle_name).charAt(0).toUpperCase()}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
        </Spin >
    );
}


export default TimeTableVariantsV1;



/**
student-time-table_create
student-time-table_delete
student-time-table_index
student-time-table_view
 */