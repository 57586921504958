import styled from "styled-components";

export const Wrapper = styled.div<{theme: any}>`
.ant-breadcrumb a, .ant-breadcrumb span{
  color: ${p => p.theme.text};
  &:hover{
    color: ${p => p.theme.blue};
  }
}
.goBackBtn{
  color: ${p => p.theme.text};
  &:hover{
    color: ${p => p.theme.blue};
  }
}
`