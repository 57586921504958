import { Button, Col, Empty, Modal, Row, Tag } from "antd";
import { AxiosError } from "axios";
import instance from "config/_axios";
import { Title, Wrapper } from "pages/styles/style";
import { FC, useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { SelectStudentTheme } from "config/theme/colors";
import { useTranslation } from "react-i18next";
import { AppealType } from "./type";
import moment from "moment";
import { asyncN } from "utils/notifications";
import isHasAuthRoute from "utils/isHasRoute";
import { useHistory } from "react-router-dom";


const ExamAppeals: FC = (): JSX.Element => {
    const theme = SelectStudentTheme();
    const { t } = useTranslation();
    const history=useHistory();
    const [appeals, setAppeals] = useState<Array<AppealType>>([]);
    const [modal, setModal] = useState<{ open: boolean, text: string }>({ open: false, text: "" });

    const appealTypes1 = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Asosli</Tag>;
    const appealTypes2 = <Tag color="#FCE8E6" style={{ color: '#C5221F', minWidth: '120px', textAlign: 'center' }} >Asossiz</Tag>;
    const appealTypes3 = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Texnik</Tag>;
    const appealTypes4 = <Tag color="#E8F0FE" style={{ color: '#1A73E8', minWidth: '120px', textAlign: 'center' }} >Asosli va Texnik</Tag>;
    const appealTypesDef = <Tag color="#E8F0FE" style={{ color: '#1A73E8', minWidth: '120px', textAlign: 'center' }} >Jarayonda</Tag>;


    const appealTypes = (status: number) => {

        switch (status) {
            case 1:
                return appealTypes1;
            case 2:
                return appealTypes2;
            case 3:
                return appealTypes3;
            case 4:
                return appealTypes4;
            default:
                return appealTypesDef;
        }
    }

    useEffect(() => {
        (
            async () => {
                if(isHasAuthRoute("exam-appeal_index")){
                    try {
                        const resp = await instance({
                            method: 'GET'
                            , "url": '/exam-appeals?expand=exam.examType,subject'
                        })
                        if (resp?.data?.status == 1) {
                            setAppeals(resp?.data?.data?.items)
                        } else {
                            asyncN("error", "read", resp?.data?.message)
                        }
                    } catch (err: any) {
                        asyncN("error", "read", err?.response ? err?.response?.data?.message : err?.message)
                    }
                }
            }
        )()
    }, [])

    
    return (
        <Wrapper>
            <Row gutter={24}>
                <Col span={12}>
                    <Title color={theme.header_text} >{t("Appeals")}</Title>
                </Col>
                <Col span={24}>
                    {isHasAuthRoute("exam-appeal_index")?<Table className="table table-bordered table-striped">
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>Imtihon nomi</Th>
                                <Th>Fan nomi</Th>
                                <Th>Apelatsiya matni</Th>
                                <Th>Qoldirilgan vaqti</Th>
                                <Th>Turi</Th>
                                {isHasAuthRoute("exam-appeal_view")?<Th></Th>:''}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                appeals?.map((item: AppealType, i: number) =>
                                    <Tr>
                                        <Td>
                                            {i + 1}
                                        </Td>
                                        <Td style={{verticalAlign:"middle"}}>{item?.exam?.name}</Td>
                                        <Td style={{verticalAlign:"middle"}}>{item?.subject?.name}</Td>
                                        <Td style={{verticalAlign:"middle"}}>{item?.appeal_text?.length > 90 ? <>
                                            {item?.appeal_text?.substring(0, 90)}<Button onClick={() => setModal({ open: true, text: item?.appeal_text })} className="p-0 m-0" type="link">...</Button></> : item?.appeal_text}</Td>
                                        <Td style={{verticalAlign:"middle"}}>{moment.unix(item?.created_at).format("DD/MM/YYYY HH:mm:SS")}</Td>
                                        <Td style={{verticalAlign:"middle"}}>{appealTypes(item?.type)}</Td>
                                        {isHasAuthRoute("exam-appeal_view")?<Td>
                                            <Button type="primary" onClick={()=>{
                                                history.push(`/student-exams/${item?.subject_id}/exam-result/${item?.exam_id}`)
                                            }}>Ko'rish</Button>
                                        </Td>:''}
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>:<Empty/>}
                </Col>
                <Modal title="Apeal text" visible={modal?.open} onCancel={() => setModal({ open: false, text: "" })} footer={false}>
                    {modal?.text}
                </Modal>
            </Row>
        </Wrapper>
    )
}

export default ExamAppeals