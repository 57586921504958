import { Spin } from "antd";
import { BiArrowToBottom, BiLinkExternal } from "react-icons/bi";
import useGetOneData, { ReturnTypeUseGetOneData } from "hooks/useGetOneData";
import { statuteDocuments } from "./utils";
import "./styles.scss";
import { IStudent } from "models/user";


const StatuteInHomePage = () => {

    const _data = useGetOneData({ url: `students/me`, isCall: 'auto' }) as ReturnTypeUseGetOneData<IStudent>;

    return (
        <Spin spinning={_data.loading}>
            <div className="statute_container_hompage">
                {
                    statuteDocuments.map((element) => {
                        if (_data?.data?.edu_type_id === 1 && element.id === 5) return;
                        return (
                            <div key={element.id} className="statute_box mb-3">
                                <span className="statute_name">{element.name}</span>
                                <span className="statute_actions_homepage">
                                    <a href={element.file} className="me-3" target="_blank"><BiLinkExternal color="#1a73e8" size={16} /></a>
                                    <a href={element.file} download={String(element.name)}><BiArrowToBottom color="#40427f" size={18} /></a>
                                </span>
                            </div>
                        )
                    })
                }
            </div>
        </Spin>
    )

}


export default StatuteInHomePage;