/** @format */

import styled from "styled-components";

export const HomeCardMenuUi = styled.div<{ theme: any }>`
  border: 1px solid ${(props) => props.theme.element};
  padding: 10px;
  width: 150px;

  /* &:not */
`;

export const Wrapper = styled.div<{ theme: any }>`
  .row {
    ::-webkit-scrollbar {
      height: 4px !important;
    }
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.card};
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.element};
    }
    a {
      background-color: ${(props) => props.theme.element};
      /* border: 1px solid; */
      height: 160px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      text-decoration: none;
      color: #262626;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        background-color: ${(props) => props.theme.active_element};
      }
      h1 {
        color: ${(props) => props.theme.blue};
        padding-bottom: 4px;
      }
    }
  }

  .message{
    padding: 0 1rem;
  }

  @media (max-width: 720px) {
    .row {
      a{
      }
    }
    .message{
      padding: 0;
      margin-top: 1rem;
    }
  }
  @media (max-width: 576px) {
    .row {
      a {
        height: 140px;
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(6) {
          display: none;
        }
      }
    }
  }
`;
