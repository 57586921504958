import styled from "styled-components";



export const UnreadMessageItem = styled.div`

    width: 100%;
    margin: 10px auto ;
    padding: 5px 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.2);

    & > div {
        border-bottom: 1px solid #5A8DEE;
        display: flex;
        justify-content:space-between;
        padding: 4px 2px;
         & > span {
             font-size: 13px;
             font-weight: 400;
             color: #000;

             & > span {
                 font-weight:400;
                 font-size:11px;
             }
         }
         & > button {
             padding: 1px 6px;
             border: none;
             border-radius:2px;
             font-size: 12px;
             background-color:#5A8DEE;
             color:white;

             &:hover {
                 background-color:#6F9BEE;
             }
         }
    }

    p {
        font-size: 13px;
        padding: 2px;
    }
`