/** @format */

import { RoutesTypeSubmenu } from "./types";
import { FaBriefcase, FaVoteYea } from "react-icons/fa";
import {
  BiBlanket,
  BiCalendarEvent,
  BiCalendarCheck,
  BiBookBookmark,
  BiFileFind,
  BiHomeAlt,
  BiHelpCircle,
  BiCategory,
} from "react-icons/bi";
import Login from "pages/login";
import StudentLibrary from "pages/library";
import StudentLibraryBooks from "pages/library/crud/books";
import StudentSubjects from "pages/subjects";
import StudentExams from "pages/student_exams";
import StudentProfileHomePage from "pages/student_profile_homepage";
import StudentAttendance from "pages/attendance";
import Note from "pages/note";
// import ExamQuestions from "pages/student_exams/components/examQuestions";
import Exercisse from "pages/subjects/pages/exercisse";
import SubjectDetails from "pages/subjects/pages/subject_details";
import Documents from "pages/documents";
import Docs from "pages/documents/docs";
import TeacherStatistic from "pages/teacher_statistics";
import Notification from "pages/notification";
import SubjectContent from "pages/subjects/pages/subject_content";
import SubjectExams from "pages/student_exams/components/exams";
import Result from "pages/student_exams/components/result";
import QrLogin from "pages/login/qr_login";
import FAQ from "pages/FAQ";
import StudentExamView from "pages/student_exams/components/view";
import Profile from "pages/profile";
import ExamAppeals from "pages/appeals/exam_appeals";
import Dormitory from "pages/dormitory";
import InteractiveServices from "pages/interactive_services";
import SubjectTopic from "pages/SubjectSelection/subject_topic";
import TimeTables from "pages/lesson_schedule/pages/time_tables";
import StudentLessonScheduleVariant from "pages/time_table_variant_version";
import TimeTableVariants from "pages/time_table_variant_version/pages/time_table_variants";
import TimeTableBySubjects from "pages/time_table_variant_version/pages/time_tables_by_subjects";
import TimeTableVariantsV1 from "pages/time_table_variant_version_v_1/pages/time_table_variants";
import TimeTablesV1 from "pages/lesson_schedule_v1/pages/time_tables";
import ClubCategory from "pages/training/pages/club_category";
import StudentClub from "pages/training";
import Clubs from "pages/training/pages/clubs";
import SubjectContent_1 from "pages/subjects/pages/subject_content_1";
import Club from "pages/training/pages/club";
import ExamControl from "pages/exam/exam_control";
import ExamControlView from "pages/exam/exam_control/view";
import { RiFileEditLine } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsViewList } from "react-icons/bs";
import TeacherProfile from "pages/teacher_profile";
import SubjectSelection from "pages/SubjectSelection";
import ExamView from "pages/exam";
import StudentLessonSchedule from "pages/lesson_schedule";
import { Divider } from "antd";
import StdExam from "pages/std_exam";

export const public_routes: Array<RoutesTypeSubmenu> = [
  {
    name: "Login",
    path: "/",
    component: Login,
    config: {
      key: "unlock",
      icon: FaBriefcase,
      structure: "nonlayout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Home",
    path: "/:username/:password",
    component: QrLogin,
    config: {
      key: "unlock",
      icon: BiHomeAlt,
      structure: "nonlayout",
      exact: true,
      isShowLink: false,
    },
  },
];

export const student_routes: Array<RoutesTypeSubmenu> = [
  {
    name: "Home",
    path: "/",
    component: StudentProfileHomePage,
    config: {
      key: "unlock",
      icon: BiHomeAlt,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Davomat",
    path: "/std_attendances/:subject_id",
    component: StudentAttendance,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  // {
  //   name: "Subjects",
  //   path: "/std_subjects",
  //   component: StudentSubjects,
  //   config: {
  //     key: "unlock",
  //     icon: BiBlanket,
  //     structure: "student_layout",
  //     exact: true,
  //     isShowLink: false,
  //   },
  // },
  {
    name: "Fanlar",
    path: "/std_subjects",
    component: StudentSubjects,
    config: {
      key: "unlock",
      icon: BiBlanket,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Fan tanlash",
    path: "/select_subject",
    component: SubjectSelection,
    config: {
      key: "student-subject-selection_create",
      icon: FaVoteYea,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Fan mavzulari",
    path: "/subject_topic/:subject_name",
    component: SubjectTopic,
    config: {
      key: "unlock",
      icon: FaVoteYea,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Topshiriqlar",
    path: "/std_subject/subject_exercisses/:time_table_id",
    component: Exercisse,
    config: {
      key: "unlock",
      icon: BiBlanket,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Sillabus",
    path: "/subjects/:subject_id",
    component: SubjectDetails,
    config: {
      key: "unlock",
      icon: BiBlanket,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Subject contents",
    path: "/subject_content/:topic_id",
    component: SubjectContent,
    config: {
      key: "unlock",
      icon: BiBlanket,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Subject contents 1",
    path: "/subject_content_1/:topic_id/:user_id",
    component: SubjectContent_1,
    config: {
      key: "unlock",
      icon: BiBlanket,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Dars jadval",
    path: "/time-table_variant_index",
    component: StudentLessonScheduleVariant,
    config: {
      key: "time-table_index",
      icon: BiCalendarEvent,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Time table variants",
    path: "/time_table_variants/:edu_semester_id",
    component: TimeTableVariants,
    config: {
      key: "time-table_index",
      icon: BiCalendarEvent,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Time table",
    path: "/time-table_index",
    component: StudentLessonSchedule,
    config: {
      key: "time-table_index",
      icon: BiCalendarEvent,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Dars jadval fanlar bo'yicha",
    path: "/time_tables_by_subjects/:edu_semester_id/:subject_id",
    component: TimeTables,
    config: {
      key: "unlock",
      icon: BiCalendarEvent,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  // {
  //   name: "Time table variant",
  //   path: "/time-table_index_variant",
  //   component: StudentLessonScheduleVariantV1,
  //   config: {
  //     key: "time-table_index",
  //     icon: BiCalendarEvent,
  //     structure: "student_layout",
  //     exact: true,
  //     isShowLink: false,
  //   },
  // },
  // {
  //   name: "Time table v1",
  //   path: "/time-table_index-v1",
  //   component: StudentLessonScheduleVariantV1,
  //   config: {
  //     key: "time-table_index",
  //     icon: BiCalendarEvent,
  //     structure: "student_layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  // },
  {
    name: "Time table variants",
    path: "/time_table_variants-v1/:edu_semester_id",
    component: TimeTableVariantsV1,
    config: {
      key: "time-table_index",
      icon: BiCalendarEvent,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Dars jadval fanlar bo'yicha",
    path: "/time_tables_by_subjects-v1/:edu_semester_id/:subject_id",
    component: TimeTablesV1,
    config: {
      key: "unlock",
      icon: BiCalendarEvent,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Time table variants",
    path: "/time_tables_by_subject/:subject_id",
    component: TimeTableBySubjects,
    config: {
      key: "time-table_index",
      icon: BiCalendarEvent,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Mashg'ulotlar",
    path: "/clubs",
    component: StudentClub,
    config: {
      key: "unlock",
      icon: BsViewList,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Mashg'ulot",
    path: "/club/:id",
    component: Club,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Mashg'ulotlar toifasi",
    path: "/club_category",
    component: ClubCategory,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Mashg'ulotlar tanlsh",
    path: "/club_selection/:id",
    component: Clubs,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Exams",
    path: "/std_exams",
    component: StudentExams,
    config: {
      key: "unlock",
      icon: RiFileEditLine,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Exam appeals",
    path: "/exam-appeals",
    component: ExamAppeals,
    config: {
      key: "exam-appeals_index",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Exam Control",
    path: "/exam/control",
    component: ExamControl,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Exams New",
    path: "/exam",
    component: ExamView,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  { 
    name: "Imtihonlar by subject id",
    path: "/student-exams/:subjectId",
    component: SubjectExams,
    config: {
      key: "unlock",
      icon: BiCalendarCheck, 
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Exam view",
    path: "/student-exams/:subjectId/view/:examId",
    component: StudentExamView,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Imtihon",
    path: "/student-exams/:subjectId/exam-result/:id",
    component: Result,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Oraliq imtihon",
    path: "/exam_control/:time_table_id/:question_id",
    component: ExamControl,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Oraliq imtihonni ko'rish",
    path: "/exam_control_view/:time_table_id/:question_id",
    component: ExamControlView,
    config: {
      key: "unlock",
      icon: BiCalendarCheck,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Hujjatlar",
    path: "/documents",
    component: Documents,
    config: {
      key: "unlock",
      icon: IoDocumentTextOutline,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "",
    path: "/doc_page",
    component: Docs,
    config: {
      key: "unlock",
      icon: BiFileFind,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Interaktiv xizmatlar",
    path: "/interactive_services",
    component: InteractiveServices,
    config: {
      key: "unlock",
      icon: BiCategory,
      structure: "student_layout",
      exact: true,
      isShowLink: true,
    },
  },
  {
    name: "Kutubxona",
    path: "/std_library",
    component: StudentLibrary,
    config: {
      key: "unlock",
      icon: BiBookBookmark,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Kitoblar",
    path: "/std_library_books",
    component: StudentLibraryBooks,
    config: {
      key: "unlock",
      icon: BiBookBookmark,
      structure: "student_layout",
      exact: false,
      isShowLink: false,
    },
  },
  {
    name: 'Imtihon',
    path: '/std_exam/:exam_id/',
    component: StdExam,
    config: {
      key: 'unlock',
      icon: BiBookBookmark,
      structure: 'student_layout',
      exact: false,
      isShowLink: false
    }
  },
  // {
  //   name: "Ovoz berish",
  //   path: "/student-vote",
  //   component: VoteComponent,
  //   config: {
  //     key: "unlock",
  //     icon: FaVoteYea,
  //     structure: "student_layout",
  //     exact: false,
  //     isShowLink: false,
  //   },
  // },
  {
    name: "FAQ",
    path: "/help",
    component: FAQ,
    config: {
      key: "unlock",
      icon: BiHelpCircle,
      structure: "student_layout",
      exact: false,
      isShowLink: false,
    },
  },
  {
    name: "Note",
    path: "/note",
    component: Note,
    config: {
      key: "unlock",
      icon: BiHelpCircle,
      structure: "student_layout",
      exact: false,
      isShowLink: false,
    },
  },
  {
    name: "Notification",
    path: "/notification",
    component: Notification,
    config: {
      key: "unlock",
      icon: BiHelpCircle,
      structure: "student_layout",
      exact: false,
      isShowLink: false,
    },
  },
  {
    name: "Student profile",
    path: `/profile`,
    component: Profile,
    config: {
      key: "unlock",
      icon: "",
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Teacher statistics",
    path: "/teacher_statistics",
    component: TeacherStatistic,
    config: {
      key: "unlock",
      icon: BiBookBookmark,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Dormitory",
    path: "/dormitory",
    component: Dormitory,
    config: {
      key: "hostel-app_index",
      icon: BiBookBookmark,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
  {
    name: "Teacher Profile",
    path: "/teacher_profile/:teacher_id",
    component: TeacherProfile,
    config: {
      key: "hostel-app_index",
      icon: BiBookBookmark,
      structure: "student_layout",
      exact: true,
      isShowLink: false,
    },
  },
];
