import { Empty, Spin } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { IClubCategory } from "models/club";
import { Card, CardBody } from "reactstrap";
import "../styles.scss";
import { FaInfoCircle } from "react-icons/fa";
import { PageNotice } from "components/Notice";
import { NavLink } from "react-router-dom";

const ClubCategory = () => {
    const club_categories = useGetAllData({ url: `/club-categories?expand=description,clubTimes,clubs.clubTimes.building,clubs.description`, isCall: 'auto' }) as TypeReturnUseGetAllData<IClubCategory>;

    return (
        <Spin spinning={club_categories.loading}>
            <Card style={{ minHeight: club_categories.items.length ? 'auto' : 500 }}>
                <CardBody>
                    <PageNotice showIcon={true} isTitle={false} notice={<span style={{ fontSize: "14px", fontWeight: 500 }}>Mashg'ulotlar toifalari.</span>} />
                    <div style={{ backgroundColor: "#F5F7F9" }} className="px-2 pb-3 pt-1 mt-3">
                        {
                            club_categories.items.length ?
                                club_categories?.items.map((clubCategoryItem, index: number) => {
                                    return (
                                        <NavLink to={`/club_selection/${clubCategoryItem?.id}`} key={clubCategoryItem.id} className="text-decoration-none" >
                                            <Card key={clubCategoryItem.id} className="mt-2" >
                                                <CardBody>
                                                    <div className="club_category_card">
                                                        <span>{index + 1}. {clubCategoryItem.name}</span>
                                                        {clubCategoryItem.description ? <p><FaInfoCircle size={14} color="#1890ff" />&nbsp;&nbsp;{clubCategoryItem.description}</p> : null}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </NavLink>
                                    )
                                })
                                : <Empty className="mt-5" description="Mashg'ulotlar qo'shilmagan !" />
                        }
                    </div>
                </CardBody>
            </Card >
        </Spin>
    )

}


export default ClubCategory;